import React, { useEffect } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
// import { SlArrowRightCircle } from "react-icons/sl";
// import { SlArrowRight } from "react-icons/sl";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

import styled from "styled-components";
import "./Products.css";

import {
  View,
  Text,
  Image,
  TouchableOpacity,
  ScrollView,
  TextInput
} from "./../../components/styled/styledComponents";

import colors from "./../../resources/styles/colors";
import loaders from "./../../utils/personas_loaders";

import config from "../../config";

// Define the ArrowButton using styled-components
const ArrowButton = styled.button`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2px;
  opacity: ${props => (props.disabled ? "0" : "1")};
  user-select: none;
  border-radius: 6px;
  border-width: 1px;
  background: "transparent";
`;

// Arrow function component
function Arrow({ children, disabled, onClick, className, testId }) {
  return (
    <ArrowButton
      disabled={disabled}
      onClick={onClick}
      className={`arrow-${className}`}
      data-testid={testId}
    >
      {children}
    </ArrowButton>
  );
}

const getItems = () =>
  Array(20)
    .fill(0)
    .map((_, ind) => ({ id: `element-${ind}` }));

let imgWidth = parseInt(96 * 1.1);
let imgHeight = parseInt(144 * 1.1);

function App(props) {
  // console.log("props 1", props);
  const [items, setItems] = React.useState(props.items || []);
  const [selected, setSelected] = React.useState([]);
  const [position, setPosition] = React.useState(0);

  useEffect(
    () => {
      setItems(props.items);
    },
    [props.items]
  ); // Re-run the effect only if props.items changes

  const isItemSelected = id => !!selected.find(el => el === id);

  const handleClick = id => ({ getItemById, scrollToItem }) => {
    const itemSelected = isItemSelected(id);

    setSelected(
      currentSelected =>
        itemSelected
          ? currentSelected.filter(el => el !== id)
          : currentSelected.concat(id)
    );
  };

  const apiRef = React.useRef({});

  const myScrollNext = () => {
    console.log(apiRef.current);
    console.log(apiRef.current.getNextItem());
    let nextItem = apiRef.current.getNextItem();
    let totalItems = items.length;
    console.log(totalItems);
    if (nextItem) {
      let id = nextItem.key;
      let tmpId = parseInt(id) + 2;
      let newId = tmpId < totalItems - 1 ? tmpId : totalItems - 1;
      console.log("newId", newId);
      apiRef.current.scrollToItem(
        apiRef.current.getItemById(`${newId}`)
        // OR if you not sure about id for first item
        // apiRef.current.getItemById(apiRef.current.items.toItems()[0]),
        // "auto",
        // "start"
      );
    }
  };

  function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } = React.useContext(
      VisibilityContext
    );

    return (
      <div style={{ width: 0, height: 0 }} onClick={() => scrollPrev()}>
        <div
          style={{
            position: "relative",
            left: 0,
            top: (144 * 1.1 - 50) / 2,
            zIndex: 100,
            cursor: "pointer"
          }}
        >
          {isFirstItemVisible && <div />}
          {!isFirstItemVisible && (
            <View
              style={{
                width: 50,
                height: 50,
                borderRadius: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
                // backgroundColor:"#fff",
                // backgroundImage: "linear-gradient(to left,rgb(255 255 255/0),white var(--f-fw-z-a-i))"
              }}
            >
              <View
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: 40,
                  backgroundColor: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // margin: 5,
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 2px 5px",
                  border: "0.5px solid rgb(0 0 0/0.3)" // `1px solid ${colors.inkLighter}`
                }}
              >
                <IoIosArrowBack size={15} color={colors.inkDarkest} />
              </View>
            </View>
          )}
        </div>
      </div>
    );
  }

  function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(
      VisibilityContext
    );

    return (
      <div
        style={{ width: 0, height: 0 }}
        onClick={() => {
          console.log("scrollNext", scrollNext);
          // scrollNext();
          myScrollNext();
        }}
      >
        <div
          style={{
            position: "relative",
            left: -50,
            top: (144 * 1.1 - 50) / 2,
            zIndex: 100,
            cursor: "pointer"
          }}
        >
          {isLastItemVisible && <div />}
          {!isLastItemVisible && (
            <View
              style={{
                width: 50,
                height: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <View
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: 40,
                  backgroundColor: "#fff",
                  display: "flex",
                  // flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 2px 5px",
                  border: "0.5px solid rgb(0 0 0/0.3)" // `1px solid ${colors.inkLighter}`
                }}
              >
                <IoIosArrowForward size={15} color={colors.inkDarkest} />
              </View>
            </View>
          )}
        </div>
      </div>
    );
  }

  // function resizeShopifyImage(url, newWidth, newHeight) {
  //   // method 2
  //   // image_url appends a dimension=somenumber query param to the url
  //   // (e.g. https://your-store.myshopify.com/cdn/shop/products/some-product.jpg?v=1252216042&width=400)
  //   // image_url requires you to specify a dimension, so you'll want to manually
  //   // replace that if you want another number after the fact.
  //
  //   const sizeRegex = /_(\d+)x(\d+)?\./;
  //
  //   const newSize = `_${newWidth}x${newHeight}.`;
  //
  //   // console.log("url", url);
  //   let newUrl = url.replace(sizeRegex, newSize);
  //   // console.log("newUrl", newUrl);
  //
  //   return newUrl;
  // }

  function resizeShopifyImage(url, newWidth, newHeight) {
    // Regex to check if URL already has dimensions in the filename
    const sizeRegex = /_(\d+)x(\d+)?\./;

    // Check if the URL matches the existing dimension format
    if (sizeRegex.test(url)) {
      // If it matches, replace the existing dimensions
      const newSize = `_${newWidth}x${newHeight}.`;
      return url.replace(sizeRegex, newSize);
    } else {
      // If no dimensions in the filename, add dimensions as query parameters
      // Check if the URL already has query parameters
      const separator = url.includes("?") ? "&" : "?";
      console.log(`${url}${separator}width=${newWidth}&height=${newHeight}`);
      return `${url}${separator}width=${newWidth}&height=${newHeight}`;
    }
  }

  function Card({ onClick, selected, title, itemId, item, keyNr }) {
    const visibility = React.useContext(VisibilityContext);
    let product = item;

    const removeHtmlTags = htmlString => {
      // Use a regular expression to remove all HTML tags
      const noHtmlString = htmlString.replace(/<\/?[^>]+(>|$)/g, "");
      return noHtmlString;
    };

    let shopifyCurrency = window.shopifyCurrency;
    let shopifyMoneyFormat = window.shopifyMoneyFormat;

    let priceFormatted;

    if (product.metadata.price) {
      console.log(
        "price data",
        shopifyMoneyFormat,
        shopifyCurrency,
        product.metadata
      );

      if (
        false &&
        shopifyMoneyFormat &&
        shopifyCurrency &&
        product.metadata.currency &&
        shopifyCurrency.toLowerCase() == product.metadata.currency.toLowerCase()
      ) {
        console.log("shopifyMoneyFormat", shopifyMoneyFormat);
        shopifyMoneyFormat = removeHtmlTags(shopifyMoneyFormat);
        // priceFormatted = shopifyMoneyFormat.replace(
        //   "{{amount}}",
        //   product.metadata.price
        // );

        let currencyFormats = {
          USD: { symbol: "$", position: "before" },
          CAD: { symbol: "$", position: "before" },
          GBP: { symbol: "£", position: "before" },
          JPY: { symbol: "¥", position: "before" },
          CHF: { symbol: "CHF", position: "before" },
          AUD: { symbol: "$", position: "before" },
          NZD: { symbol: "$", position: "before" },
          EUR: { symbol: "€", position: "after" },
          SEK: { symbol: "kr", position: "after" },
          NOK: { symbol: "kr", position: "after" },
          DKK: { symbol: "kr", position: "after" },
          CZK: { symbol: "Kč", position: "after" },
          PLN: { symbol: "zł", position: "after" },
          HUF: { symbol: "Ft", position: "after" },
          LEI: { symbol: "lei", position: "after" }
          // other - after
        };

        let moneyFormatAll = [
          {
            placeholder: "{{ amount }}",
            description:
              "Displays the price as a plain number without currency symbol."
          },
          {
            placeholder: "{{amount}}",
            description:
              "Displays the price as a plain number without currency symbol."
          },
          {
            placeholder: "{{ amount_with_comma_separator }}",
            description:
              "Displays the amount with commas as thousands separators."
          },
          {
            placeholder: "{{amount_with_comma_separator}}",
            description:
              "Displays the amount with commas as thousands separators."
          },
          {
            placeholder: "{{ amount_no_decimals }}",
            description: "Displays the amount without decimals."
          },
          {
            placeholder: "{{amount_no_decimals}}",
            description: "Displays the amount without decimals."
          },
          {
            placeholder: "{{ amount_no_decimals_with_comma_separator }}",
            description:
              "Displays the amount without decimals, using commas as thousands separators."
          },
          {
            placeholder: "{{amount_no_decimals_with_comma_separator}}",
            description:
              "Displays the amount without decimals, using commas as thousands separators."
          },
          {
            placeholder: "{{ amount_no_decimals_with_space_separator }}",
            description:
              "Displays the amount without decimals, with spaces as thousands separators."
          },
          {
            placeholder: "{{amount_no_decimals_with_space_separator}}",
            description:
              "Displays the amount without decimals, with spaces as thousands separators."
          },
          {
            placeholder: "{{ amount_with_apostrophe_separator }}",
            description:
              "Displays the amount with apostrophes as thousands separators."
          },
          {
            placeholder: "{{amount_with_apostrophe_separator}}",
            description:
              "Displays the amount with apostrophes as thousands separators."
          },
          {
            placeholder: "{{ amount_with_dot_separator }}",
            description:
              "Displays the amount with dots as thousands separators."
          },
          {
            placeholder: "{{amount_with_dot_separator}}",
            description:
              "Displays the amount with dots as thousands separators."
          },
          {
            placeholder: "{{ amount_with_space_separator }}",
            description:
              "Displays the amount with spaces as thousands separators."
          },
          {
            placeholder: "{{amount_with_space_separator}}",
            description:
              "Displays the amount with spaces as thousands separators."
          }
        ];

        // Helper function to apply specific formatting based on the placeholder
        function applyFormat(price, placeholder) {
          switch (placeholder) {
            case "{{ amount }}":
            case "{{amount}}":
              return price.toString(); // Basic formatting
            case "{{ amount_with_comma_separator }}":
            case "{{amount_with_comma_separator}}":
              return price.toLocaleString(); // Adds commas as thousands separators
            case "{{ amount_no_decimals }}":
            case "{{amount_no_decimals}}":
              return parseInt(price).toLocaleString(); // No decimals
            case "{{ amount_no_decimals_with_comma_separator }}":
            case "{{amount_no_decimals_with_comma_separator}}":
              return parseInt(price).toLocaleString(); // No decimals, with commas
            case "{{ amount_no_decimals_with_space_separator }}":
            case "{{amount_no_decimals_with_space_separator}}":
              return parseInt(price)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, " "); // No decimals, with spaces
            case "{{ amount_with_apostrophe_separator }}":
            case "{{amount_with_apostrophe_separator}}":
              return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'"); // With apostrophes as thousands separators
            case "{{ amount_with_dot_separator }}":
            case "{{amount_with_dot_separator}}":
              return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."); // With dots as thousands separators
            case "{{ amount_with_space_separator }}":
            case "{{amount_with_space_separator}}":
              return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "); // With spaces as thousands separators
            default:
              return price.toString(); // Default fallback
          }
        }
        // Define a function that formats the price based on different placeholder options
        function formatPrice(shopifyMoneyFormat, productPrice, moneyFormatAll) {
          let priceFormatted = shopifyMoneyFormat;

          // Iterate over each money format option
          moneyFormatAll.forEach(formatOption => {
            let formattedPrice = applyFormat(
              productPrice,
              formatOption.placeholder
            );
            priceFormatted = priceFormatted.replace(
              formatOption.placeholder,
              formattedPrice
            );
          });

          return priceFormatted;
        }

        priceFormatted = formatPrice(
          shopifyMoneyFormat,
          product.metadata.price,
          moneyFormatAll
        );

        console.log("priceFormatted", priceFormatted);
      } else if (product.metadata.currency) {
        if (product.metadata.currency.toLowerCase() == "usd") {
          priceFormatted = `$${parseFloat(product.metadata.price).toFixed(2)}`;
        } else {
          priceFormatted = `${parseFloat(product.metadata.price).toFixed(
            2
          )} ${product.metadata.currency}`;
        }
      } else {
        // no needed if the price is indexed with currency
        priceFormatted = `$${product.metadata.price}`;
      }
    }

    return (
      <View
        mx={4}
        // mb={8}
        ml={keyNr == 0 ? 58 : 5}
        onClick={() => onClick(visibility)}
        style={{
          // width: "160px",
          // marginHorizontal: "5px"
        }}
        tabIndex={0}
      >
        <div className="card">
          <View key={itemId}>
            <TouchableOpacity
              onClick={() => {
                let domain = window.location.hostname;
                let url;
                if (product.metadata.url.includes("https://")) {
                  url = product.metadata.url;
                } else {
                  url = `https://${props.shop}/products/${product.metadata
                    .url}`;
                }

                // let mainUrl = window.location.href;
                // const urlObj = new URL(mainUrl);
                // const isTestEnabled = urlObj.searchParams.get("test_enabled");

                // if (isTestEnabled) {
                //   const test_session = urlObj.searchParams.get("test_session");
                //   url = `${url}?test_enabled=1&test_session=${test_session}`;
                // }

                if (
                  domain.includes("localhost") ||
                  domain.includes("192.168.0.149") ||
                  domain.includes(config.testSite)
                ) {
                  url = url.replace("https://tmp_", "https://");
                  window.open(
                    url,
                    // product.image.contextLink,
                    "_blank"
                  );
                } else {
                  window.location.href = url;
                }

                // window.location.href =
                //   product.image.contextLink;

                config.analytics.trackProduction(`Product Link`, {
                  distinct_id: config.userId,
                  product_title: product.title,
                  product_link: url, // product.image ? product.image.contextLink : ""
                });
                // this.props.navigation.push("ProductWebView", {
                //   uri: product.image.contextLink,
                //   title: product.displayLink
                // });
                // WebBrowser.openBrowserAsync(
                //   product.image.contextLink
                // );
              }}
            >
              <View
                // mx={10}
                style={{
                  width: 96 * 1.1,
                  alignItems: "center",
                  paddingHorizontal: 20
                }}
              >
                <Image
                  // transition={500}
                  // width={96 * 1.7}
                  // height={144 * 1.7}
                  // placeholder={
                  //   config.blurhash_2x3
                  // }
                  // source={{ uri: product.image.thumbnailLink }}
                  // source={{
                  //   uri: `${product.metadata.image}`
                  // }}

                  source={{
                    uri: resizeShopifyImage(
                      product.metadata.image,
                      imgWidth * 3,
                      imgHeight * 3
                    )
                  }}
                  // source={{
                  //   uri: `https://traveline-images.s3.amazonaws.com/fashionapp/general/${this
                  //     .state.persona.image}`
                  // }}
                  // uri={imgUrl}
                  // preview={preview}
                  //indicator={ProgressBar}
                  style={{
                    width: 96 * 1.1,
                    height: 144 * 1.1,
                    // width: 96, // (width - 60) / 2, //width * 0.35,
                    // height: 144, // (width - 60) / 2 * 1.5,
                    // marginTop: 20,
                    // textAlign: 'center',
                    // backgroundColor: 'blue',
                    //marginLeft: parseInt((width - imgWidth) / 2),
                    // marginTop: getStatusBarHeight(true) + 30,
                    //marginTop: isNativeApp ? getStatusBarHeight(true) + 30 : 80,
                    //marginBottom: 40,
                    borderWidth: 1,
                    borderRadius: 2,
                    borderColor: colors.inkLighter,
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain"
                  }}
                  // resizeMode="contain"
                  // maxBytes="100000"
                  // contentFit="contain"
                />
                <Text
                  mt={2}
                  small_tight_regular
                  style={{
                    fontSize: 12,
                    // color: colors.inkLight,
                    textAlign: "left",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                    //   lineHeight: "16px", // Adjust this value as needed
                    //   maxHeight: "32px" // This should be line-height * number of lines
                  }}
                >
                  {product.metadata.name}
                </Text>
                {!!product.metadata.price && (
                  <Text
                    mt={2}
                    // small_tight_regular
                    style={{
                      fontSize: 12,
                      // color: colors.inkLight,
                      textAlign: "left"
                    }}
                  >
                    {priceFormatted}
                  </Text>
                )}
              </View>
            </TouchableOpacity>
          </View>
        </div>
      </View>
    );
  }

  // console.log("items found", items);

  // remove duplicates

  let newItems;
  if (items && items.length > 0) {
    console.log("items1111", items);

    newItems = items.filter(
      (obj, index, self) =>
        index === self.findIndex(t => t.metadata.url === obj.metadata.url)
    );
  } else {
    newItems = items;
  }

  return (
    <View>
      {newItems &&
        newItems.length == 0 && (
          <View
            style={{
              width: "100%",
              height: 144 * 1.1 + 6,
              display: "flex",
              // alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Text
              tiny_none_regular
              px={12}
              py={8}
              // style={{
              //   marginLeft: 20,
              //   paddingLeft: 40
              // }}
            >
              No products found.
            </Text>
          </View>
        )}
      {newItems &&
        newItems.length > 0 && (
          <ScrollMenu
            apiRef={apiRef}
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
            // style={{ paddingLeft: "20px", paddingRight: "20px" }}
          >
            {newItems.map((item, keyNr) => {
              if (item.metadata.image) {
                return (
                  <Card
                    itemId={keyNr} // NOTE: itemId is required for track items
                    title={item.id}
                    key={item.id}
                    onClick={handleClick(item.id)}
                    selected={isItemSelected(item.id)}
                    item={item}
                    keyNr={keyNr}
                  />
                );
              }
            })}
          </ScrollMenu>
        )}
    </View>
  );
}

// <Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
//   Left
// </Arrow>

export default App;

//
// import React from "react";
// import ReactDOM from "react-dom";
// import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
// import 'react-horizontal-scrolling-menu/dist/styles.css';
//
// import { LeftArrow, RightArrow } from "./arrows";
// import { Card } from "./card";
// import { Footer } from "./footer";
// import { Header } from "./header";
// import "./globalStyles.css";
//
// // NOTE: embrace power of CSS flexbox!
// // import "./hideScrollbar.css";
// // import "./firstItemMargin.css";
//
// type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;
//
// const elemPrefix = "test";
// const getId = (index: number) => `${elemPrefix}${index}`;
//
// const getItems = () =>
//   Array(20)
//     .fill(0)
//     .map((_, ind) => ({ id: getId(ind) }));
//
// function App() {
//   const [items] = React.useState(getItems);
//
//   // NOTE: apiRef
//   const apiRef = React.useRef({} as scrollVisibilityApiType);
//
//   const reset = () => {
//     apiRef.current.scrollToItem(
//       apiRef.current.getItemById("test0"),
//       // OR if you not sure about id for first item
//       // apiRef.current.getItemById(apiRef.current.items.toItems()[0]),
//       "auto",
//       "start"
//     );
//   };
//   const ResetButton = ({ onClick }: { onClick: VoidFunction }) => (
//     <button id="reset" onClick={onClick} style={{ marginTop: "50px" }}>
//       RESET
//     </button>
//   );
//
//   return (
//     <>
//       <Header />
//       <div className="example" style={{ paddingTop: "100px" }}>
//         <div>
//           <ScrollMenu
//             apiRef={apiRef}
//             LeftArrow={LeftArrow}
//             RightArrow={RightArrow}
//             onWheel={onWheel}
//           >
//             {items.map(({ id }) => (
//               <Card
//                 title={id}
//                 itemId={id} // NOTE: itemId is required for track items
//                 key={id}
//               />
//             ))}
//           </ScrollMenu>
//           <ResetButton onClick={reset} />
//         </div>
//         <Footer />
//       </div>
//     </>
//   );
// }
// export default App;
//
// function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void {
//   const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;
//
//   if (isThouchpad) {
//     ev.stopPropagation();
//     return;
//   }
//
//   if (ev.deltaY < 0) {
//     apiObj.scrollNext();
//   } else if (ev.deltaY > 0) {
//     apiObj.scrollPrev();
//   }
// }
//
// ReactDOM.render(<App />, document.getElementById("root"));
