import React, { Component } from "react";
import {
  View,
  Text,
  TouchableOpacity
} from "./../../components/styled/styledComponents";
import colors from "./../../resources/styles/colors";

class SlideInAnimation extends Component {
  componentDidMount() {
    // Implement any initialization logic here if needed
  }

  slideInWithDelay() {
    // Implement slide-in animation with a delay for web
  }

  slideIn() {
    // Implement slide-in animation for web
  }

  render() {
    console.log("render 1234", this.props.suggestedUserResponses);
    return (
      <View>
        <View
          mx={24}
          // mt={4}
          mb={4}
          style={{
            display: "flex",
            flexWrap: "nowrap",
            alignItems: "flex-end",
            alignContent: "stretch",
            flexDirection: "column"
          }}
        >
          {this.props.suggestedUserResponses &&
            this.props.suggestedUserResponses.length > 0 &&
            this.props.suggestedUserResponses.map((response, index) => (
              <View key={index} style={{}}>
                <TouchableOpacity
                  onClick={() => {
                    if (!this.props.hasAccess) {
                      this.props.redirectToSubscribe();
                    } else {
                      this.props.onSubmitResponse(response);
                    }
                  }}
                >
                  <View
                    my={4}
                    // py={12}
                    py={8}
                    px={16}
                    // style={{
                    //   backgroundColor: colors.bgVioletLighter,
                    //   // backgroundColor: colors.inkLightest, // colors.bgVioletLighter, // colors.inkLightest,
                    //   borderRadius: 8,
                    //   marginHorizontal: 5
                    //   // border: `1px solid ${colors.inkLight}`
                    // }}
                    style={{
                      // backgroundColor: colors.bgVioletLighter,
                      // backgroundColor: colors.inkLightest, // colors.bgVioletLighter, // colors.inkLightest,
                      borderRadius: 100, // 8,
                      // marginHorizontal: 5,
                      border: `1px solid ${colors.inkLight}`
                    }}
                  >
                    <Text small_message style={{fontSize: 13}}>
                      {response}
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            ))}
        </View>
      </View>
    );
  }
}

export default SlideInAnimation;
