export default [
  {
    mod: 'Here is a list of products:',
    andreea_chirila: "I've selected these items for you:",
    denisa_sima: "There you have some items I've chosen for you:",
    maria_zavate: 'Picked some nasty stuff for you:',
  },
  {
    mod: 'Explore this selection:',
    andreea_chirila: 'These are some #ladyboss items for you:',
    denisa_sima: "Wooop! Wooop! I've selected some amazing pieces for you:",
    maria_zavate:
      'Wishful thinking... And all that manifestation bullshit! But here are some items for you:',
  },
  {
    mod: 'Play with these items:',
    andreea_chirila: 'Here you have some pretty items:',
    denisa_sima: 'Hope you will like these items:',
    maria_zavate: 'My sheep approved these clothing selection:',
  },
  {
    mod: 'Here is a stylistic curation for you:',
    andreea_chirila: 'Picked some badass pieces that you can shop:',
    denisa_sima: 'Manifest them or shop them. You choose:',
    maria_zavate:
      "I brought these items to the village board for approval, and they unanimously agreed they're fabulous:",
  },
  {
    mod: 'Created this shoppable list for you:',
    andreea_chirila: 'Your list of smart shopping:',
    denisa_sima: "Let's seeeee! Here are some items for you:",
    maria_zavate:
      'Yassss, amigos! Here are some items that will make all your frenemies jealous:',
  },
  {
    mod: 'Want to shop? You can start from here:',
    andreea_chirila: 'Let me share with you some cool items:',
    denisa_sima: 'Take a look at these exciting pieces: ',
    maria_zavate: 'Pam-pam! Sexy style guru I am! Picked these for you:',
  },
  {
    mod: 'How about these clothing items?',
    andreea_chirila:
      'Life is too short to wear ugly clothing items. Take a look at these:',
    denisa_sima: 'Hope these items will inspire you:',
    maria_zavate:
      "I hope my clothing selection isn't sh*tty, but hey, no guarantees.",
  },
  {
    mod: 'Selected these pieces especially for you:',
    andreea_chirila:
      "Don't forget to check the composition label when you shop:",
    denisa_sima:
      "Some items for you. And don't forget to choose proper makeup or even better a #nomakeup makeup:",
    maria_zavate: 'Organized the chaos and chose these items:',
  },
  {
    mod:
      'Here is a list of products, straight out of your wildest shopping dreams:',
    andreea_chirila:
      'You deserve a handpicked selection of items that match your energy:',
    denisa_sima:
      "These pieces are perfect for someone with a fun and playful personality but don't worry, I won't make you take a personality test to confirm it:",
    maria_zavate:
      "These items are fire! You're going to love them. Or not. I don't know:",
  },
  {
    mod:
      'Created this shoppable list for you, just like Rachel Green would have done for her friends:',
    andreea_chirila:
      'These stylish pieces are perfect for a fashion-forward and unstoppable woman like you:',
    denisa_sima:
      'Here are some fashion-forward items that will make a statement and help you break free from any self-imposed fashion limitations:',
    maria_zavate: 'No-bullshit alert! These are some outstanding pieces:',
  },
  {
    mod:
      'Want to shop? You can start from here, and it will be like you\'re in a "Sex and the City" episode with Samantha\'s limitless credit card:',
    andreea_chirila:
      "I curated this list of trendy items because I know you're not afraid to take risks and stand out from the crowd:",
    denisa_sima:
      'These items will make you feel confident and powerful, but remember that true power comes from within and not from the clothing:',
    maria_zavate:
      'Here are some items that will bring a bit of drama in your looks:',
  },
  {
    mod:
      'These pieces are like the superhero costumes from "The Avengers," but for your everyday life:',
    andreea_chirila:
      "These handpicked items will make you look so good, even the paparazzi will be chasing after you like you're Anna Wintour:",
    denisa_sima:
      'These items will make you look and feel like a million bucks, and a little mascara and lipstick will take it to the next level:',
    maria_zavate:
      'These items are perfect for someone who wants to make a statement with their style:',
  },
  {
    mod: 'Some clothing pieces that will make your day:',
    andreea_chirila:
      "You don't need to blend in when you were born to stand out. These standout pieces will elevate your style game to the top:",
    denisa_sima:
      'You are a one-of-a-kind individual, and these items were selected to match that:',
    maria_zavate:
      "Want to be that fierce woman? Prepared this for you. You're welcome!",
  },
  {
    mod:
      "These items will make you feel like you're on a runway in New York Fashion Week, even if you're just doing your own thing::",
    andreea_chirila:
      'These statement pieces will make sure you make an impression when you enter a room:',
    denisa_sima: 'These items will allow your natural beauty to shine through:',
    maria_zavate: 'Bold, daring, outstanding clothing items... Mmm..',
  },
  {
    mod:
      "Explore this selection and you'll feel like you're playing dress up with the wardrobe of your favorite fashion influencer:",
    andreea_chirila:
      'These items are perfect for someone with a bold and fearless personality, just like you:',
    denisa_sima:
      'Picked these for you. But remember that a good outfit is nothing without a good makeup look to go with it:',
    maria_zavate:
      'I won\'t judge if you press the "shop now" button way too many times:',
  },
  {
    mod:
      "Play with these items and you'll feel like a stylist in your own right, mixing and matching the perfect outfit:",
    andreea_chirila: 'These pieces will make you feel wonderful:',
    denisa_sima:
      'You are already amazing, but these items will only enhance that beauty and self-esteem you already possess:',
    maria_zavate: 'All of us have guilty pleasures...',
  },
  {
    mod:
      'Here is a stylistic curation for you, straight out of the pages of Vogue and Elle:',
    andreea_chirila:
      'These items are handpicked to make you feel like a rockstar on every occasion:',
    denisa_sima:
      'These items were selected to help you express yourself and embrace your individuality:',
    maria_zavate:
      'Here are some edgy and rebellious items for the rule-breakers:',
  },
  {
    mod:
      'Created this shoppable list for you, just like a personal shopper would do for a celebrity client:',
    andreea_chirila: 'These pieces can be dressed up or down for any occasion:',
    denisa_sima:
      'I hope these items will inspire you to continue to love and appreciate yourself:',
    maria_zavate:
      'Ah, the power of manifestation... And here I am, manifesting some items for you. You better appreciate it:',
  },
  {
    mod:
      "How about these clothing items? They're so fashionable and trendy that even Anna Wintour would approve:",
    andreea_chirila:
      "These items are so trendy, even the fashion police won't be able to find anything wrong with them:",
    denisa_sima:
      "These items are like the fashion equivalent of a magic potion. One sip, and you'll feel like the most stylish person in the room:",
    maria_zavate:
      "These pieces will make you feel like a sophisticated adult, even if you're still ordering off the kid's menu.",
  },
  {
    mod:
      'These items are like the fashion equivalent of a Michelin-starred meal - indulgent, luxurious, and oh so satisfying:',
    andreea_chirila:
      'The only thing better than these stylish pieces is the feeling you\'ll get when someone asks where you got them, and you can say "Oh, just something I picked up from my personal stylist.":',
    denisa_sima:
      'These items are perfect for someone who wants to express their true self through their fashion choices:',
    maria_zavate:
      "These items are perfect for someone who wants to make a statement without being too over the top, but let's be real, who doesn't want to be over the top sometimes?",
  },
  {
    mod:
      "These pieces are like a breath of fresh air - they'll add some life and color to your wardrobe:",
    andreea_chirila:
      "These items are perfect for someone who's always one step ahead:",
    denisa_sima:
      'Your beauty and self-worth are not determined by material things, but these items can still help you feel amazing:',
    maria_zavate:
      "Keep up with the latest trends with these items. You'll be a walking, talking fashion magazine.",
  },
  {
    mod:
      'These pieces are like a secret weapon that will make you feel like you can conquer the world, one stylish outfit at a time:',
    andreea_chirila:
      "Style doesn't have to break the bank, and these stylish items prove just that:",
    denisa_sima:
      'Take a look at these items, and remember that fashion is just another way to express your individuality and self-awareness:',
    maria_zavate:
      'These items will make you feel like a boss. Wear them to your next meeting and watch everyone else quiver in their loafers.',
  },
  {
    mod:
      "Here is a list of products that will make you feel like you're TikTok famous:",
    andreea_chirila:
      'These items are perfect for the busy and fierce woman you are:',
    denisa_sima:
      'I hope these items will inspire you to continue on your journey of self-discovery and self-love:',
    maria_zavate:
      'These fun items will make you stand out from the crowd. Go ahead, be the flamingo in a flock of pigeons.',
  },
  {
    mod:
      "Explore this selection and you'll feel like you're scrolling through your Instagram explore page:",
    andreea_chirila:
      'These items are so versatile, you can wear them to any occasion - even to a fashion show with Anna Wintour.',
    denisa_sima:
      "You know that feeling when you find the perfect outfit? It's like finding the last piece of a puzzle, except you're the only one who gets to see the finished picture. These items will give you that feeling:",
    maria_zavate:
      "Take a look at these sleek items. You'll be like Audrey Hepburn, but with a Netflix addiction.",
  },
  {
    mod:
      "Play with these items and you'll feel like you're in a virtual reality fashion game:",
    andreea_chirila:
      "You don't need a magic wand to be fashionable when you have these items - they're like your own personal fashion fairy godmother.",
    denisa_sima:
      "These items are like your own personal cheerleaders, but instead of pom-poms, they're stylish accessories:",
    maria_zavate:
      "Express your unique personality with this selection of items. Just remember, if anyone judges you, they're just jealous they didn't think of it first.",
  },
  {
    mod:
      'Created this shoppable list for you, just like a "haul" video from your favorite YouTuber:',
    andreea_chirila:
      'Play with these items, and you\'ll feel like you\'re in a fashion montage from "The Devil Wears Prada":',
    denisa_sima:
      "These pieces are perfect for someone who knows their worth and isn't afraid to show it off. Think of them as your stylish sidekicks:",
    maria_zavate:
      "These items are hotter than Joey's love for sandwiches. You need them in your life:",
  },
  {
    mod:
      "Want to shop? You can start from here and you'll feel like you're part of a trendy influencer squad:",
    andreea_chirila:
      'Here is a stylistic curation for you, straight from the fashion heavens of Carrie Bradshaw and her Manolos:',
    denisa_sima:
      "These items will make you feel like a million bucks, minus the actual million dollars. It's like getting the jackpot without even having to play the lottery:",
    maria_zavate:
      'These items will make you feel like a fashion icon, even more so than Rachel Green in her infamous "I\'m sorry, I was wearing a bathing suit" outfit:',
  },
  {
    mod:
      "Selected these pieces especially for you, and they're so cute and trendy that they'd fit right in on your Pinterest board:",
    maria_zavate:
      "Take a look at these stylish clothing pieces. They're like Jim Halpert's pranks, always on point.",
  },
  {
    mod:
      'These items are so stylish that they\'ll make you feel like you\'re in a fashion competition on "Project Runway":',
  },
  {
    mod:
      "Take a look at these items, and you'll feel like you're on a virtual shopping spree with your BFFs:",
  },
];
