import React, { Component } from "react";
import styled from "styled-components";
import colors from "./../../resources/styles/colors";
// Import your styled components
import {
  View,
  Text,
  TouchableOpacity
} from "./../../components/styled/styledComponents";

import ChatAI from "./../scan/ChatAI";

import imgClose from "./../../assets/close_2.png"; // Adjust the path as necessary
import imgBottom from "./../../assets/img_bottom_3.png"; // Adjust the path as necessary

class ChatWindow extends Component {
  state = {
    isHalfSize: true,
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleDimensionsChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleDimensionsChange);
  }

  handleDimensionsChange = () => {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    });
  };

  toggleSize = () => {
    this.setState(prevState => ({
      isHalfSize: !prevState.isHalfSize
    }));
  };

  render() {
    if (!this.props.visible) {
      return null;
    }

    const { windowHeight, windowWidth, isHalfSize } = this.state;
    const halfHeight = Math.max(windowHeight / 1.5, 250);
    const isMobile = windowWidth < 481;
    // const marginSize = isMobile ? 20 : 20;
    const marginSize = 0; // isMobile ? 0 : 20;
    // const chatWidth = isMobile ? windowWidth - marginSize * 2 : 400;
    const chatWidth = windowWidth; // isMobile ? windowWidth : 400;
    // const chatHeight = Math.min(windowHeight - 40, 650);
    const chatHeight = windowHeight
    //  isMobile
    //   ? windowHeight
    //   : Math.min(windowHeight - 40, 650);

    let user = this.props.userPersona;
    let position = "right";
    if (user && user.chat && user.chat.chatWidgetPosition) {
      position = user.chat.chatWidgetPosition;

      // patch for the old version
      if (position == "right-bottom") {
        position = "right";
      }
      if (position == "left-bottom") {
        position = "left";
      }
    }

    console.log("chatWidgetPosition", position);

    return (
      <View
        style={{
          ...styles.container,
          height: chatHeight,
          // bottom: isMobile ? 0 : 20,
          // borderRadius: 20, // isMobile ? 0 : 10,
          // borderTopLeftRadius: 10,
          // borderTopRightRadius: 10,
          // borderBottomLeftRadius: isMobile ? 0 : 10,
          // borderBottomRightRadius: isMobile ? 0 : 10,
          // right: marginSize,
          // left: marginSize,
          ...(position == "left"
            ? { left: marginSize }
            : { right: marginSize }),
          ...(isMobile
            ? { left: marginSize, width: chatWidth }
            : { width: chatWidth })
        }}
      >
        <ChatAI
          chatWidth={chatWidth}
          userPersona={this.props.userPersona}
          context={this.props.context}
          isTest={this.props.isTest}
          isLocal={this.props.isLocal}
          header={
            <View
              style={{
                // width: "100%",
                paddingVertical: 8,
                paddingHorizontal: 16,
                backgroundColor: "#FFFFFF",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                // borderTopLeftRadius: isMobile ? 10 : 10, // Match the container's borderRadius
                // borderTopRightRadius: isMobile ? 10 : 10,
                // borderBottom: `0.1px solid ${colors.inkDark}`,
                // borderColor: colors.bdLine,
                backgroundColor: this.props.userPersona.chat.primaryColor // colors.bgVioletBase
              }}
            >
              <View
                mx={24}
                my={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <View my={8}>
                  <Text regular_none_bold style={{ color: "#fff" }}>
                    {/* Runa */}
                    {this.props.userPersona.chat.name}
                  </Text>
                  {/*
                  <Text small_tight_regular style={{ color: "#fff" }}>
                    AI Nutritionist
                  </Text>
                  */}
                </View>
                {/*
                <img
                  onClick={this.props.onClose}
                  src={imgClose}
                  alt="Close"
                  style={{
                    width: 24,
                    height: 24,
                    cursor: "pointer"
                    // padding: 2
                  }}
                />
                */}
              </View>
            </View>
          }
          testSession={this.props.testSession}
          adminChannelId={this.props.adminChannelId}
        />
      </View>
    );
  }
}

// Define your styles here
const styles = {
  container: {
    zIndex: 10000000000,
    // justifyContent: 'flex-end',
    // alignItems: "center",
    // minWidth: 350,
    // border: `1px solid ${colors.bdLine}`,
    borderWidth: 1,
    borderColor: colors.bdLine,
    backgroundColor: "#fff",
    // borderRadius: 10,
    // overflow: "hidden",
    // overflowY: "auto", // Enable vertical scrolling
    position: "fixed",
    // height: "100%",
    // bottom: 20, // Align to the bottom
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 5px 40px"
  },
  header: {
    // // width: "100%",
    // paddingVertical: 8,
    // paddingHorizontal: 16,
    // backgroundColor: "#FFFFFF",
    // flexDirection: "row",
    // justifyContent: "space-between",
    // alignItems: "center",
    // borderTopLeftRadius: 10, // Match the container's borderRadius
    // borderTopRightRadius: 10,
    // borderBottom: `0.1px solid ${colors.inkDark}`,
    // // borderColor: colors.bdLine,
    // // backgroundColor: colors.bgVioletBase
  },
  headerText: {
    fontWeight: "bold"
  },
  closeButton: {
    padding: 8
  },
  closeButtonText: {
    fontWeight: "bold",
    fontSize: 18
  },
  sizeButton: {
    padding: 8
  },
  sizeButtonText: {
    fontWeight: "bold",
    fontSize: 18
  }
  // overlay: {
  //   position: "absolute",
  //   top: 0,
  //   bottom: 0,
  //   left: 0,
  //   right: 0,
  //   backgroundColor: "rgba(0, 0, 0, 0.5)"
  // }
  // Define other styles...
};

export default ChatWindow;
