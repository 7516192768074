import React, { Component } from "react";
import styled from "styled-components";

import config from "../../config";
import colors from "./../../resources/styles/colors";

import { View, Text, Image } from "./../../components/styled/styledComponents";
import TypingMessage from "./TypingMessage"; // Import the TypingMessage component

function isMobileDevice() {
  return window.innerWidth <= 768;
}

class FloatingChatButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showInfoMessage: false, // !this.isInfoMessageDismissed(),
      typingMessage: "",
      isTesting: props.activeChat === "testing"
    };
    // this.fullMessage = ""; //"Hi there 👋 What brings you to our store today?";
  }

  hideTestMode = () => {
    if (window.confirm("Are you sure you want to leave test mode?")) {
      this.props.disableTesting();
    }
    // this.props.disableTesting();
    // this.setState({ isTesting: false });
  };

  componentDidMount() {
    if (!this.isInfoMessageDismissed()) {
      setTimeout(() => {
        this.setState({ showInfoMessage: true }, () => {
          setTimeout(() => {
            this.typeMessage();
          }, 100);
        });
      }, 2000);
    }
  }

  isInfoMessageDismissed() {
    const dismissedDate = localStorage.getItem("infoMessageDismissedDate");
    const today = new Date().toISOString().split("T")[0];
    return dismissedDate === today;
  }

  dismissInfoMessage() {
    const today = new Date().toISOString().split("T")[0];
    localStorage.setItem("infoMessageDismissedDate", today);
    this.setState({ showInfoMessage: false });
  }

  typeMessage(index = 0) {
    let user = this.props.userPersona;
    if (user) {
      let message = user.chat.chatFloatingButtonDescription
        ? user.chat.chatFloatingButtonDescription
        : "Hi there 👋 What brings you to our store today?";
      if (index < message.length) {
        this.setState(
          { typingMessage: message.substring(0, index + 1) },
          () => {
            setTimeout(() => this.typeMessage(index + 1), 20); // Adjust typing speed here
          }
        );
      }
    }
  }

  getDefaultPosition() {
    return isMobileDevice() ? "center" : "right";
  }

  render() {
    let user = this.props.userPersona;
    console.log("chatWidgetPosition user ", user);

    if (!user) return null;

    let position = this.getDefaultPosition();
    if (user && user.chat) {
      position = isMobileDevice()
        ? user.chat.mobileChatWidgetPosition
        : user.chat.chatWidgetPosition;

      if (position === "right-bottom") {
        position = "right";
      }
      if (position === "left-bottom") {
        position = "left";
      }
    }

    let marginBottom;
    if (isMobileDevice()) {
      marginBottom = user.chat.mobileMarginBottom;
    } else {
      console.log("chatWidgetPosition", user.chat.marginBottom);
      marginBottom = user.chat.marginBottom;
    }
    console.log("marginBottom", marginBottom);

    let marginLeft;
    if (isMobileDevice()) {
      marginLeft = user.chat.mobileMarginHorizontal;
    } else {
      marginLeft = user.chat.marginHorizontal;
    }

    let marginRight;
    if (isMobileDevice()) {
      marginRight = user.chat.mobileMarginHorizontal;
    } else {
      marginRight = user.chat.marginHorizontal;
    }

    console.log("chatWidgetPosition", position);

    const StyledTouchableOpacity = styled.button`
      background-color: ${this.props.userPersona
        ? this.props.userPersona.chat.primaryColor
        : colors.bgVioletBase};
      border-radius: 30px;
      border: none;
      cursor: pointer;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    `;
    // padding: ${isMobileDevice() ? "14px" : "14px"};

    const StyledContainer = styled.div`
      position: fixed;
      bottom: ${marginBottom}px;
      ${props => {
        switch (props.position) {
          case "left":
            return `left: ${marginLeft}px;`;
          case "center":
            return "left: 50%; transform: translateX(-50%);";
          case "right":
            return `right: ${marginRight}px;`;
          default:
            return "right: ${marginRight}px;";
        }
      }};
      display: flex;
      flex-direction: column;
      align-items: ${props => {
        switch (props.position) {
          case "left":
            return `start`;
          case "center":
            return "center";
          case "right":
            return `end`;
          default:
            return "center";
        }
      }};
    `;

    const InfoMessage = styled.div`
      width: 200px;
      position: relative;
      background-color: #fff;
      color: #000;
      padding: 15px 20px;
      border-radius: 20px;
      margin-bottom: 10px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 15px;
      line-height: 20px;
      font-family: CircularStd-Book;
      letter-spacing: -0.01em;
      cursor: pointer;

      &:after {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        border-width: 10px;
        border-style: solid;
        border-color: #fff transparent transparent transparent;
      }
    `;

    const CloseButton = styled.button`
      background: none;
      border: none;
      font-size: 14px;
      font-family: CircularStd-Bold;
      line-height: 18px;
      letter-spacing: -0.01em;
      position: absolute;
      top: 0;
      left: 0;
      margin: -30px 0px 0px 0px;
      padding: 5px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
      color: ${colors.inkBase};
    `;

    return (
      <View>
        {this.props.userPersona && (
          <StyledContainer position={position}>
            {this.state.showInfoMessage && (
              <React.Fragment>
                <InfoMessage
                  onClick={() => {
                    this.dismissInfoMessage();
                    this.props.onPress();
                  }}
                >
                  <CloseButton onClick={() => this.dismissInfoMessage()}>
                    ✕
                  </CloseButton>
                  {this.state.typingMessage}
                  {/*
                  <TypingMessage message={user.chat.chatFloatingButtonDescription} />
                  */}
                </InfoMessage>
              </React.Fragment>
            )}
            {this.state.isTesting && (
              <div
                style={{
                  position: "absolute",
                  top: "-41px",
                  right: "0px",
                  backgroundColor: "red",
                  color: "white",
                  borderRadius: "30px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  zIndex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)"
                }}
              >
                <View ml={14} my={10}>
                  <View
                    style={{
                      fontSize: "12px",
                      lineHeight: "14px",
                      fontFamily: "CircularStd-Book"
                    }}
                  >
                    Test Mode
                  </View>
                  {/*
                  <View style={{ fontSize: 10 }}>Visible just for you</View>
                  */}
                </View>
                <View
                  mr={14}
                  ml={10}
                  style={{
                    fontSize: "14px",
                    lineHeight: "14px",
                    fontFamily: "CircularStd-Book",
                    cursor: "pointer",
                    background: "none",
                    border: "none",
                    color: "white",
                    fontWeight: "bold"
                  }}
                  onClick={this.hideTestMode}
                >
                  ✕
                </View>
              </div>
            )}
            <StyledTouchableOpacity
              onClick={() => {
                this.dismissInfoMessage();
                this.props.onPress();
              }}
            >
              <div
                style={{
                  // flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Image
                  source={{
                    uri:
                      "https://traveline-images.s3.amazonaws.com/shopify/mod/logo_ai_550x550.png"
                  }}
                  style={{
                    width: 20, // isMobileDevice() ? 34 : 20,
                    height: 20, // isMobileDevice() ? 34 : 20
                    marginLeft: 14,
                    marginTop: 14,
                    marginBottom: 14
                  }}
                  resizeMode="contain"
                />
                {true ? (
                  <Text
                    // ml={8}
                    // mr={8}
                    regular_none_bold
                    style={{
                      color: "#fff",
                      lineHeight: "18px",
                      whiteSpace: "nowrap",
                      marginLeft: 8,
                      marginRight: 14,
                      marginTop: 14,
                      marginBottom: 14
                    }}
                  >
                    {this.props.userPersona.chat.name}
                  </Text>
                ) : null}
              </div>
            </StyledTouchableOpacity>
          </StyledContainer>
        )}
      </View>
    );
  }
}

export default FloatingChatButton;
