"use strict";
const colors = {
  txt: "#231f20", // '#d09f43', // '#484858',
  text: "#231f20",

  selectedOption: "#F7F7F7",

  txtMain: "#757575",
  txtMainRed: "#d09f43", //'#1C71BE', // '#FF5A4F',
  txtDescription: "#757575",
  txtDark: "#222222", // '#484858',
  txtWhite: "#ffffff",
  txtError: "#fb641d",
  txtSuccess: "#25ce56",
  txtGrey: "#767678",

  bgMain: "#d09f43", //'#1C71BE',
  bgMainDark: "#d09f43", // '#1C71BE', // For screens > auth
  bgMainRed: "#d09f43", //  '#1C71BE', // '#FF5A4F',
  bgWhite: "#ffffff",
  bgError: "#fb643d",
  bgChat: "#f1f1f2",
  bgTopMenuActive: "#000000",
  bgMainColor: "#d09f43",

  bdMain: "#00a689",
  bdWhite: "#ffffff",
  bdLine: "#dddddd",
  bdInput: "#cbcbcb",

  btnMainGrey: "#EFEFEF",

  bgMainOrange: "#231f20", // '#d09f43',
  bgMainBlue: "#1C71BE",
  bgMainImage: "#EBEBEB",
  bgMainImageView: "#313132", // '#85683F',
  bgMainCarousel: "#19191B",
  bgMainGrey: "#F2F2F2", // '#F5F5F5',
  link: "#7846F3", // '#0645AD',
  button: "#232121",

  bgPink: "#7E7CAD", // '#f470b2',

  bgOffer: "#C3262C", // '#EE2632',
  bgOfferGrey: "#B3B3B3",
  bgMainOffer: "#E52E36",
  bgMainYellow: "#F6F0E9",
  bgMainTab: "#ECEDE8",
  bgVioletBase: "#A581FD",
  bgVioletLighter: "#EDE6FF", // Violet/Lighter
  bgVioletDark: "#7846F3",
  bgLight: "#999999",
  inkLight: "#999999",
  inkDarkest: "#090A0A" /* Ink/Darkest */,
  inkDark: "#2F2F2F",
  inkLightest: "#F2F2F2" /* Ink/Lightest */,
  inkLighter: "#CCCCCC",
  inkBase: "#000000" /* Ink/Base */,

  bgLogo: "#1C3FFF"
};

export default colors;
