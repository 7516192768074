import React, { Component } from "react";
import Lottie from "lottie-react";

import {
  View,
  Text,
  Image,
  TouchableOpacity,
  ScrollView,
  TextInput
} from "./../../components/styled/styledComponents";
import FloatingChatButton from "./FloatingChatButton";
import ChatWindow from "./ChatWindow";
import loadingActivityIndicator from "./../../assets/loading_activity.json";

import config from "../../config";
import colors from "./../../resources/styles/colors";

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    // Initialize state in the constructor
    this.state = {
      isChatWindowVisible: false,
      userPersona: false,
      context: null,
      countdown: 10, // Initial countdown value
      isTest: false,
      isLocal: false,
      showChat: false,
      isTesting: false,
      testSession: null
    };

    // Bind methods to ensure 'this' context
    this.toggleChatWindow = this.toggleChatWindow.bind(this);
    this.startCountdown = this.startCountdown.bind(this);
    // this.renderCountdown = this.renderCountdown.bind(this);

    // Initialize interval ID to null
    this.countdownInterval = null;
  }

  componentWillUnmount() {
    // Clear the interval when the component unmounts to prevent memory leaks
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  }

  startCountdown = () => {
    // this.setState({ countdown: 5 }); // Reset countdown to 5 seconds
    this.countdownInterval = setInterval(() => {
      if (this.state.countdown >= 0) {
        this.setState(prevState => ({ countdown: prevState.countdown - 1 }));
        if (this.state.countdown === 0) {
          clearInterval(this.countdownInterval);
          this.countdownInterval = null;
          this.setState({ isChatWindowVisible: true }); // Open chat window after countdown
        }
      }
      console.log("this.countdownInterval", this.countdownInterval);
    }, 1000); // Decrease countdown every second
  };

  getCleanDomain = () => {
    let domain = window.location.hostname;

    let url = window.location.href;
    // Create a URL object
    const urlObj = new URL(url);
    // Use URLSearchParams to get the query parameter named 'shop'
    const shopParam = urlObj.searchParams.get("shop");
    const isActive = urlObj.searchParams.get("isActive");
    const isLocal = urlObj.searchParams.get("isLocal");

    // Check if the domain contains 'localhost'
    if (
      true ||
      domain.includes("localhost") ||
      domain.includes("192.168.0.149") ||
      domain.includes(config.testSite)
    ) {
      console.log("isLocal", isLocal);
      if (shopParam) {
        let shop = shopParam.endsWith("/") ? shopParam.slice(0, -1) : shopParam;

        const getCleanDomainFromURL = input => {
          let urlString = input;
          // Check if the input already includes a protocol; if not, prepend 'https://'
          if (!input.match(/^[a-zA-Z]+:\/\//)) {
            urlString = `https://${input}`;
          }

          try {
            const url = new URL(urlString);
            // Use a regular expression to remove any 'www.' prefix from the hostname
            const domain = url.hostname.replace(/^www\./, "");
            return domain; // Returns the domain name without www.
          } catch (error) {
            console.error("Invalid URL");
            return null;
          }
        };

        let websiteDomain = getCleanDomainFromURL(shop);
        domain = `${websiteDomain}`;
        // if (isActive) {
        //   domain = `${websiteDomain}`;
        // } else {
        //   domain = `tmp_${websiteDomain}`;
        // }
        this.setState({
          isLocal: isLocal,
          isTest: 1
        });
        // this.setState({
        //   isChatWindowVisible: true
        // });
      } else {
        domain = "mod-ai-stylist.myshopify.com";
        // return "mod-ai-stylist.myshopify.com";
      }

      return domain;
    }

    // if (domain.startsWith("www.")) {
    //   domain = domain.substring(4);
    // }
    domain = window.shopifyShopDomain;

    console.log("domain2", domain);

    let isChatWindowVisible = false;
    // let url = window.location.href;
    if (
      url == "https://demo.modstylist.com" ||
      url == "https://demo.modstylist.com/" ||
      url == "https://fashion.runachat.ai" ||
      url == "https://fashion.runachat.ai/"

      // domain.includes("mod-ai.myshopify.com") ||
      // domain.includes("runa-ai-fashion.myshopify.com")
    ) {
      isChatWindowVisible = true;
    }
    console.log("isChatWindowVisible", isChatWindowVisible);

    this.setState({
      isChatWindowVisible: isChatWindowVisible,
      isLocal: isLocal ? isLocal : this.state.isLocal
    });

    let shopData = window.shopifyShop;
    console.log("shopData", shopData);

    console.log("domain", domain);

    let shopifyCurrency = window.shopifyCurrency;
    let shopifyMoneyFormat = window.shopifyMoneyFormat;

    console.log(shopifyCurrency, shopifyMoneyFormat);

    return domain;
  };

  getContext = async () => {
    const lambdaUrl = `https://enofvc3o7f.execute-api.us-east-1.amazonaws.com/production/mod-context`;
    // Make a GET request to the Lambda function
    let response = await fetch(lambdaUrl);
    let data = await response.json();
    console.log("data context", data);
    console.log("this.state.userPersona", this.state.userPersona);
    let context = data.webChatContext;

    return context;

    // this.setState({
    //   systemMessage: {
    //     role: "system",
    //     content: context
    //   }
    // });
  };

  getUser = async () => {
    let domain = this.getCleanDomain();
    console.log("domain", domain);
    let shop = domain;
    let url = `${config.appServerUrl}?action=getUser&shop=${shop}`;
    console.log("shop", shop);
    console.log("get user url", url);

    let responseJson = await fetch(url);
    let response = await responseJson.json();
    let user = response.data;

    let context = await this.getContext();
    console.log("user from db", user);

    if (user.context && user.context.categories) {
      if (user.contextManual && user.contextManual.categories) {
        context = context.replace(
          /__CONTEXT_CATEGORIES_LIST__/g,
          user.contextManual.categories
        );
      } else {
        context = context.replace(
          /__CONTEXT_CATEGORIES_LIST__/g,
          user.context.categories
        );
      }
      // context = context.replace(
      //   /__CONTEXT_CATEGORIES_LIST__/g,
      //   user.context.categories
      // );
      context = context.replace(
        "__CONTEXT_CATEGORIES_DESCRIPTION__",
        user.context.summary
      );
    }

    if (user.chat && user.chat.customContext) {
      context = context.replace(
        "__CONTEXT_CUSTOM_INSTRUCTIONS__",
        user.chat.customContext
      );
    }

    if (user.chat && user.chat.additionalContext) {
      context = context.replace(
        "__CONTEXT_ADDITIONAL_INSTRUCTIONS__",
        user.additionalContext
      );
    } else {
      context = context.replace("__CONTEXT_ADDITIONAL_INSTRUCTIONS__", "");
    }

    console.log("user from db", user);
    console.log("context app", context);

    if (this.state.isTest) {
      this.startCountdown();
    }

    this.setState({ userPersona: user, context: context });

    return user;
  };

  checkLogChat = async () => {
    let url = window.location.href;
    const urlObj = new URL(url);
    let adminMode = urlObj.searchParams.get("admin_mode");
    let channelId = urlObj.searchParams.get("channel_id");

    if (!!adminMode && !!channelId) {
      this.setState({
        isAdmin: true,
        adminChannelId: channelId
      });
    }
  };

  async componentDidMount() {
    let user = await this.getUser();

    this.checkLogChat();

    if (user && !user.showChat) {
      console.log("check test mode");
      this.checkTestEnabled();
    }
  }

  topUp = () => {
    var elements = document.querySelectorAll(".baDropdownStyle.top_right");
    if (elements.length > 0) {
      elements.forEach(function(element) {
        element.style.top = "-100px"; // "0px"
      });
    }
  };

  topDown = () => {
    var elements = document.querySelectorAll(".baDropdownStyle.top_right");

    if (elements.length > 0) {
      elements.forEach(function(element) {
        element.style.top = "40px";
      });
    }
  };

  // topUp = () => {
  //   var elements = document.querySelectorAll(".bacurr-dropdownChoices");
  //   if (elements.length > 0) {
  //     elements.forEach(function(element) {
  //       element.style.top = "-100px"; // "0px"
  //     });
  //   }
  // };
  //
  // topDown = () => {
  //   var elements = document.querySelectorAll(".bacurr-dropdownChoices");
  //
  //   if (elements.length > 0) {
  //     elements.forEach(function(element) {
  //       element.style.top = "0px";
  //     });
  //   }
  // };

  toggleChatWindow = () => {
    // console.log("toogle chat window");
    let isVisible = this.state.isChatWindowVisible;
    this.setState(prevState => ({
      isChatWindowVisible: !prevState.isChatWindowVisible
    }));

    // patch for https://buzzwear.co/
    if (
      this.state.userPersona &&
      this.state.userPersona.shop &&
      this.state.userPersona.shop == "buzzwearbrand.myshopify.com"
    ) {
      if (isVisible) {
        this.topDown();
      } else {
        this.topUp();
      }
    }
  };

  saveTestEnabledToLocalStorage(test_session) {
    const data = {
      testEnabled: "1",
      test_session: test_session,
      timestamp: new Date().getTime() // Store the current time in milliseconds
    };

    localStorage.setItem("testEnabled", JSON.stringify(data));
  }

  getTestEnabledFromLocalStorage() {
    const dataString = localStorage.getItem("testEnabled");
    if (!dataString) return null; // If no data is found

    const data = JSON.parse(dataString);

    // const expireTime = 12 * 60 * 60 * 1000; // 12 * 60 * 60 * 1000;  // 12 hours // one week
    // const now = new Date().getTime();
    //
    // // Check if the data is older than one day
    // if (now - data.timestamp > expireTime) {
    //   localStorage.removeItem("testEnabled"); // Clear expired data
    //   return null;
    // }

    return data;
  }

  checkTestEnabled = async () => {
    let url = window.location.href;
    // Create a URL object
    const urlObj = new URL(url);
    // Use URLSearchParams to get the query parameter named 'shop'
    let isTestEnabled = urlObj.searchParams.get("test_enabled");
    let test_session = urlObj.searchParams.get("test_session");
    console.log("isTestEnabled urlObj.searchParams", urlObj.searchParams);
    console.log("isTestEnabled", isTestEnabled, test_session);

    if (isTestEnabled) {
      console.log("enable test mode");

      await this.saveTestEnabledToLocalStorage(test_session);
    } else {
      let data = await this.getTestEnabledFromLocalStorage();
      console.log("data from localStorage", data);

      if (!!data) {
        isTestEnabled = true;
        test_session = data.test_session;
      }

      // if (data && !!data.testEnabled) {
      //   console.log("test enabled in the last 12 hours");
      //   isTestEnabled = true;
      // } else {
      //   return false;
      // }
      // check if it is enabled in the last 12 hours
    }

    console.log("isTestEnabled", isTestEnabled);

    if (!!isTestEnabled) {
      this.setState({
        isTesting: true,
        testSession: test_session
      });
    }

    // return !!isTestEnabled;
  };

  disableTesting = () => {
    localStorage.removeItem("testEnabled");
    localStorage.removeItem("infoMessageDismissedDate");
    this.setState({ isTesting: false }); // Update the state to reflect that testing is disabled

    // Redirect to the main domain without any parameters
    const mainDomain =
      window.location.protocol +
      "//" +
      window.location.hostname +
      (window.location.port ? ":" + window.location.port : "");
    window.location.href = mainDomain;
  };

  render() {
    console.log("this.state.userPersona", this.state.userPersona);

    let url = window.location.href;
    // Create a URL object
    const urlObj = new URL(url);
    // Use URLSearchParams to get the query parameter named 'shop'
    const isTestEnabled = urlObj.searchParams.get("test_enabled");
    const test_session = urlObj.searchParams.get("test_session");

    let showChat = false;
    let activeChat = null;
    if (
      this.state.userPersona &&
      !!this.state.userPersona.chat &&
      !!this.state.userPersona.chat.enableChat
    ) {
      showChat = true;
      activeChat = "default";
    } else if (
      this.state.userPersona &&
      !!this.state.userPersona.chat &&
      !!this.state.isTesting
    ) {
      showChat = true;
      activeChat = "testing";
    }

    return (
      <React.Fragment>
        {false &&
          this.state.isTest && (
            <View>
              <View
                style={{
                  // display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <View
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Text mt={34} h5 regular_none_bold>
                    Test the experience
                  </Text>
                </View>
                {!this.state.userPersona && (
                  <View
                    style={{
                      // display: "flex",
                      flex: 1,
                      // overflowY: "auto",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <View
                      mt={12}
                      style={{
                        display: "flex",
                        // flex: 1,
                        // overflowY: "auto",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <Lottie
                        animationData={loadingActivityIndicator}
                        loop={true}
                        style={{ width: 80, height: 80 }}
                      />
                      <Text> </Text>
                    </View>
                  </View>
                )}

                {this.state.userPersona && (
                  <React.Fragment>
                    <View
                      mt={24}
                      style={{
                        flex: 1,
                        // flexGrow: 1,
                        // backgroundColor: colors.bgVioletBase,
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center"
                      }}
                    >
                      <Image
                        source={{
                          uri:
                            "https://traveline-images.s3.amazonaws.com/shopify/mod/logo_ai_1024x1024.png" // this.state.userPersona.chat.imagePreviewUrl
                        }}
                        style={{
                          width: 80,
                          height: 80,
                          borderRadius: 80,
                          backgroundColor: this.state.userPersona.chat
                            .primaryColor // colors.bgVioletBase
                          // borderColor: colors.bdLine,
                          // borderWidth: 1,
                        }}
                        resizeMode="contain"
                      />
                    </View>

                    <Text
                      mt={8}
                      regular_none_bold
                      mx={24}
                      style={{ textAlign: "center" }}
                    >
                      {/*
                    {this.state.userPersona.chat.name}
                    */}
                      AI Stylist
                    </Text>

                    <Text
                      mt={24}
                      regular_none_regular
                      style={{ textAlign: "center" }}
                    >
                      Connected to
                    </Text>

                    <View
                      mt={24}
                      style={{
                        flex: 1,
                        // flexGrow: 1,
                        // backgroundColor: colors.bgVioletBase,
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center"
                      }}
                    >
                      <Image
                        source={{
                          uri: this.state.userPersona.logo
                        }}
                        style={{
                          width: 80,
                          height: 80,
                          borderRadius: 80,
                          // backgroundColor: this.state.userPersona.chat.primaryColor // colors.bgVioletBase
                          borderColor: colors.bdLine,
                          borderWidth: 1
                        }}
                        resizeMode="contain"
                      />
                    </View>

                    <Text
                      mt={8}
                      regular_none_bold
                      mx={24}
                      style={{ textAlign: "center" }}
                    >
                      {this.state.userPersona.shop.replace("tmp_", "")}
                    </Text>

                    {this.state.countdown > 0 && (
                      <View
                        style={{
                          // position: "absolute",
                          // bottom: 20,
                          // right: 20,
                          // zIndex: 100
                        }}
                      >
                        <Text
                          mt={24}
                          small_tight_regular
                          // numberOfLines={4}
                          ellipsizeMode="tail"
                          mx={32}
                          style={{
                            color: colors.inkLight,
                            textAlign: "center"
                          }}
                        >
                          {/*
                        Click on the AI stylist button from the footer, or the
                        chat will open in {this.state.countdown} seconds...
            */}
                          Opening chat in {this.state.countdown} seconds...
                        </Text>
                      </View>
                    )}

                    {/*
                <Text
                  mt={8}
                  small_tight_regular
                  mx={24}
                  style={{ textAlign: "center" }}
                >
                  AI Nutritionist
                </Text>
                */}

                    {/*
                <Text
                  mt={8}
                  small_tight_regular
                  numberOfLines={4}
                  ellipsizeMode="tail"
                  mx={32}
                  style={{ color: colors.inkLight, textAlign: "center" }}
                >
                  {this.state.userPersona.chat.description}
                </Text>
                */}
                  </React.Fragment>
                )}
              </View>
            </View>
          )}

        {!!this.state.userPersona && (
          <React.Fragment>
            <ChatWindow
              userPersona={this.state.userPersona}
              context={this.state.context}
              visible={true}
              onClose={this.toggleChatWindow}
              isTest={this.state.isTest}
              isLocal={this.state.isLocal}
              testSession={this.state.testSession}
              adminChannelId={this.state.adminChannelId}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default App;
