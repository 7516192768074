// export const fontSizes = [12, 14, 16, 24, 32, 48, 64, 96, 128];
// export const spaces = [0, 5, 10, 20, 40, 80, 160, 320];

export const fontSizes = [];
export const spaces = [];
export const breakpoints = ['40em', '52em', '64em', '80em'];
// export const breakpoints = ["576", "768", "992", "1200"];

export const colors = {
  txt: "#222222", // '#484858',

  txtMain: "#757575",
  txtMainRed: "#d09f43", //'#1C71BE', // '#FF5A4F',
  txtDescription: "#757575",
  txtDark: "#222222", // '#484858',
  txtWhite: "#ffffff",
  txtError: "#fb641d",
  txtSuccess: "#25ce56",
  txtGrey: "#767678",

  bgMain: "#d09f43", //'#1C71BE',
  bgMainDark: "#d09f43", // '#1C71BE', // For screens > auth
  bgMainRed: "#d09f43", //  '#1C71BE', // '#FF5A4F',
  bgWhite: "#ffffff",
  bgError: "#fb643d",
  bgChat: "#f1f1f2",
  bgTopMenuActive: "#000000",

  bdMain: "#00a689",
  bdWhite: "#ffffff",
  bdLine: "#EBEBEB", // '#dddddd',
  bdInput: "#cbcbcb",

  btnMainGrey: "#EFEFEF",

  bgMainOrange: "#d09f43",
  bgMainBlue: "#1C71BE",
  bgMainImage: "#EBEBEB",
  bgMainGrey: "#F2F2F2", // '#F5F5F5',
  link: "#0645AD",

  // primary: '#423EA2',
  // link: '#1890ff',
  // success: '#52c41a',
  // warning: '#faad14',
  // error: '#e84118',
  // heading: '#423EA2',
  // text: '#484858',
  // disabled: '#f5222d',
  // border: '#423EA2',
};

const theme = {
  fontSizes: { ...fontSizes },
  space: { ...spaces },
  colors: { ...colors },
  breakpoints: [...breakpoints]
};

export default theme;
