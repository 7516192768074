import React, { createRef } from "react";
// import {
//   // SafeAreaView,
//   TextInput,
//   // Text,
//   // View,
//   Switch,
//   TouchableOpacity,
//   StyleSheet,
//   Dimensions,
//   Alert,
//   ScrollView,
//   KeyboardAvoidingView,
//   Platform,
//   // Image as MainImage,
//   StatusBar,
//   ImageBackground,
//   TouchableWithoutFeedback,
//   AsyncStorage,
//   AppState,
//   Keyboard,
//   FlatList,
//   Animated
// } from "react-native";

// import { GiftedChat } from "react-native-gifted-chat";
import { v4 as uuidv4 } from "uuid";

// import { FlashList } from "@shopify/flash-list";

// const { PineconeClient } = require("@pinecone-database/pinecone");
// import { PineconeClient } from "@pinecone-database/pinecone";

// const { Configuration, OpenAIApi } = require("openai");
// import OpenAI from "openai";

// import { setupURLPolyfill } from "react-native-url-polyfill";
// setupURLPolyfill();

// import TypeWriter from "react-native-typewriter";
// import { Svg, Path, Circle } from "react-native-svg";
// import Lottie from "lottie-react-native";
// import SkeletonContent from 'react-native-skeleton-content';
// import { Image as FastImage } from "expo-image";
// import { StateContext } from "./../../State";

// import { Image } from "expo-image";

import Pubnub from "pubnub";
import Lottie from "lottie-react";

import {
  View,
  Text,
  Image,
  TouchableOpacity,
  ScrollView,
  TextInput
} from "./../../components/styled/styledComponents";
// import Container from "./../../components/Container";
// import NavBar from "./../../components/NavBar";

// import Button from "./../../components/form/Button";
// import loadingAnimation from "./../../../assets/images/loading_2.json";

import colors from "./../../resources/styles/colors";
import loaders from "./../../utils/personas_loaders";

import config from "../../config";
// import Image from "./../../components/Image";

import ProfileSilhouette from "./../../utils/ProfileSilhouette";
import urlize from "./../../utils/urlize";

import loadingAnimation from "./../../assets/loading_2.json";
import loadingActivityIndicator from "./../../assets/loading_activity.json";

import Products from "./Products";
import ProductsBar from "./ProductsBar";

// import ChatProducts from "./ChatProducts";
import SlideInAnimation from "./SlideInAnimation";

import modPersona from "./../../utils/persona_mod";
import personas from "./../../utils/personas";
import imgSend from "./../../assets/img_send.png"; // Adjust the path as necessary
import imgSendActive from "./../../assets/img_send_active.png"; // Adjust the path as necessary

// import logoImg from "./../../assets/logo_runa_1024x1024.png";
// import logoImgTransparent from "./../../assets/logo_runa_transparent_1024x1024.png";

import "./ChatStyle.css"; // Adjust the path based on where your file is located

// const ChatProducts = () => {
//   return <div />;
// };
// const SlideInAnimation = () => {
//   return <div />;
// };

let dataEn = {
  triunghi: "triangle",
  "triunghi-intors": "inverted triangle",
  dreptunghi: "rectangle",
  clepsidra: "hourglass",
  oval: "oval",
  ovala: "oval",

  clasica: "classic",
  romantica: "romantic",
  creativa: "creative",
  dramatica: "dramatic",
  naturala: "natural"
};
// let { width, height } = Dimensions.get("window");

function findJSON(text) {
  const pattern = /({[\s\S]*}|\[[\s\S]*\])/g; // /{[\s\S]*?}/g;
  const matches = text.match(pattern) || []; // use empty array if no matches
  const result = [];
  for (const match of matches) {
    try {
      result.push(JSON.parse(match));
    } catch (e) {
      // console.log(`Error parsing JSON: ${match}`, e);
    }
  }
  return result;
}

function removeJsonAndTrim(text) {
  const jsonPattern = /({[\s\S]*}|\[[\s\S]*\])/g;
  const cleanedText = text.replace(jsonPattern, "").trim();
  return cleanedText;
}

// const findJSON = text => {
//   const jsonPattern = /({\s*"(?:[^"\\]|\\.)*"\s*:\s*(?:(?:"(?:[^"\\]|\\.)*"|null|-?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?|true|false)|{(?:[^{}]|{(?:[^{}]|{(?:[^{}]|{[^{}]*})*})*})*}|(?:[^[\]]|\[(?:[^[\]]|\[(?:[^[\]]|\[(?:[^[\]]|\[[^[\]]*\])*])*])*])*)*\s*})/g;
//   const matches = text.match(jsonPattern);
//
//   if (matches) {
//     return matches.map(match => JSON.parse(match));
//   } else {
//     return null;
//   }
// };

// function findJSONInText(text) {
//   let startIndex = -1;
//   let endIndex = -1;
//   let openBraces = 0;
//
//   for (let i = 0; i < text.length; i++) {
//     if (text[i] === '{') {
//       if (startIndex === -1) {
//         startIndex = i;
//       }
//       openBraces++;
//     } else if (text[i] === '}') {
//       openBraces--;
//       if (openBraces === 0) {
//         endIndex = i;
//         break;
//       }
//     }
//   }
//
//   if (startIndex !== -1 && endIndex !== -1) {
//     const jsonString = text.substring(startIndex, endIndex + 1);
//     try {
//       const jsonObject = JSON.parse(jsonString);
//       return jsonObject;
//     } catch (e) {
//       // console.log('Invalid JSON:', e);
//       return null;
//     }
//   } else {
//     // console.log('No JSON found in the text.');
//     return null;
//   }
// }

// function findJSONInText(text) {
//   const jsonString = extractJSON(text);
//
//   if (jsonString) {
//     try {
//       const jsonObject = JSON.parse(jsonString);
//       return jsonObject;
//     } catch (e) {
//       // console.log('Invalid JSON:', e);
//       return null;
//     }
//   } else {
//     // console.log('No JSON found in the text.');
//     return null;
//   }
// }
//
// function extractJSON(text) {
//   let openBraces = 0;
//   let startIndex = -1;
//   let jsonString = '';
//
//   for (let i = 0; i < text.length; i++) {
//     if (text[i] === '{') {
//       if (startIndex === -1) {
//         startIndex = i;
//       }
//       openBraces++;
//     } else if (text[i] === '}') {
//       openBraces--;
//
//       if (openBraces === 0) {
//         jsonString = text.substring(startIndex, i + 1);
//         break;
//       }
//     }
//   }
//
//   return jsonString;
// }

let cxItemsArr = [
  {
    title: "all",
    cx: "0587078766ef9425b"
  },
  {
    title: "premium",
    cx: "a71a817a4ac1240a0"
  },
  {
    title: "basic",
    cx: "61b6d82bf94c94132"
  },
  {
    title: "luxury",
    cx: "77e32b6b55b664b5d"
  },
  {
    title: "sustainable",
    cx: "64b6fd3fc320e4afe"
  }
];

class Typing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayText: ""
    };
    this.startTyping = this.startTyping.bind(this);
  }

  componentDidMount() {
    this.startTyping();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.text !== nextProps.text ||
      this.state.displayText !== nextState.displayText
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.text !== this.props.text) {
      this.startTyping();
    }
  }

  startTyping() {
    const { text } = this.props;
    this.setState({ displayText: "" }, () => {
      setTimeout(() => {
        this.setState({ displayText: text });
      }, 500);
    });
  }

  render() {
    return (
      <View
        typing={1}
        initialDelay={0}
        minDelay={1}
        maxDelay={1}
        onTypingEnd={() => {
          // console.log('typing ended');
          if (this.state.displayText) {
            // console.log('typing ended with text');
            this.props.handleAnimationEnd(this.props.item);
          }
        }}
        // onFinish={() => {
        //   // console.log('Finished typing');
        //   this.props.handleAnimationEnd(this.props.item);
        // }}
      >
        <Text
          small_message
          style={{
            color: colors.inkDarkest
            // flexGrow: 1,
          }}
        >
          {this.state.displayText}
        </Text>
      </View>
    );
  }
}

class TypingAndroid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayText: ""
    };
    // this.startTyping = this.startTyping.bind(this);
  }

  // componentDidMount() {
  //   this.startTyping();
  // }
  //
  // shouldComponentUpdate(nextProps, nextState) {
  //   return (
  //     this.props.text !== nextProps.text ||
  //     this.state.displayText !== nextState.displayText
  //   );
  // }
  //
  // componentDidUpdate(prevProps) {
  //   if (prevProps.text !== this.props.text) {
  //     this.startTyping();
  //   }
  // }

  // startTyping() {
  //   const { text } = this.props;
  //   this.setState({ displayText: '' }, () => {
  //     setTimeout(() => {
  //       this.setState({ displayText: text });
  //     }, 500);
  //   });
  // }

  componentDidMount = () => {
    this.props.handleAnimationEnd(this.props.item);
  };

  render() {
    return (
      <Text
        small_message
        style={{
          color: colors.inkDarkest
          // flexGrow: 1,
        }}
      >
        {this.state.displayText}
      </Text>
    );
  }
}

// class Typing2 extends React.PureComponent {
//   fadeIn = new Animated.Value(0);
//
//   componentDidMount() {
//     Animated.timing(this.fadeIn, {
//       toValue: 1,
//       duration: 20000,
//       useNativeDriver: true,
//     }).start();
//   }
//   render() {
//     let item = this.props.item;
//     return (
//       <View>
//         <Animated.Text style={{ opacity: this.fadeIn }}>
//           <Text
//             small_tight_regular
//             style={{
//               color: colors.inkDarkest,
//               // flexGrow: 1,
//             }}
//           >
//             {item.text}
//           </Text>
//         </Animated.Text>
//
//         <TypeWriter
//           typing={1}
//           // minDelay={10}
//           maxDelay={50}
//           onTypingEnd={() => this.props.handleAnimationEnd(item)}
//         >
//           <Text
//             small_tight_regular
//             style={{
//               color: colors.inkDarkest,
//               // flexGrow: 1,
//             }}
//           >
//             {item.text}
//           </Text>
//         </TypeWriter>
//       </View>
//     );
//   }
// }

// function withMemo(Component) {
//   return class extends React.PureComponent {
//     render() {
//       return <Component {...this.props} />;
//     }
//   };
// }
//
// const MemoizedSlideInAnimation = withMemo(SlideInAnimation);

class Chat extends React.PureComponent {
  // static contextType = StateContext;

  constructor(props) {
    super(props);

    console.log(props);

    let persona = personas[0]; // props.route.params.persona;
    console.log(persona);

    // if (persona.id == 'mod') {
    persona.system = persona.system.replace(
      "__user_name__",
      config.userFullName
    );
    // .replace("__body_shape__", dataEn[urlize(config.siluetteName)])
    // .replace("__personality__", dataEn[urlize(config.personalityName1)])
    // .replace("__personality2__", dataEn[urlize(config.personalityName2)])
    // .replace("__color_palette__", config.paletteName.toLowerCase());
    // }

    let system = persona.system;
    let aiUser = {
      _id: persona.id,
      name: persona.name,
      avatar: `${persona.image}`
    };

    // console.log("system", system);

    // let channelId = uuidv4(); // "user_id_runa_32"; // uuidv4();
    // let { channelId, isNew } = this.getChannelId();
    // console.log("channelId, isNew", channelId, isNew);

    this.flatListRef = React.createRef(); // Creating a ref for the FlatList
    this.scrollViewRef = React.createRef();

    let userPersona = props.userPersona;

    let shopName = userPersona.shop;
    let channelId = `runa_${shopName}_${uuidv4()}`; // uuidv4();
    let isNew = false;

    let url = window.location.href;
    // Create a URL object
    const urlObj = new URL(url);
    // Use URLSearchParams to get the query parameter named 'shop'
    // const isTestEnabled = urlObj.searchParams.get("test_enabled");
    // const test_session = urlObj.searchParams.get("test_session");

    const test_session = props.testSession ? props.testSession : null;
    console.log("test_session in chat ai", test_session);

    let adminChannelId = props.adminChannelId || null;

    this.state = {
      userPersona: userPersona, //null,
      isNewChannelId: isNew,
      adminChannelId: adminChannelId,
      scrollPosition: 0,
      hasAccess: false,
      persona: persona,
      aiUser: aiUser,
      messages: [],
      // appState: AppState.currentState,
      pubnubConnected: false,
      isLoading: false,
      isLoadingSuggestedProducts: false,
      isRenderedDelay: false,
      channelId,
      currentText: "",
      productResults: {},
      gpt4Error: false,
      error: "",
      cx: cxItemsArr[0].cx,
      suggestedUserResponses: [],
      suggestedUserResponsesShow: false,
      isLoadingUserSuggestions: false,
      isFocused: false,
      systemMessage: {
        role: "system",
        content: null // system
      },
      chatLocalStorage: test_session ? test_session : "chatMessages100", // "chatMessages1000", // test_session ? test_session : "chatMessages100",

      systemMessageSuggestedProducts: {
        role: "system",
        //         content: `You are an assistant designed to generate entities from text.
        // Find and extract all the clothing items, shoes, and accessories (use a combination of type, model, color, material, and title) from the text and build a response with the product items that should have the following keys: ti (a text that will be displayed as a title, two-three words length text) and gs(a text that will be used to search on google).
        // Here's an example of your output format:
        //
        // The output must be a JSON object with an array of these items, using the following format:
        // {"sp":[{"ti": "","gs": ""},{"ti": "","gs": ""}]}.
        // If you don't find any items the output must be a JSON object with an empty array: {"sp":[]}.
        //
        // Instructions:
        // - The output should contain just the JSON object with no additional text!
        //     `,

        content: `You are an assistant designed to generate entities from text.
Find if there is any outfit described in the text and extract all the clothing items, shoes, and accessories (use a combination of type, model, color, material, and title) from the text and build a response with the product items that should have the following keys: ti (a text that will be displayed as a title, two-three words length text) and gs(a text that will be used to search on google). If you extract adjectives, make sure they are relevant to the context of the conversation.

The output format should be a JSON object containing an array of these items:
{
"sp": [
{"ti": "", "gs": ""},
{"ti": "", "gs": ""}
]
}
If no items are found, provide a JSON object with an empty array:
{
"sp": []
}
Please note:
The output should only include the JSON object, without any additional text.`
      },

      systemMessageSuggestedUserResponses: {
        role: "system",
        //         content: `You are a style assistant designed to generate suggestions about how the user may continue a conversation.
        // You will receive a conversation and you will respond with a set of 3 predefined suggestions that the user may use to continue the conversation.
        // The output must be a JSON object with these suggestions, using the following format:
        // {"suggestedUserResponses":["", "", ""]}
        //
        // Instructions:
        // - The output should contain just the JSON object with no additional text!
        //
        //     `,
        //       },

        content: `You are a Style Assistant created to offer conversation continuation ideas for users. When provided with a conversation, generate three predefined suggestions that users can utilize to extend the discussion. Ensure that the output is a JSON object containing these suggestions, formatted as follows:

{
"suggestedUserResponses": [
"",
"",
""
]
}

Please note:

The output should include only the JSON object, with no extra text.`
      }

      // Summarize the following meeting notes, by extracting the people, places,
      // and ideas discussed in the text and outputting a JSON object with an array
      // of these entities, using the following format:
      //
      // Input:
      // {
      //     "meeting_notes": "At the meeting, John Smith presented his proposal for a new project in Anytown USA. Jane Doe provided feedback on the project and raised concerns about the budget. Bob Johnson suggested using a different approach to reduce costs. The team discussed the feasibility of the project and decided to move forward with it. Anytown USA was mentioned as the location for the project."
      // }
      //
      // Output:
      // {
      //     "people": ["John Smith", "Jane Doe", "Bob Johnson"],
      //     "places": ["Anytown USA"],
      //     "ideas": ["new project","budget concerns","different approach","feasibility"]
      // }
      //
      // Input:
      // ${input_text}
      //
      // Output:
    };

    this.timers = [];
    this.messageEvents = [];
    this.myListener = {
      message: messageEvent => {
        // let isRendering = this.getIsRendering();
        // if (!isRendering) {
        //   this.parseMessage(messageEvent);
        // } else {
        //   this.messageEvents.push(messageEvent);
        // }

        console.log("messageEvent listener", messageEvent);

        this.parseMessage2(messageEvent);
      },

      status: statusEvent => {
        // console.log('\n\n\n\n\n', statusEvent.category, '\n\n\n\n\n');
        // if (statusEvent.category === 'PNNetworkUpCategory') {
        //   // const [{ pubnub }, dispatch] = this.context;
        //   // console.log('\n\n\n\n\n\nreconnect\n\n\n\n\n\n');
        //   // this.pubnub.addListener(this.myListener);
        //   this.pubnub.subscribe({
        //     channels: [this.state.channelId, config.updatesChannelId],
        //   });
        //   // this.pubnub.reconnect();
        // } else {
        //   // check for other status events - PNTimeoutCategory, PNNetworkIssuesCategory, etc
        //   // console.log(statusEvent.category);
        // }
      }
      // status: statusEvent => {
      //   if (statusEvent.category === 'PNConnectedCategory') {
      //     this.setState({ pubnubConnected: true });
      //   } else if (statusEvent.category === 'PNDisconnectedCategory') {
      //     this.setState({ pubnubConnected: false });
      //   }
      // },
    };

    this.onSend = this.onSend.bind(this);
    this.setMessages = this.setMessages.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
    this.fetchGPT4 = this.fetchGPT4.bind(this);
    this.updateAssistantResponseWithProducts = this.updateAssistantResponseWithProducts.bind(
      this
    );

    this.addLoadingMessage = this.addLoadingMessage.bind(this);
    this.getIsRendering = this.getIsRendering.bind(this);

    this.updateMessage = this.updateMessage.bind(this);
    this.getUser = this.getUser.bind(this);
    this.fetchHistory = this.fetchHistory.bind(this);
    this.queryProducts = this.queryProducts.bind(this);
    this.parseMessage2 = this.parseMessage2.bind(this);

    this.chatContainerRef = React.createRef();
  }

  getChannelId = () => {
    let CHANNEL_ID_LOCAL_STORAGE = "chatChannelId140";
    // let chatName = "chatChannelId135";
    // Check if a channel ID is stored in localStorage and is still valid
    const storedData = localStorage.getItem(CHANNEL_ID_LOCAL_STORAGE);
    if (storedData) {
      const { channelId, expiry } = JSON.parse(storedData);
      const now = new Date();
      if (now.getTime() < expiry) {
        return { channelId, isNew: false }; // Channel ID exists and is still valid
      }
    }

    // Generate a new channel ID and store it with an expiry date of one day
    const newChannelId = uuidv4();
    const expiry = new Date();
    expiry.setDate(expiry.getDate() + 1);
    localStorage.setItem(
      CHANNEL_ID_LOCAL_STORAGE,
      JSON.stringify({ channelId: newChannelId, expiry: expiry.getTime() })
    );

    return { channelId: newChannelId, isNew: true }; // New channel ID generated
  };

  handleFocus = () => {
    this.setState({ isFocused: true });
  };

  handleBlur = () => {
    this.setState({ isFocused: false });
  };

  scrollToBottom = () => {
    // do nothing
    // const chatContainer = this.chatContainerRef.current;
    // if (chatContainer) {
    //   chatContainer.scrollTop = chatContainer.scrollHeight;
    // }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.messages.length !== this.state.messages.length) {
      this.scrollToBottom();
    }
    if (this.state.isLoading) {
      this.scrollToBottom();
    }
  }

  // fetchHistory = () => {
  //   console.log("fetchHistory called");
  //   const channel = `${this.state.channelId}`; // replace with your channel name
  //   this.pubnub.history(
  //     {
  //       channel: channel,
  //       count: 100 // Number of messages to fetch
  //       // You can add other parameters like start, end, reverse
  //     },
  //     (status, response) => {
  //       if (status.error) {
  //         // Handle error
  //         console.log("Error fetching history", status);
  //       } else {
  //         console.log("message history", response.messages);
  //         // Process and add messages to state
  //         const messages = response.messages.reverse().map(message => {
  //           return {
  //             ...message.entry, // Format the message as needed
  //             isRendered: true,
  //             createdAt: new Date(message.timetoken / 10000) // Convert PubNub timetoken to JavaScript Date
  //           };
  //         });
  //         console.log("history messages", messages);
  //         // this.setState({ messages: messages });
  //
  //         // this.setState({
  //         //   messages: GiftedChat.append(this.state.messages, messages)
  //         //   // isLoading: false
  //         //   // suggestedUserResponses: suggestedUserResponses
  //         // });
  //         // this.setState({
  //         //   messages: [...this.state.messages, ...messages]
  //         // });
  //         if (messages && messages.length > 0) {
  //           console.log("list messages");
  //           this.setState({
  //             messages: [...messages]
  //           });
  //         }
  //
  //         for (let item of messages) {
  //           console.log("item suggestedProducts", item);
  //           if (item.suggestedProducts && item.suggestedProducts.length > 0) {
  //             console.log("get suggestedProducts", item);
  //             this.getProducts(item._id, item.suggestedProducts[0]);
  //           }
  //         }
  //       }
  //     }
  //   );
  // };

  formatMessages = async messages => {
    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    let newMessages = [];
    for (let item of messages) {
      console.log("itempppppp", item);
      if (item.text) {
        // await sleep(500);
        newMessages.push(item);
        // let currentMessages = [...this.state.messages];
        // currentMessages.push(item);
        // this.setState({ messages: currentMessages });
        // this.setState(
        //   prevState => {
        //     return {
        //       messages: prevState.messages.push(item),
        //       isLoading: false
        //     };
        //   },
        //   () => {
        //     // this.saveMessagesToLocalStorage(this.state.messages);
        //   }
        // );
      } else {
        // await sleep(500);
        // let tmpItem = JSON.parse(item[0].data.choices[0].message.content);

        let completeMessageId = item[0].data.completeMessageId;
        let countProducts = item[0].data.countProducts;
        // let text = response.data[0].message.content;
        let text = item[0].data.choices[0].message.content;
        console.log("set isLoading pubnub text", text);

        let newMessage = await this.pushAssistantResponse(
          text,
          completeMessageId,
          countProducts
        );
        newMessages.push(newMessage);
        // let tmpItem = JSON.parse(item[0].data.choices[0].message.content);
        // let newItem = {
        //     ...item[0].data.choices[0].message.content,
        //     // _id: messageId,
        //     // text: textCleaned, //text,
        //     // response: text,
        //     // createdAt: new Date(),
        //     // suggestedProducts,
        //     // suggestedUserResponses,
        //     // activeSuggestedProduct,
        //     // products: {},
        //     //  {
        //     //   category_1: {},
        //     //   category_2: {},
        //     // },
        //     user: this.state.aiUser
        //     // {
        //     //   _id: 'mod',
        //     //   name: 'MOD',
        //     //   avatar:
        //     //     'https://traveline-images.s3.amazonaws.com/fashionapp/general/logo_chat.png',
        //     // },
        //   }
        // newMessages.push(newItem);
      }
    }

    console.log("newMessages1111", newMessages);

    // function removeDuplicates(messages) {
    //   const uniqueMessages = {};
    //
    //   messages.forEach(message => {
    //     const id = message._id;
    //     if (
    //       !uniqueMessages[id] ||
    //       (uniqueMessages[id] &&
    //         uniqueMessages[id].suggestedProducts &&
    //         uniqueMessages[id].suggestedProducts.length === 0 &&
    //         message.suggestedProducts.length > 0)
    //     ) {
    //       uniqueMessages[id] = message;
    //     }
    //   });
    //
    //   return Object.values(uniqueMessages);
    // }

    function removeDuplicates(messages) {
      const uniqueMessages = {};

      messages.forEach(message => {
        const id = message._id;
        if (
          !uniqueMessages[id] ||
          (!uniqueMessages[id].suggestedProducts?.length &&
            message.suggestedProducts?.length > 0)
        ) {
          uniqueMessages[id] = message;
        }
      });

      return Object.values(uniqueMessages);
    }

    newMessages = removeDuplicates(newMessages);

    this.setState({ messages: newMessages }, async () => {
      // this.saveMessagesToLocalStorage(this.state.messages);
      for (let item of newMessages) {
        if (item.suggestedProducts && item.suggestedProducts.length > 0) {
          console.log("populate products", item);
          this.getProducts(item._id, item.suggestedProducts[0]);
          function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
          }
          await sleep(200);
        }
      }
    });

    // await sleep(1000);

    return newMessages;
  };

  // parseAssistantResponse = async (text, completeMessageId, countProducts) => {
  //   let info = null;
  //   let textCleaned;
  //   let suggestedUserResponses = null;
  //   let suggestedProducts = null;
  //   let activeSuggestedProduct = null;
  //   let haveSuggestedProducts = false;
  //
  //   console.log("text", text);
  //
  //   let responseJson = {};
  //   try {
  //     responseJson = JSON.parse(text);
  //     // suggestedUserResponses = responseJson.suggestions;
  //     textCleaned = responseJson.text; // responseJson.shortResponse; // text;
  //     suggestedProducts = responseJson.products;
  //     console.log("completeMessageId suggestedProducts", suggestedProducts);
  //     if (suggestedProducts && !Array.isArray(suggestedProducts)) {
  //       console.log("completeMessageId - not array");
  //       suggestedProducts = [];
  //     }
  //     if (suggestedProducts && suggestedProducts.length > 0) {
  //       let firstItem = suggestedProducts[0];
  //       console.log("completeMessageId - firstItem", firstItem);
  //       if (!firstItem.id || !firstItem.title || !firstItem.query) {
  //         console.log("completeMessageId - incomplete array");
  //         // incomplete products
  //         suggestedProducts = [];
  //       }
  //     }
  //
  //     console.log("suggestedProducts push 1", suggestedProducts);
  //     suggestedProducts = this.parseSuggestions(suggestedProducts);
  //     console.log("suggestedProducts push 2", suggestedProducts);
  //
  //     //console.log(suggestedProducts);
  //   } catch (e) {
  //     textCleaned = text;
  //   }
  //
  //   let messageId = completeMessageId ? completeMessageId : uuidv4();
  //   console.log("textCleaned1", textCleaned);
  //   let messages = [
  //     {
  //       _id: messageId,
  //       text: textCleaned, //text,
  //       response: text,
  //       createdAt: new Date(),
  //       suggestedProducts,
  //       suggestedUserResponses,
  //       // activeSuggestedProduct,
  //       products: {},
  //       //  {
  //       //   category_1: {},
  //       //   category_2: {},
  //       // },
  //       user: this.state.aiUser
  //       // {
  //       //   _id: 'mod',
  //       //   name: 'MOD',
  //       //   avatar:
  //       //     'https://traveline-images.s3.amazonaws.com/fashionapp/general/logo_chat.png',
  //       // },
  //     }
  //   ];
  //   // console.log('new message', messages[0]);
  //
  //   // this.onSend(previousMessages);
  //   // this.setState({
  //   //   messages: GiftedChat.append(this.state.messages, messages),
  //   //   suggestedUserResponses: suggestedUserResponses,
  //   // });
  //
  //   // find message and replace it
  //   console.log("completeMessageId", completeMessageId);
  //   console.log("current messages", this.state.messages);
  //   let currentMessages = this.state.messages;
  //   let messageExists = currentMessages.find(item => item._id == messageId);
  //   if (messageExists) {
  //     console.log("completeMessageId updateing message");
  //     const updateMessage = (messageId, newMessage) => {
  //       this.setState(
  //         prevState => {
  //           const updatedMessages = prevState.messages.map(
  //             message => (message._id === messageId ? newMessage : message)
  //           );
  //
  //           return { messages: updatedMessages, isLoading: false };
  //         },
  //         () => {
  //           this.saveMessagesToLocalStorage(this.state.messages);
  //         }
  //       );
  //     };
  //     updateMessage(messageId, messages[0]);
  //   } else {
  //     let newMessage = messages[0];
  //     // if (newMessage.products && !Array.isArray(newMessage.products)) {
  //     //   delete  newMessage.products;
  //     //   delete newMessage.suggestedProducts  ;
  //     // }
  //
  //     console.log("completeMessageId new message", newMessage);
  //
  //     console.log("...this.state.messages", this.state.messages);
  //     let currentMessages = [...this.state.messages];
  //     // message.suggestedProducts=[]
  //     currentMessages.push(newMessage);
  //
  //     console.log("currentMessages", currentMessages);
  //     console.log("completeMessageId countProducts", countProducts);
  //     let isLoading = false;
  //     console.log("set isLoading countProducts", countProducts);
  //     if (!!parseInt(countProducts)) {
  //       console.log("set isLoading  ------ innnnn!!!");
  //       isLoading = true;
  //     }
  //
  //     this.setState(
  //       {
  //         messages: currentMessages
  //         // isLoading: isLoading,
  //         // gpt4Error: false
  //       },
  //       () => {
  //         this.saveMessagesToLocalStorage(this.state.messages);
  //       }
  //     );
  //
  //     // commented for isLoading: true
  //     if (isLoading) {
  //       setTimeout(() => {
  //         this.setState({ isLoading: isLoading, gpt4Error: false });
  //       }, 100);
  //     }
  //
  //     // this.addMessages(messages, suggestedUserResponses);
  //   }
  //   console.log("completeMessageId suggestedProducts", suggestedProducts);
  //   if (suggestedProducts) {
  //     setTimeout(() => {
  //       this.updateMessageSuggestedProducts(messageId, suggestedProducts);
  //     }, 200);
  //   }
  //
  //   // if (haveSuggestedProducts) {
  //   //   this.getOpenAIDataForMessage(textCleaned, messageId);
  //   // }
  //
  //   // if (true) {
  //   //   this.getOpenAIDataForMessage(textCleaned, messageId);
  //   // }
  //
  //   // suggestions not active
  //   // this.getOpenAIDataForSuggestedUserResponses(null, messageId);
  //
  //   // setTimeout(() => {
  //   //   this.getOpenAIDataForSuggestedUserResponses(null, messageId);
  //   // }, 200);
  //
  //   // this.updateMessageSuggestedProducts(messageId, suggestedProducts);
  //
  //   // call products api if exists
  //   // if (suggestedProducts) {
  //   //   this.getProducts(messageId, suggestedProducts[0]);
  //   // }
  // };

  fetchAdminHistory = async () => {
    const channel = `${this.state.adminChannelId}`; // replace with your channel name

    console.log("fetchHistory called", channel);

    // this.setState({
    //   // messages: messages
    //   messages: [{id:1}]
    // });

    const response = await new Promise((resolve, reject) => {
      this.pubnub.history(
        {
          channel: channel,
          count: 100 // Number of messages to fetch
          // You can add other parameters like start, end, reverse
        },
        (status, response) => {
          if (status.error) {
            reject(status); // Reject the Promise if there's an error
          } else {
            resolve(response); // Resolve the Promise with the response
          }
        }
      );
    });

    console.log("message history 2", response.messages);
    const messages = response.messages.map(message => {
      return {
        ...message.entry, // Format the message as needed
        isRendered: true,
        createdAt: new Date(message.timetoken / 10000) // Convert PubNub timetoken to JavaScript Date
      };
    });
    console.log("history messages", messages);

    let formattedMessages = this.formatMessages(messages);
    console.log("formattedMessages", formattedMessages);
    // this.setState({ messages: formattedMessages });

    // if (messages && messages.length > 0) {
    //   console.log("update messages in state");
    //   this.setState({
    //     // messages: messages
    //     messages: messages
    //   });
    // }
    //
    // messages.forEach(item => {
    //   if (item.suggestedProducts && item.suggestedProducts.length > 0) {
    //     console.log("get suggestedProducts", item);
    //     this.getProducts(item._id, item.suggestedProducts[0]);
    //   }
    // });

    return messages;
  };

  fetchHistory = async () => {
    console.log("fetchHistory called");
    const channel = `${this.state.channelId}`; // replace with your channel name

    // this.setState({
    //   // messages: messages
    //   messages: [{id:1}]
    // });

    const response = await new Promise((resolve, reject) => {
      this.pubnub.history(
        {
          channel: channel,
          count: 50 // Number of messages to fetch
          // You can add other parameters like start, end, reverse
        },
        (status, response) => {
          if (status.error) {
            reject(status); // Reject the Promise if there's an error
          } else {
            resolve(response); // Resolve the Promise with the response
          }
        }
      );
    });

    console.log("message history 2", response.messages);
    const messages = response.messages.map(message => {
      return {
        ...message.entry, // Format the message as needed
        isRendered: true,
        createdAt: new Date(message.timetoken / 10000) // Convert PubNub timetoken to JavaScript Date
      };
    });
    console.log("history messages", messages);

    // if (messages && messages.length > 0) {
    //   console.log("update messages in state");
    //   this.setState({
    //     // messages: messages
    //     messages: messages
    //   });
    // }

    messages.forEach(item => {
      if (item.suggestedProducts && item.suggestedProducts.length > 0) {
        console.log("get suggestedProducts", item);
        this.getProducts(item._id, item.suggestedProducts[0]);
      }
    });

    return messages;
  };

  parseMessagesInQueue = () => {
    //console.log('this.messageEvents', this.messageEvents);
    if (this.messageEvents.length > 0) {
      // let messageEvents = [...this.messageEvents];
      let count = 20;
      for (let messageEvent of this.messageEvents) {
        setTimeout(() => {
          this.parseMessage(messageEvent);
        }, count);
        count = count + 500;
      }
      this.messageEvents = [];
    }
  };

  // parseMessage2 = messageEvent => {
  //   if (messageEvent.channel == this.state.channelId) {
  //     //console.log('RESPONE FROM PUBNUB', messageEvent);
  //     let response = messageEvent.message[0]; // await this.getOpenAIData(text);
  //
  //     let messageId = response.messageId;
  //     // console.log('messageId', messageId);
  //
  //     let isSuggestion = response.isSuggestion;
  //     // console.log('isSuggestion', isSuggestion);
  //
  //     console.log("response", response);
  //
  //     if (response) {
  //       let text = response.data.choices[0].message.content;
  //       // console.log('pubnub text', text);
  //       this.pushAssistantResponse(text);
  //
  //       config.analytics.trackProduction(`Chat Message Response`, {
  //         distinct_id: config.userId,
  //         chatPersonality: this.state.persona.name,
  //         chatMessage: text,
  //         chatChannelId: this.state.channelId
  //       });
  //     } else {
  //       // there was an error, retry
  //       // the only error that should be listed
  //       this.setState({
  //         gpt4Error: true,
  //         isLoading: false,
  //         isLoadingSuggestedProducts: false,
  //         isLoadingUserSuggestions: false,
  //         error: "response is empty"
  //       });
  //     }
  //     this.setState({ isLoading: false, isLoadingMessage2: false });
  //   }
  // };

  parseMessage2 = messageEvent => {
    console.log("messageEvent", messageEvent);

    // if (messageEvent.channel == `${this.state.channelId}`) {
    //   let message = messageEvent.message;
    //   console.log("messages item from chat", message);
    //
    //   // this.setState({
    //   //   // messages: GiftedChat.append(this.state.messages, messages),
    //   //   messages: [...prevState.messages, ...messages],
    //   //   isLoading: false
    //   //   // suggestedUserResponses: suggestedUserResponses
    //   // });
    //
    //   // this.setState(prevState => ({
    //   //   messages: [...prevState.messages, ...messages],
    //   //   isLoading: false
    //   // }));
    //   console.log("...this.state.messages", this.state.messages);
    //   let currentMessages = [...this.state.messages];
    //   // message.suggestedProducts=[]
    //   currentMessages.push(message);
    //
    //   console.log("currentMessages", currentMessages);
    //
    //   this.setState(
    //     {
    //       messages: currentMessages
    //       // isLoading: false
    //     },
    //     () => {
    //       this.saveMessagesToLocalStorage(this.state.messages);
    //     }
    //   );
    //
    //   // let item = message;
    //   // if (item.suggestedProducts && item.suggestedProducts.length > 0) {
    //   //   console.log("get suggestedProducts", item);
    //   //   this.getProducts(item._id, item.suggestedProducts[0]);
    //   // }
    // }

    if (messageEvent.channel == this.state.channelId) {
      console.log("RESPONE FROM PUBNUB", messageEvent);
      let response = messageEvent; // await this.getOpenAIData(text);

      let messageId = response.messageId;
      // console.log('messageId', messageId);

      let isSuggestion = response.isSuggestion;
      // console.log('isSuggestion', isSuggestion);

      if (response.message && !!response.message.text) {
        // do nothing
        // allready added to the chat
      } else {
        if (response.message) {
          console.log("response.message", response.message[0]);

          let completeMessageId = response.message[0].data.completeMessageId;
          let countProducts = response.message[0].data.countProducts;
          // let text = response.data[0].message.content;
          let text = response.message[0].data.choices[0].message.content;
          console.log("set isLoading pubnub text", text);
          this.pushAssistantResponse(text, completeMessageId, countProducts);

          config.analytics.trackProduction(`Chat Message Response`, {
            distinct_id: config.userId,
            chatPersonality: this.state.persona.name,
            chatMessage: text,
            chatChannelId: this.state.channelId
          });
        } else {
          // there was an error, retry
          // the only error that should be listed
          console.log("set isLoading:false - 1");
          this.setState({
            gpt4Error: true,
            isLoading: false,
            isLoadingSuggestedProducts: false,
            isLoadingUserSuggestions: false,
            error: "response is empty"
          });
        }
        console.log("set isLoading:false - 2");
        this.setState({ isLoading: false, isLoadingMessage2: false });
      }
    }
  };

  parseMessage = messageEvent => {
    if (messageEvent.channel == this.state.channelId) {
      //console.log('RESPONE FROM PUBNUB', messageEvent);
      let response = messageEvent.message[0]; // await this.getOpenAIData(text);

      let messageId = response.messageId;
      // console.log('messageId', messageId);

      let isSuggestion = response.isSuggestion;
      // console.log('isSuggestion', isSuggestion);

      let isError = false;
      if (response.data && response.data.error) {
        console.log("set isLoading:false - 3");
        this.setState({
          gpt4Error: true,
          isLoading: false,
          isLoadingSuggestedProducts: false,
          isLoadingUserSuggestions: false,
          error: `open ai error - ${response.data.error.message}`
        });
        isError = true;
      }

      if (!isError)
        if (isSuggestion) {
          // console.log('update suggestions');
          let text = response.data[0].message.content;
          // console.log('suggestions', text);

          try {
            let response = JSON.parse(text);
            this.setState({
              suggestedUserResponses: response.suggestedUserResponses,
              isLoadingUserSuggestions: false
            });

            let tmpSuggestionsMessage = {
              _id: uuidv4(),
              text: JSON.stringify(response.suggestedUserResponses),
              response: JSON.stringify(response.suggestedUserResponses),
              suggestedUserResponses: response.suggestedUserResponses,
              createdAt: new Date(),
              user: this.state.aiUser
            };
            // console.log('\n\n\n\n\n\n\nadd suggestion to chat history');
            this.addMessageToPubNubChannel(
              `${this.state.channelId}`,
              tmpSuggestionsMessage
            );
          } catch (e) {
            // if responded just with text and no json
            let suggestedUserResponses = [text];
            // this.setState({
            //   suggestedUserResponses: suggestedUserResponses,
            //   isLoadingUserSuggestions: false,
            // });
            // console.log(text);
            // console.log(e);
            // this.setState({
            //   gpt4Error: true,
            //   isLoading: false,
            //   isLoadingSuggestedProducts: false,
            //   isLoadingUserSuggestions: false,
            //   error:
            //     'Unable to parse the response - suggested user responses',
            // });
          }

          // this.updateAssistantResponseWithProducts(text, messageId);
          // this.setState({ isLoadingSuggestedProducts: false });
        } else if (messageId) {
          // update message
          // console.log('update message', messageId);
          let text = response.data[0].message.content;
          this.setState({
            isLoadingSuggestedProducts: false,
            isLoadingSuggestedProductsLoading2: false
          });
          this.updateAssistantResponseWithProducts(text, messageId);
        } else {
          // new message

          if (response) {
            let text = response.data[0].message.content;
            // console.log('pubnub text', text);
            this.pushAssistantResponse(text);

            config.analytics.trackProduction(`Chat Message Response`, {
              distinct_id: config.userId,
              chatPersonality: this.state.persona.name,
              chatMessage: text,
              chatChannelId: this.state.channelId
            });
          } else {
            // there was an error, retry
            // the only error that should be listed
            console.log("set isLoading:false - 4");
            this.setState({
              gpt4Error: true,
              isLoading: false,
              isLoadingSuggestedProducts: false,
              isLoadingUserSuggestions: false,
              error: "response is empty"
            });
          }
          console.log("set isLoading:false - 5");
          this.setState({ isLoading: false, isLoadingMessage2: false });
        }
    }
  };

  onSubmitResponse = text => {
    // console.log(data);
    let message = {
      _id: uuidv4(),
      createdAt: "2023-03-08T09:24:58.575Z",
      text: text, // this.state.currentText,
      isRendered: true,
      user: {
        _id: config.userId,
        avatar: config.userImage,
        name: config.userFullName
      }
    };

    // add message to the local chat and then send it to pubnub
    // console.log("...this.state.messages", this.state.messages);
    let currentMessages = [...this.state.messages];
    // message.suggestedProducts=[]
    currentMessages.push(message);

    // console.log("currentMessages", currentMessages);

    this.setState(
      {
        messages: currentMessages
        // isLoading: false
      },
      () => {
        this.saveMessagesToLocalStorage(this.state.messages);
      }
    );
    // end add message to local chat

    this.onSend([message], true);
    this.setState({ currentText: "", suggestedUserResponses: [] });
  };

  redirectToSubscribe = () => {
    config.analytics.trackProduction(`Subscribe To User - Text`, {
      distinct_id: config.userId
    });
    this.props.navigation.push("RootPlanUser", {
      persona: this.state.persona,
      activateSubscription: this.activateSubscription
    });
  };

  onSubmit = event => {
    event.preventDefault();
    // if (this.state.persona.id != "healthiny" && !this.state.hasAccess) {
    //   this.redirectToSubscribe();
    //   return null;
    // }
    if (this.state.currentText) {
      // console.log(data);
      let message = {
        _id: uuidv4(),
        createdAt: "2023-03-08T09:24:58.575Z",
        text: this.state.currentText,
        isRendered: true,
        user: {
          _id: config.userId,
          avatar: config.userImage,
          name: config.userFullName
        }
      };

      console.log("messages item from chat", message);

      // add message to the local chat and then send it to pubnub
      console.log("...this.state.messages", this.state.messages);
      let currentMessages = [...this.state.messages];
      // message.suggestedProducts=[]
      currentMessages.push(message);

      console.log("currentMessages", currentMessages);

      this.setState(
        {
          messages: currentMessages
          // isLoading: false
        },
        () => {
          this.saveMessagesToLocalStorage(this.state.messages);
        }
      );
      // end add message to local chat

      this.onSend([message]);
      this.setState({ currentText: "" });
    }
  };

  // onChangeText = text => {
  //   this.setState({ currentText: text });
  // };
  onChangeText(event) {
    // Update the state with the new text
    this.setState({ currentText: event.target.value });
  }

  setMessages = messages => {
    this.setState(messages);
  };

  // findQuery = async query => {
  //   let results = await this.getGoogleProductsByQuery(query);
  //   // console.log('results', results);
  //   allProducts.push({
  //     title: query,
  //     items: results
  //   });
  // };

  // getGoogleProducts = async queryList => {
  //   // let allProducts = [];
  //   // for (let query of queryList) {
  //   // }
  //   // console.log('allProducts', allProducts);
  //
  //   // const promises = await fruitsToGet.map(async fruit => {
  //   //   const numFruit = new Promise((resolve, reject) => {
  //   //     setTimeout(() => resolve(fruit), 1000);
  //   //   });
  //   //   return numFruit;
  //   // });
  //   // const numFruits = await Promise.all(promises);
  //
  //   if (!queryList) {
  //     queryList = this.state.itemsForGoogle;
  //   }
  //
  //   // console.log('get new products');
  //
  //   allProducts = [];
  //   const promises = await queryList.map(async query => this.findQuery(query));
  //   const numQueries = await Promise.all(promises);
  //
  //   // order results
  //   let allProductsOrdered = [];
  //   queryList.map(query =>
  //     allProducts.map(
  //       product =>
  //         product.title == query ? allProductsOrdered.push(product) : null
  //     )
  //   );
  //   allProducts = allProductsOrdered;
  //
  //   // console.log('allProducts', allProducts);
  //   // console.log('allProducts');
  //
  //   // allProducts = [];
  //
  //   // this.setState({
  //   //   items: allProducts,
  //   // });
  //
  //   // console.log(allProducts)
  //   let allProductsFiltered = [];
  //   for (let key in allProducts) {
  //     allProductsFiltered[key] = allProducts[key];
  //
  //     if (
  //       allProductsFiltered[key].items &&
  //       allProductsFiltered[key].items.length > 0
  //     ) {
  //       let data = allProductsFiltered[key].items;
  //       const uniqueItems = data.reduce((accumulator, current) => {
  //         if (!accumulator.find(item => item.link === current.link)) {
  //           accumulator.push(current);
  //         }
  //         return accumulator;
  //       }, []);
  //       allProductsFiltered[key].items = uniqueItems;
  //     }
  //   }
  //   allProducts = allProductsFiltered;
  //
  //   return allProducts;
  // };
  //
  // getGoogleProductsByQuery = async query => {
  //   // query = `"${query}" "women"`;
  //   query = `${query} "women"`;
  //   let cx = this.state.cx;
  //   // console.log('cx', cx);
  //   let url = `https://customsearch.googleapis.com/customsearch/v1?cx=${cx}&q=${encodeURIComponent(
  //     query
  //   )}&key=AIzaSyAHUFxsrIBP49IjYasiYMWMfK1uK_XDS8U&searchType=image`;
  //
  //   // console.log(url);
  //   const rawResponse = await fetch(url);
  //   const content = await rawResponse.json();
  //   // console.log('content', content.items);
  //
  //   return content.items;
  // };

  updateMessageInPubNubChannel = async (channel, messageId, newMessage) => {
    try {
      // Get the original message from PubNub history
      const result = await this.pubnub.history({
        channel: channel,
        count: 1,
        includeMeta: true,
        stringifiedTimeToken: true,
        start: messageId
      });

      const originalMessage = result.messages[0];

      // Create a new message with the same UUID and timestamp as the original message,
      // but with the updated message content
      const updatedMessage = {
        uuid: originalMessage.entry[0],
        timestamp: originalMessage.entry[1],
        message: newMessage
      };

      // Publish the updated message to the channel
      await this.pubnub.publish({
        channel: channel,
        message: updatedMessage
      });

      // console.log('Message updated successfully');
    } catch (error) {
      // console.log(error);
    }
  };

  parseSuggestions = suggestedProducts => {
    let newSuggestions = suggestedProducts;

    if (suggestedProducts && suggestedProducts.length > 0) {
      const categoriesMap = {};
      let productTmp = suggestedProducts[0];
      // if not from cache, not formatted

      let found = false;
      suggestedProducts.forEach(product => {
        if (!product.queries) {
          found = true;
          const id = product.id;
          const category = product.query.category;
          const categoryTranslated = product.query.categoryTranslated;
          if (!categoriesMap[category]) {
            categoriesMap[category] = {
              id: id,
              category: category,
              categoryTranslated: categoryTranslated,
              queries: []
            };
          }
          // Add queries
          categoriesMap[category].queries.push(product.query);
        }
      });

      if (found) {
        const bundledData = Object.values(categoriesMap);
        // console.log("bundledData", bundledData);
        newSuggestions = bundledData;
      }
    }

    return newSuggestions;
  };

  updateMessageSuggestedProducts = (id, suggestedProducts) => {
    // format like queries

    console.log("suggestedProducts initial", suggestedProducts);
    suggestedProducts = this.parseSuggestions(suggestedProducts);
    console.log("suggestedProducts parsed", suggestedProducts);

    const updatedMessages = this.state.messages.map(message => {
      if (message._id === id) {
        let updatedMessageItem = {
          ...message,
          suggestedProducts: suggestedProducts,
          activeSuggestedProduct:
            suggestedProducts && suggestedProducts.length > 0
              ? suggestedProducts[0].id
              : null
        };
        // this.addMessageToPubNubChannel(
        //   `${this.state.channelId}`,
        //   updatedMessageItem
        // );

        return {
          ...message,
          suggestedProducts: suggestedProducts,
          activeSuggestedProduct:
            suggestedProducts && suggestedProducts.length > 0
              ? suggestedProducts[0].id
              : null
        };
      } else {
        return message;
      }
    });
    this.setState({ messages: updatedMessages });

    console.log("get google result");
    console.log("suggestedProducts", suggestedProducts);

    // get google results
    if (suggestedProducts && suggestedProducts.length > 0) {
      this.getProducts(id, suggestedProducts[0]);
    }
  };

  // updateMessageProductsLoading = (
  //   id,
  //   activeSuggestedProduct,
  //   isProductsLoading
  // ) => {
  //   const updatedMessages = this.state.messages.map(message => {
  //     if (message._id === id) {
  //       return {
  //         ...message,
  //         activeSuggestedProduct: activeSuggestedProduct,
  //         isProductsLoading: isProductsLoading,
  //       };
  //     } else {
  //       return message;
  //     }
  //   });
  //   this.setState({ messages: updatedMessages });
  // };

  // updateMessageProductsLoading = (
  //   id,
  //   products,
  //   activeSuggestedProduct,
  //   isProductsLoading
  // ) => {
  //   const updatedMessages = this.state.messages.map(message => {
  //     if (message._id === id) {
  //       return {
  //         ...message,
  //         products: products,
  //         activeSuggestedProduct: activeSuggestedProduct,
  //         isProductsLoading: isProductsLoading,
  //       };
  //     } else {
  //       return message;
  //     }
  //   });
  //   this.setState({ messages: updatedMessages });
  // };

  updateMessage = (
    id,
    products,
    activeSuggestedProduct,
    isProductsLoading,
    animationEnded
  ) => {
    const updatedMessages = this.state.messages.map(message => {
      if (message._id === id) {
        return {
          ...message,
          products: products, // products ? products : message.products,
          activeSuggestedProduct: activeSuggestedProduct,
          isProductsLoading: isProductsLoading
          // animationEnded: message.animationEnded
          //   ? message.animationEnded
          //   : animationEnded,
        };
      } else {
        return message;
      }
    });
    this.setState({ messages: updatedMessages }, () => {
      this.saveMessagesToLocalStorage(this.state.messages);
    });
  };

  updateMessageIsRendered = (id, isRendered) => {
    const updatedMessages = this.state.messages.map(message => {
      if (message._id === id) {
        return {
          ...message,
          isRendered: isRendered
        };
      } else {
        return message;
      }
    });
    this.setState({ messages: updatedMessages, isRenderedDelay: true });
    setTimeout(() => {
      this.setState({ isRenderedDelay: false });
    }, 1000);
  };

  handleAnimationEnd = item => {
    // console.log('handleAnimationEnd called', item.text);
    if (!item.isRendered) {
      this.updateMessageIsRendered(item._id, true);
      this.setState({ isAnimatedEnded: true });
      this.parseMessagesInQueue();
    }
  };

  fetchGPT4 = async () => {
    let response = this.getOpenAIData();
    // if (response) {
    //   this.pushAssistantResponse(response);
    // } else {
    //   // there was an error, retry
    //   this.setState({ gpt4Error: true });
    // }
  };

  handleAppStateChange = nextAppState => {
    if (
      this.state.appState.match(/inactive|background/) &&
      nextAppState === "active"
    ) {
      // console.log('App has come to the foreground');
      this.subscribeToChannels();
    } else if (nextAppState.match(/inactive|background/)) {
      // console.log('App has gone to the background');
      this.unsubscribeFromChannels();
    }
    this.setState({ appState: nextAppState });
  };

  // subscribeToChannels = () => {
  //   // const [{ pubnub }, dispatch] = this.context;
  //
  //   this.pubnub.subscribe({
  //     channels: [this.state.channelId] // [this.state.channelId, config.updatesChannelId]
  //     // withPresence: true,
  //   });
  // };

  subscribeToChannels = () => {
    // const [{ pubnub }, dispatch] = this.context;

    this.pubnub.subscribe({
      channels: [this.state.channelId, `${this.state.channelId}`] // [this.state.channelId, config.updatesChannelId]
      // withPresence: true,
    });
  };

  unsubscribeFromChannels = () => {
    this.pubnub.unsubscribe({
      channels: [this.state.channelId, `${this.state.channelId}`]
    });
  };

  // reconnectToPubNub() {
  //   // const [{ pubnub }, dispatch] = this.context;
  //   this.pubnub.stop();
  //   this.pubnub.start();
  // }

  // addGPT4Listener = async () => {
  //   // const [{ pubnub }, dispatch] = this.context;
  //
  //   this.pubnub.addListener(this.myListener);
  //   this.subscribeToChannels();
  //   // this.pubnub.subscribe({
  //   //   channels: [this.state.channelId], // [this.state.channelId, config.updatesChannelId]
  //   // });
  //   AppState.addEventListener('change', this.handleAppStateChange);
  //
  //   // console.log('myListener added');
  // };

  componentWillUnmount() {
    // const [{ pubnub }, dispatch] = this.context;

    try {
      this.pubnub.removeListener(this.myListener);
      this.pubnub.unsubscribe({
        channels: [this.state.channelId]
      });
    } catch (e) {
      // console.log(e);
    }

    // console.log('unsubscribed from pubnub');

    this.appStateSubscription?.remove();
    // AppState.removeEventListener('change', this.handleAppStateChange);

    // this.this.pubnub.removeListener(this.myListener);
    // this.this.pubnub.unsubscribe({
    //   channels: ['my_channel'],
    // });
  }

  initDefaultChat = (channelId, aiUser) => {
    let messageId1 = uuidv4();
    // let text1 = `For a girls' brunch, I recommend pairing a flowy midi skirt in a bright winter color, such as royal blue or emerald green, with a feminine blouse. Adding a slim waist belt will accentuate your waistline. Complete your outfit with statement earrings and heeled ankle boots.`;

    let lastMessage = this.state.userPersona.message; // "How can I help you today?"; // this.props.route.params.lastMessage;

    let messageId = uuidv4();
    let previousMessages = [
      {
        _id: messageId,
        text: lastMessage,
        response: lastMessage,
        createdAt: new Date(),
        user: aiUser
        //  {
        //   _id: 'mod',
        //   name: 'MOD',
        //   avatar:
        //     'https://traveline-images.s3.amazonaws.com/fashionapp/general/logo_chat.png',
        // },
      }
    ];

    this.addMessageToPubNubChannel(`${channelId}`, previousMessages[0]);
  };

  // initChat = () => {
  //   let messageId1 = uuidv4();
  //   // let text1 = `For a girls' brunch, I recommend pairing a flowy midi skirt in a bright winter color, such as royal blue or emerald green, with a feminine blouse. Adding a slim waist belt will accentuate your waistline. Complete your outfit with statement earrings and heeled ankle boots.`;
  //   console.log("init chat");
  //   let lastMessage = this.state.userPersona.chat.message; // "How can I help you today?"; // this.props.route.params.lastMessage;
  //
  //   console.log("lastMessage");
  //   let messageId = uuidv4();
  //   let previousMessages = [
  //     {
  //       _id: messageId,
  //       text: lastMessage ? lastMessage : this.state.persona.question,
  //       response: lastMessage ? lastMessage : this.state.persona.question,
  //       createdAt: new Date(),
  //       user: this.state.aiUser
  //       //  {
  //       //   _id: 'mod',
  //       //   name: 'MOD',
  //       //   avatar:
  //       //     'https://traveline-images.s3.amazonaws.com/fashionapp/general/logo_chat.png',
  //       // },
  //     }
  //   ];
  //
  //   let suggestedUserResponses = this.state.userPersona.suggestions;
  //
  //   // let suggestedUserResponses = this.state.persona.suggestedUserResponses;
  //
  //   if (lastMessage) {
  //     // this.setState({
  //     //   messages: previousMessages
  //     //   // suggestedUserResponses: suggestedUserResponses,
  //     // });
  //
  //     this.addMessages(previousMessages);
  //   } else {
  //     this.setState(
  //       {
  //         messages: previousMessages,
  //         suggestedUserResponses: suggestedUserResponses
  //       },
  //       () => {
  //         this.saveMessagesToLocalStorage(this.state.messages);
  //       }
  //     );
  //   }
  //
  //   // setTimeout(() => {
  //   //   this.setState({
  //   //     // messages: previousMessages,
  //   //     suggestedUserResponses: suggestedUserResponses,
  //   //   });
  //   // }, 3000);
  // };

  initChat = () => {
    let messageId1 = uuidv4();
    // let text1 = `For a girls' brunch, I recommend pairing a flowy midi skirt in a bright winter color, such as royal blue or emerald green, with a feminine blouse. Adding a slim waist belt will accentuate your waistline. Complete your outfit with statement earrings and heeled ankle boots.`;
    console.log("init chat");
    let defaultMessage = this.state.userPersona.chat.message; // "How can I help you today?"; // this.props.route.params.lastMessage;

    console.log("defaultMessage");
    let messageId = uuidv4();
    let previousMessages = [
      {
        _id: messageId,
        text: defaultMessage ? defaultMessage : this.state.persona.question,
        response: defaultMessage ? defaultMessage : this.state.persona.question,
        createdAt: new Date(),
        user: this.state.aiUser
        //  {
        //   _id: 'mod',
        //   name: 'MOD',
        //   avatar:
        //     'https://traveline-images.s3.amazonaws.com/fashionapp/general/logo_chat.png',
        // },
      }
    ];

    this.setState({ messages: previousMessages });

    // let suggestedUserResponses = this.state.userPersona.suggestions;

    // let suggestedUserResponses = this.state.persona.suggestedUserResponses;

    // if (defaultMessage) {
    //   // this.setState({
    //   //   messages: previousMessages
    //   //   // suggestedUserResponses: suggestedUserResponses,
    //   // });
    //
    //
    // } else {
    //   this.setState(
    //     {
    //       messages: previousMessages,
    //       suggestedUserResponses: suggestedUserResponses
    //     },
    //     () => {
    //       this.saveMessagesToLocalStorage(this.state.messages);
    //     }
    //   );
    // }

    // setTimeout(() => {
    //   this.setState({
    //     // messages: previousMessages,
    //     suggestedUserResponses: suggestedUserResponses,
    //   });
    // }, 3000);
  };

  initChatAdmin = () => {
    this.fetchAdminHistory();
    // get messages from pubnub
  };

  getIsRendering = () => {
    return this.state.messages.find(
      item => item.user._id == this.state.aiUser._id && !item.isRendered
    );
  };

  footerComponent = () => {
    let isRendering = this.getIsRendering();
    //console.log(this.state.suggestedUserResponses);
    // let renderSuggestions =
    //   !this.state.isLoading &&
    //   !this.state.isLoadingSuggestedProducts &&
    //   this.state.suggestedUserResponses &&
    //   this.state.suggestedUserResponses.length > 0;
    // console.log('render header component');
    //console.log("renderSuggestions", renderSuggestions);
    //console.log('suggestedUserResponses', this.state.suggestedUserResponses);

    let renderAppSuggestions =
      this.state.messages && this.state.messages.length == 1;

    // let renderAppSuggestions =
    //   !isRendering &&
    //   !!renderSuggestions &&
    //   this.state.messages &&
    //   !!this.state.messages.length == 1;

    // console.log(
    //   !isRendering,
    //   !!renderSuggestions,
    //   // this.state.messages,
    //   this.state.messages.length
    // );

    let loadingProductsItem = {
      _id: uuidv4(),
      text: "Looking for something 2...",
      response: "How can I help you? 2",
      createdAt: new Date(),
      user: this.state.aiUser
      // user: {
      //   _id: 'mod',
      //   name: 'MOD',
      //   avatar:
      //     'https://traveline-images.s3.amazonaws.com/fashionapp/general/logo_chat.png',
      // },
    };

    let suggestedUserResponses = this.state.userPersona.chat.suggestions;

    // if (this.state.isLoading) {
    //   setTimeout(() => {
    //     this.scrollToBottom();
    //   }, 500);
    // }

    return (
      <View pb={12} style={{ flex: 1 }}>
        {/*
        <SkeletonContent
          containerStyle={{
            flex: 1,
            width: width,
            flexDirection: 'row',
            marginLeft: 24,
          }}
          isLoading={true}
          layout={[
            { key: 'someOtherId', width: 80, height: 80, margin: 5 },
            { key: 'someOtherId', width: 80, height: 80, margin: 5 },
            { key: 'someOtherId', width: 80, height: 80, margin: 5 },
            { key: 'someOtherId', width: 80, height: 80, margin: 5 },
            { key: 'someOtherId', width: 80, height: 80, margin: 5 },
            { key: 'someOtherId', width: 80, height: 80, margin: 5 },
            { key: 'someOtherId', width: 80, height: 80, margin: 5 },
          ]}
        >
          <View>
            <Text style={styles.normalText}>Your content</Text>
            <Text style={styles.normalText}>Your content</Text>
            <Text style={styles.normalText}>Your content</Text>
            <Text style={styles.normalText}>Your content</Text>
            <Text style={styles.normalText}>Your content</Text>
            <Text style={styles.normalText}>Your content</Text>
          </View>
        </SkeletonContent>
        */}

        {this.state.isLoading && (
          <View mt={8} mb={12}>
            <View
              mx={24}
              style={{
                display: "flex",
                flex: 1,
                flexDirection:
                  loadingProductsItem.user._id == this.state.aiUser._id
                    ? "row"
                    : "row-reverse",
                alignItems: "flex-start"
                // backgroundColor: 'yellow'
              }}
            >
              <Image
                source={{
                  uri:
                    "https://traveline-images.s3.amazonaws.com/shopify/mod/logo_ai_1024x1024.png"
                }}
                style={{
                  width: 24,
                  height: 24,
                  borderRadius: 50,
                  // borderColor: colors.bdLine,
                  // borderWidth: 1,
                  backgroundColor: this.props.userPersona.chat.primaryColor
                }}
                resizeMode="contain"
              />

              <View
                mr={
                  loadingProductsItem.user._id == this.state.aiUser._id ? 8 : 8
                }
                ml={
                  loadingProductsItem.user._id == this.state.aiUser._id ? 8 : 0
                }
                style={{
                  flex: 1,
                  minHeight: 20,
                  // paddingVertical: 12,
                  // paddingHorizontal: 16,
                  // backgroundColor:
                  //   loadingProductsItem.user._id ==
                  //   this.state.aiUser._id
                  //     ? colors.bgVioletLighter
                  //     : colors.inkLightest,
                  borderRadius: 8
                  // flexGrow: 1,
                  // backgroundColor: 'blue',
                }}
              >
                <View style={{ width: 58 }}>
                  <Lottie animationData={loadingAnimation} loop={true} />
                </View>
              </View>
            </View>
          </View>
        )}

        {renderAppSuggestions && (
          <View>
            <SlideInAnimation
              key={"slide_in_animation"}
              suggestedUserResponses={suggestedUserResponses}
              onSubmitResponse={this.onSubmitResponse}
              hasAccess={true}
              redirectToSubscribe={this.redirectToSubscribe}
            />
          </View>
        )}

        {this.state.gpt4Error && (
          <View>
            <TouchableOpacity
              onPress={() => {
                // retry
                // this.onSubmit();
                this.setState({ gpt4Error: false });
                this.fetchGPT4();
              }}
            >
              <Text
                tiny_none_regular
                py={10}
                px={10}
                style={{ textAlign: "center", color: colors.inkDark }}
              >
                There was an error. Retry
              </Text>
            </TouchableOpacity>
            {false && (
              <Text
                py={10}
                px={10}
                style={{ textAlign: "center", color: colors.link }}
              >
                {this.state.error}
              </Text>
            )}
          </View>
        )}
      </View>
    );
  };

  headerComponent = () => (
    <View
      mt={72}
      mb={32}
      style={{
        justifyContent: "center",
        alignItems: "center"
        // backgroundColor: 'blue'
      }}
    >
      {this.state.userPersona && (
        <React.Fragment>
          <View
            mt={0}
            style={{
              display: "flex",
              flex: 1,
              flexGrow: 1,
              // backgroundColor: colors.bgVioletBase,
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center"
            }}
          >
            <Image
              source={{
                uri:
                  "https://traveline-images.s3.amazonaws.com/shopify/mod/logo_ai_1024x1024.png" // this.state.userPersona.chat.imagePreviewUrl
              }}
              style={{
                width: 80,
                height: 80,
                borderRadius: 40,
                backgroundColor: this.props.userPersona.chat.primaryColor, // colors.bgVioletBase
                // borderColor: colors.bdLine,
                // borderWidth: 1,
                zIndex: 1000
              }}
              resizeMode="contain"
            />
            {false &&
              !!this.props.isTest && (
                <Image
                  source={{
                    uri: this.state.userPersona.logo
                  }}
                  style={{
                    width: 80,
                    height: 80,
                    borderRadius: 80,
                    // backgroundColor: this.state.userPersona.chat.primaryColor // colors.bgVioletBase
                    borderColor: colors.bdLine,
                    borderWidth: 1,
                    position: "relative",
                    left: -15,
                    backgroundColor: "#fff",
                    zIndex: 100
                  }}
                  resizeMode="contain"
                />
              )}
          </View>

          <Text
            mt={16}
            regular_none_bold
            mx={24}
            style={{ textAlign: "center" }}
          >
            {this.state.userPersona.chat.name}
          </Text>

          {/*
          <Text
            mt={8}
            small_tight_regular
            mx={24}
            style={{ textAlign: "center" }}
          >
            AI Nutritionist
          </Text>
          */}

          <Text
            mt={8}
            small_tight_regular
            numberOfLines={4}
            ellipsizeMode="tail"
            mx={32}
            style={{ color: colors.inkLight, textAlign: "center" }}
          >
            {this.state.userPersona.chat.description}
          </Text>
        </React.Fragment>
      )}

      {/*
      <Text
        mt={16}
        regular_tight_regular
        mx={24}
        style={{ textAlign: "center" }}
      >
        {this.state.persona.description}
      </Text>
      */}
    </View>
  );

  addMessageToPubNubChannel = async (channel, message) => {
    try {
      await this.pubnub.publish({
        channel: channel,
        message: message
      });

      // console.log('Message sent successfully');
    } catch (error) {
      // console.log(error);
    }
  };

  // addMessages = (messages, suggestedUserResponses = null) => {
  //   // if (suggestedUserResponses) {
  //   //   this.setState({
  //   //     messages: GiftedChat.append(this.state.messages, messages),
  //   //     suggestedUserResponses: suggestedUserResponses
  //   //   });
  //   // } else {
  //   //   this.setState({
  //   //     messages: GiftedChat.append(this.state.messages, messages)
  //   //   });
  //   // }
  //
  //   if (suggestedUserResponses) {
  //     this.setState(prevState => ({
  //       messages: [...prevState.messages, ...messages],
  //       suggestedUserResponses: suggestedUserResponses
  //     }));
  //   } else {
  //     this.setState(prevState => ({
  //       messages: [...prevState.messages, ...messages]
  //     }));
  //   }
  //
  //   // console.log(this.state.channelId);
  //
  //   // add to pubnub
  //   let tmpMessage = messages[0];
  //   if (suggestedUserResponses) {
  //     tmpMessage.suggestedUserResponses = suggestedUserResponses;
  //   }
  //   this.addMessageToPubNubChannel(`${this.state.channelId}`, tmpMessage);
  // };

  addMessages = (messages, suggestedUserResponses = null) => {
    // if (suggestedUserResponses) {
    //   this.setState({
    //     messages: GiftedChat.append(this.state.messages, messages),
    //     suggestedUserResponses: suggestedUserResponses
    //   });
    // } else {
    //   this.setState({
    //     messages: GiftedChat.append(this.state.messages, messages)
    //   });
    // }
    // console.log(this.state.channelId);

    // this.setState({
    //   messages: GiftedChat.append(this.state.messages, messages)
    // });

    console.log("pubnub add message", messages);

    // add to pubnub
    let tmpMessage = messages[0];
    if (suggestedUserResponses) {
      tmpMessage.suggestedUserResponses = suggestedUserResponses;
    }
    this.addMessageToPubNubChannel(`${this.state.channelId}`, tmpMessage);
  };

  checkPurchaserInfo = async () => {};

  activateSubscription = async () => {
    //console.log('activate subscription');
    this.setState({
      hasAccess: true
    });
  };

  // componentDidUpdate(prevProps, prevState) {
  //   console.log(prevState.messages.length, this.state.messages.length);
  //   if (prevState.messages.length < this.state.messages.length) {
  //     setTimeout(() => {
  //       if (this.flatListRef.current) {
  //         this.flatListRef.current.scrollToEnd({ animated: true });
  //       }
  //     }, 100); // Delay of 100ms
  //   }
  // }

  // getCleanDomain = () => {
  //   let domain = window.location.hostname;
  //
  //   // Check if the domain contains 'localhost'
  //   if (domain.includes("localhost") || domain.includes("192.168.0.149")) {
  //     return "mod-ai-stylist.myshopify.com";
  //   }
  //
  //   if (domain.startsWith("www.")) {
  //     domain = domain.substring(4);
  //   }
  //
  //   return domain;
  // };

  getCleanDomain = () => {
    let domain = window.location.hostname;

    // Check if the domain contains 'localhost'
    if (
      domain.includes("localhost") ||
      domain.includes("192.168.0.149") ||
      domain.includes(config.testSite)
    ) {
      let url = window.location.href;
      // Create a URL object
      const urlObj = new URL(url);
      // Use URLSearchParams to get the query parameter named 'shop'
      const shopParam = urlObj.searchParams.get("shop");
      const isActive = urlObj.searchParams.get("isActive");
      if (shopParam) {
        let shop = shopParam.endsWith("/") ? shopParam.slice(0, -1) : shopParam;

        const getCleanDomainFromURL = input => {
          let urlString = input;
          // Check if the input already includes a protocol; if not, prepend 'https://'
          if (!input.match(/^[a-zA-Z]+:\/\//)) {
            urlString = `https://${input}`;
          }

          try {
            const url = new URL(urlString);
            // Use a regular expression to remove any 'www.' prefix from the hostname
            const domain = url.hostname.replace(/^www\./, "");
            return domain; // Returns the domain name without www.
          } catch (error) {
            console.error("Invalid URL");
            return null;
          }
        };

        let websiteDomain = getCleanDomainFromURL(shop);
        domain = `${websiteDomain}`;
        // if (isActive) {
        //   domain = `${websiteDomain}`;
        // } else {
        //   domain = `tmp_${websiteDomain}`;
        // }
        // this.setState({
        //   isChatWindowVisible: true
        // });
      } else {
        domain = "mod-ai-stylist.myshopify.com";
        // return "mod-ai-stylist.myshopify.com";
      }

      console.log("domain", domain);

      this.setState({ isTest: true });
      return domain;
    }

    // if (domain.startsWith("www.")) {
    //   domain = domain.substring(4);
    // }
    domain = window.shopifyShopDomain;

    let currency = window.userCurrency;
    console.log("currency", currency);

    let shopData = window.shopifyShop;
    console.log("shopData", shopData);

    console.log("domain", domain);

    return domain;
  };

  getShopParam = () => {
    // Use window.location.search to get the query string part of the URL
    const params = new URLSearchParams(window.location.search);
    // Get the 'shop' parameter
    const shop = params.get("shop");
    return shop;
  };

  getDomainFromUrl = urlString => {
    const regex = /^(?:https?:\/\/)?(?:www\.)?([^\/]+)/;
    const match = urlString.match(regex);
    return match ? match[1] : null;
  };

  getUser = async () => {
    let domain = this.getCleanDomain();
    console.log("domain", domain);
    let shop = domain;
    let url = `${config.appServerUrl}?action=getUser&shop=${shop}`;

    let responseJson = await fetch(url);
    let response = await responseJson.json();
    let user = response.data;
    this.setState({ userPersona: user });
    console.log("user from db", user);
  };

  addMessageToUser = async () => {
    let domain = this.getCleanDomain();
    console.log("domain", domain);
    let shop = domain;
    let url = `${config.appServerUrl}?action=addMessage&shop=${shop}`;

    console.log("add message to user");
    let responseJson = await fetch(url);
    let response = await responseJson.json();
    let user = response.data;
    // this.setState({ userPersona: user });
    console.log("add message to user - user from db", user);
  };

  saveMessagesToLocalStorage(messages) {
    const data = {
      messages,
      timestamp: new Date().getTime() // Store the current time in milliseconds
    };

    localStorage.setItem(this.state.chatLocalStorage, JSON.stringify(data));
  }

  getMessagesFromLocalStorage() {
    const dataString = localStorage.getItem(this.state.chatLocalStorage);
    if (!dataString) return null; // If no data is found

    const data = JSON.parse(dataString);
    const expireTime = 12 * 60 * 60 * 1000; // 12 * 60 * 60 * 1000;  // 12 hours // one week
    const now = new Date().getTime();

    // Check if the data is older than one day
    if (now - data.timestamp > expireTime) {
      localStorage.removeItem(this.state.chatLocalStorage); // Clear expired data
      return null;
    }

    return data.messages;
  }

  getContext = async () => {
    const lambdaUrl = `https://enofvc3o7f.execute-api.us-east-1.amazonaws.com/production/mod-context`;
    // Make a GET request to the Lambda function
    let response = await fetch(lambdaUrl);
    let data = await response.json();
    console.log("data context", data);
    console.log("this.state.userPersona", this.state.userPersona);
    let context = data.webChatContext;
    if (
      this.state.userPersona.context &&
      this.state.userPersona.context.categories
    ) {
      if (
        this.state.userPersona.contextManual &&
        this.state.userPersona.contextManual.categories
      ) {
        context = context.replace(
          /__CONTEXT_CATEGORIES_LIST__/g,
          this.state.userPersona.contextManual.categories
        );
      } else {
        context = context.replace(
          /__CONTEXT_CATEGORIES_LIST__/g,
          this.state.userPersona.context.categories
        );
      }
      // context = context.replace(
      //   /__CONTEXT_CATEGORIES_LIST__/g,
      //   this.state.userPersona.context.categories
      // );
      context = context.replace(
        "__CONTEXT_CATEGORIES_DESCRIPTION__",
        this.state.userPersona.context.summary
      );
    }

    if (
      this.state.userPersona.chat &&
      this.state.userPersona.chat.customContext
    ) {
      context = context.replace(
        "__CONTEXT_CUSTOM_INSTRUCTIONS__",
        this.state.userPersona.chat.customContext
      );
    }

    this.setState({
      systemMessage: {
        role: "system",
        content: context
      }
    });
  };

  componentDidMount = async () => {
    // console.log('user id', config.userId);
    // const [{ pubnub }] = this.context;

    this.getContext();

    // this.getUser();
    let user = {};
    let pubnub = new Pubnub({
      publishKey: config.pubnubPublishKey,
      subscribeKey: config.pubnubSubscribeKey,
      uuid: user && user.id ? user.id : uuidv4() // 'adi-1234',
      // restore: true,
      // ssl: true,
      // autoNetworkDetection: true,
    });

    this.pubnub = pubnub;

    // this.checkPurchaserInfo();

    this.pubnub.addListener(this.myListener);
    this.subscribeToChannels();

    // detect app in backgroundColor
    // https://www.pubnub.com/docs/general/setup/connection-management#mobile-app-connection-management
    // this.appStateSubscription = AppState.addEventListener(
    //   "change",
    //   this.handleAppStateChange
    // );

    // this.addGPT4Listener();

    // let url = window.location.href;
    // // Create a URL object
    // const urlObj = new URL(url);
    // Use URLSearchParams to get the query parameter named 'shop'
    // const isTestEnabled = urlObj.searchParams.get("test_enabled");
    // const test_session = urlObj.searchParams.get("test_session");

    // if (isTestEnabled) {
    //   const storedMessages = this.getMessagesFromLocalStorage();
    //   console.log("storedMessages", storedMessages);
    //   console.log("channelId...", this.state.channelId);
    //   if (storedMessages) {
    //     this.setState({ messages: storedMessages });
    //   } else {
    //     this.initChat();
    //   }
    //   // this.initChat();
    // } else {
    //   const storedMessages = this.getMessagesFromLocalStorage();
    //   console.log("storedMessages", storedMessages);
    //   console.log("channelId...", this.state.channelId);
    //   if (storedMessages) {
    //     this.setState({ messages: storedMessages });
    //   } else {
    //     this.initChat();
    //   }
    // }

    if (this.state.adminChannelId) {
      this.initChatAdmin();
    } else {
      // const storedMessages = this.getMessagesFromLocalStorage();
      // console.log("storedMessages", storedMessages);
      // console.log("channelId...", this.state.channelId);
      // if (storedMessages) {
      //   this.setState({ messages: storedMessages });
      // } else {
      //   this.initChat();
      // }
    }

    // console.log("isNewChannelId", this.state.isNewChannelId);
    //
    // let messages2 = await this.fetchHistory();
    // console.log("messages2", messages2);
    // if (messages2 && messages2.length > 0) {
    //   this.setState({
    //     messages: messages2
    //   });
    // } else {
    //   this.initChat();
    //
    //   // setTimeout(async () => {
    //   //   let messages3 = await this.fetchHistory();
    //   //   console.log("messages3", messages3);
    //   //   if (messages3 && messages3.length == 0) {
    //   //     this.initChat();
    //   //   }
    //   // }, 1000);
    // }

    config.analytics.trackProduction(`New Chat`, {
      distinct_id: config.userId,
      chatChannelId: this.state.channelId,
      chatPersonality: this.state.persona.name,
      chatUserFullName: config.userFullName
    });
  };

  updateAssistantResponseWithProducts = async (text, messageId) => {
    let info = null;
    let textCleaned;
    let suggestedUserResponses = null;
    let suggestedProducts = null;
    let activeSuggestedProduct = null;
    // clear text
    let responseJSON;

    // console.log('text in message response', text);
    try {
      responseJSON = JSON.parse(text);
    } catch (e) {
      // console.log('error on products');
      // console.log(text);
      // console.log(e);
      // this.setState({
      //   gpt4Error: true,
      //   isLoading: false,
      //   isLoadingSuggestedProducts: false,
      //   isLoadingUserSuggestions: false,
      //   error: 'Unable to parse the response - products',
      // });
      return null;
    }

    // console.log('responseJSON', responseJSON);
    if (responseJSON) {
      if (responseJSON.sp && responseJSON.sp.length > 0) {
        suggestedProducts = responseJSON.sp;
        let newSuggestedProducts = [];
        for (let item of suggestedProducts) {
          newSuggestedProducts.push({
            ...item,
            id: uuidv4()
          });
        }
        suggestedProducts = newSuggestedProducts;
        activeSuggestedProduct = suggestedProducts[0].id;

        let messageId = uuidv4();
        // const rand = [1, 2, 3][Math.floor(Math.random() * 3)] ;
        // let aiMessage = this.state.persona[`chat_question_${rand}`];

        // let userLoaders = [];
        // for (let loaderItem of loaders) {
        //   if (loaderItem[this.state.persona.id]) {
        //     userLoaders.push(loaderItem);
        //   }
        // }
        // const loader =
        //   userLoaders[Math.floor(Math.random() * userLoaders.length)];
        //console.log(loader, this.state.persona.id);

        // let aiMessage = loader[this.state.persona.id];
        let aiMessage = "Here is a list of products:";
        //console.log('aiMessage', aiMessage);
        this.addAIMessage(aiMessage, messageId, suggestedProducts);
        setTimeout(() => {
          this.updateMessageSuggestedProducts(messageId, suggestedProducts);
        }, 200);
      } else {
        // no products found
        // console.log('error - no products found - it must be products');
        this.setState({ isLoadingSuggestedProducts: false });
      }
    }

    // console.log('textCleaned', textCleaned);
  };

  parseJsonResponse = response => {
    // Find the start and end indices of the JSON content
    const startIndex = response.indexOf("{");
    const endIndex = response.lastIndexOf("}") + 1;

    // Extract the JSON substring
    const jsonString = response.substring(startIndex, endIndex);

    // Parse the JSON substring
    try {
      const jsonResponse = JSON.parse(jsonString);
      return jsonResponse;
    } catch (error) {
      console.error("Failed to parse JSON:", error);
      return null;
    }
  };

  pushAssistantResponse = async (text, completeMessageId, countProducts) => {
    let info = null;
    let textCleaned;
    let suggestedUserResponses = null;
    let suggestedProducts = null;
    let activeSuggestedProduct = null;
    let haveSuggestedProducts = false;

    console.log("text", text);

    let responseJson = {};
    try {
      responseJson = JSON.parse(text);
      // suggestedUserResponses = responseJson.suggestions;
      textCleaned = responseJson.text; // responseJson.shortResponse; // text;
      suggestedProducts = responseJson.products;
      console.log("completeMessageId suggestedProducts", suggestedProducts);
      if (suggestedProducts && !Array.isArray(suggestedProducts)) {
        console.log("completeMessageId - not array");
        suggestedProducts = [];
      }
      if (suggestedProducts && suggestedProducts.length > 0) {
        let firstItem = suggestedProducts[0];
        console.log("completeMessageId - firstItem", firstItem);
        if (!firstItem.id || !firstItem.items || !firstItem.category) {
          console.log("completeMessageId - incomplete array");
          // incomplete products
          suggestedProducts = [];
        }
        // products=suggestedProducts;
      }

      // console.log("suggestedProducts push 1", suggestedProducts);
      // suggestedProducts = this.parseSuggestions(suggestedProducts);
      // console.log("suggestedProducts push 2", suggestedProducts);
      console.log("textCleaned1", textCleaned);
      //console.log(suggestedProducts);
    } catch (e) {
      console.log("textCleaned error", textCleaned);
      textCleaned = text;
    }

    let messageId = completeMessageId ? completeMessageId : uuidv4();
    console.log("textCleaned2", textCleaned);
    let messages = [
      {
        _id: messageId,
        text: textCleaned, //text,
        response: text,
        createdAt: new Date(),
        suggestedProducts,
        suggestedUserResponses,
        // activeSuggestedProduct,
        products: {},
        //  {
        //   category_1: {},
        //   category_2: {},
        // },
        user: this.state.aiUser
        // {
        //   _id: 'mod',
        //   name: 'MOD',
        //   avatar:
        //     'https://traveline-images.s3.amazonaws.com/fashionapp/general/logo_chat.png',
        // },
      }
    ];

    return messages[0];
    // console.log('new message', messages[0]);

    // this.onSend(previousMessages);
    // this.setState({
    //   messages: GiftedChat.append(this.state.messages, messages),
    //   suggestedUserResponses: suggestedUserResponses,
    // });

    // find message and replace it
    console.log("completeMessageId", completeMessageId);
    console.log("current messages", this.state.messages);
    let currentMessages = this.state.messages;
    let messageExists = currentMessages.find(item => item._id == messageId);
    if (messageExists) {
      console.log("completeMessageId updateing message");
      const updateMessage = (messageId, newMessage) => {
        this.setState(
          prevState => {
            const updatedMessages = prevState.messages.map(
              message => (message._id === messageId ? newMessage : message)
            );

            return { messages: updatedMessages, isLoading: false };
          },
          () => {
            this.saveMessagesToLocalStorage(this.state.messages);
          }
        );
      };
      updateMessage(messageId, messages[0]);
    } else {
      let newMessage = messages[0];
      // if (newMessage.products && !Array.isArray(newMessage.products)) {
      //   delete  newMessage.products;
      //   delete newMessage.suggestedProducts  ;
      // }

      console.log("completeMessageId new message", newMessage);

      console.log("...this.state.messages", this.state.messages);
      let currentMessages = [...this.state.messages];

      // message.suggestedProducts=[]
      currentMessages.push(newMessage);

      console.log("currentMessages", currentMessages);
      console.log("completeMessageId countProducts", countProducts);
      let isLoading = false;
      console.log("set isLoading countProducts", countProducts);
      if (!!parseInt(countProducts)) {
        console.log("set isLoading  ------ innnnn!!!");
        isLoading = true;
      }

      this.setState(
        {
          messages: currentMessages
          // isLoading: isLoading,
          // gpt4Error: false
        },
        () => {
          this.saveMessagesToLocalStorage(this.state.messages);
        }
      );

      // commented for isLoading: true
      if (isLoading) {
        setTimeout(() => {
          this.setState({ isLoading: isLoading, gpt4Error: false });
        }, 100);
      }

      // this.addMessages(messages, suggestedUserResponses);
    }
    console.log("completeMessageId suggestedProducts", suggestedProducts);
    if (suggestedProducts) {
      setTimeout(() => {
        this.updateMessageSuggestedProducts(messageId, suggestedProducts);
      }, 200);
    }

    // if (haveSuggestedProducts) {
    //   this.getOpenAIDataForMessage(textCleaned, messageId);
    // }

    // if (true) {
    //   this.getOpenAIDataForMessage(textCleaned, messageId);
    // }

    // suggestions not active
    // this.getOpenAIDataForSuggestedUserResponses(null, messageId);

    // setTimeout(() => {
    //   this.getOpenAIDataForSuggestedUserResponses(null, messageId);
    // }, 200);

    // this.updateMessageSuggestedProducts(messageId, suggestedProducts);

    // call products api if exists
    // if (suggestedProducts) {
    //   this.getProducts(messageId, suggestedProducts[0]);
    // }
  };

  // {
  //    "color": "coral",
  //    "googleSearch": "coral A-line midi dress chiffon",
  //    "id": "dres1",
  //    "material": "chiffon",
  //    "model": "A-line",
  //    "type": "dress",
  //  },

  // {
  //    "displayLink": "www.revolve.com",
  //    "fileFormat": "image/jpeg",
  //    "htmlSnippet": "Eight Sixty <b>Fit</b> &amp; <b>Flare Dress</b> in <b>Coral</b> | REVOLVE",
  //    "htmlTitle": "Eight Sixty <b>Fit</b> &amp; <b>Flare Dress</b> in <b>Coral</b> | REVOLVE",
  //    "image": {
  //      "byteSize": 111205,
  //      "contextLink": "https://www.revolve.com/eight-sixty-fit-flare-dress-in-coral/dp/EIGH-WD345/",
  //      "height": 1450,
  //      "thumbnailHeight": 150,
  //      "thumbnailLink": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUjXduMYdumtj8H-YSAONsyJTcRT75ZQcPS7cmvLkWPfkTJQ5yW9Ja3Q&s",
  //      "thumbnailWidth": 99,
  //      "width": 960,
  //    },
  //    "kind": "customsearch#result",
  //    "link": "https://is4.revolveassets.com/images/p4/n/z/EIGH-WD345_V1.jpg",
  //    "mime": "image/jpeg",
  //    "snippet": "Eight Sixty Fit & Flare Dress in Coral | REVOLVE",
  //    "title": "Eight Sixty Fit & Flare Dress in Coral | REVOLVE",
  //  },

  formatProduct = oldObject => {
    const formattedProduct = {};

    formattedProduct.displayLink = new URL(oldObject.metadata.url).hostname;
    formattedProduct.fileFormat = "image/jpeg"; // This value seems to be static in your example
    formattedProduct.htmlSnippet = oldObject.metadata.name;
    formattedProduct.htmlTitle = oldObject.metadata.name;

    // In the example, it seems that the details in the image object are not present in the source object.
    // Here, we initialize them with some default values.
    formattedProduct.image = {
      byteSize: 0, // You may need to provide a real value
      contextLink: oldObject.metadata.url,
      height: 0, // You may need to provide a real value
      thumbnailHeight: 150, // You may need to provide a real value
      thumbnailLink: oldObject.metadata.image, // Assumption based on available data
      thumbnailWidth: 118, // You may need to provide a real value
      width: 0 // You may need to provide a real value
    };

    formattedProduct.kind = "customsearch#result"; // This value seems to be static in your example
    formattedProduct.link = oldObject.metadata.image;
    formattedProduct.mime = "image/jpeg"; // This value seems to be static in your example
    formattedProduct.snippet = oldObject.metadata.name;
    formattedProduct.title = oldObject.metadata.name;

    return formattedProduct;
  };

  // Define an async function to make the POST request
  async queryProducts(appId, shop, queryParams) {
    // Define the URL
    let urlQueryProducts = `https://enofvc3o7f.execute-api.us-east-1.amazonaws.com/production/query`;
    // let urlQueryProducts = `https://bkapiez3z2uwjni3nblbwtt65y0rkpkz.lambda-url.us-east-1.on.aws/`;

    // Define the parameters to be sent in the request
    const params = {
      appId: appId, // "mod",
      storeId: shop.replace("https://", ""), // domain, // "tmp_https://edikted.com",
      // demographic: "woman",
      // category: "dress",
      // product: "Maxi Dress",
      // characteristics: "Stapless fit",
      // color: "white",
      // size: "L",
      ...queryParams,
      similarityThresholds: {
        product: 0.1,
        characteristics: 0.01,
        color: 0.58, // 0.58,  0.75
        size: 0.5
      },
      weights: {
        product: 0.9,
        characteristics: 0.9,
        color: 100,
        size: 10
      }
    };
    console.log("params", params);

    try {
      const response = await fetch(urlQueryProducts, {
        method: "POST", // Set the request method to POST
        headers: {
          "Content-Type": "application/json" // Specify the content type as JSON
        },
        body: JSON.stringify({ params: params }) // Convert the parameters object to a JSON string
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`); // Check if the response is ok (status in the range 200-299)
      }
      const data = await response.json(); // Parse the JSON response
      console.log("Success:", data); // Log or process the data
      return data; // Return the data for further processing if needed
    } catch (error) {
      console.error("Error:", error); // Handle errors
    }
  }

  // scroll to bottom
  // if last message in the list
  // check if last message and scroll to bottom
  isLastItemById = (array, id) => {
    // Check if the array is not empty
    if (array.length === 0) {
      return false;
    }

    // Get the id of the last item in the array
    const lastItemId = array[array.length - 1]._id;

    // Compare the provided id with the last item's id
    return id == lastItemId;
  };

  fetchProducts = async (domain, queries) => {
    const products = [];

    // Mapping each query to a promise to fetch products
    const fetchPromises = queries.map(queryParams => {
      return this.queryProducts(config.appId, domain, queryParams)
        .then(responseGraph => {
          if (responseGraph.data) {
            for (let item of responseGraph.data) {
              products.push({
                metadata: {
                  name: item.title,
                  url: item.handle,
                  image: item.image,
                  price: item.price,
                  currency: item.currency
                }
              });
            }
          }
        })
        .catch(error => {
          console.error(
            "Failed to fetch products for query:",
            queryParams,
            error
          );
        });
    });

    // Waiting for all fetch promises to complete
    await Promise.all(fetchPromises);

    return products;
  };

  getProducts2 = async (messageId, suggestedProduct) => {
    console.log("\n\n\nCALLLLLLLL   api2 products");
    console.log("suggestedProduct", messageId, suggestedProduct);

    let domain = this.getCleanDomain();
    console.log("domain", domain);

    // this.updateMessage(messageId, null, suggestedProduct.id, true, false);
    // this.updateMessage(messageId, [], suggestedProduct.id, true, false);
    this.updateMessage(messageId, null, suggestedProduct.id, true, false);
    setTimeout(() => {
      this.updateMessage(messageId, null, suggestedProduct.id, false, true);
      console.log("is last item", this.state.messages, messageId);
      // scroll to bottom for loading
      if (this.isLastItemById(this.state.messages, messageId)) {
        this.scrollToBottom();
      }
    }, 100);

    console.log("suggestedProduct params", suggestedProduct);
    // let queryParams = suggestedProduct.query;
    // let queryParams = suggestedProduct.queries[0];
    // console.log("queryParams", queryParams);
    //
    // let appId = config.appId;
    // let responseGraph = await this.queryProducts(appId, domain, queryParams);
    // console.log("data graph", responseGraph);

    // let products2 = [];
    let queries = suggestedProduct.queries;

    const products2 = await this.fetchProducts(domain, queries);

    // for (let queryParams of queries) {
    //   // let queryParams = suggestedProduct.queries[0];
    //   console.log("queryParams", queryParams);
    //
    //   let appId = config.appId;
    //   let responseGraph = await this.queryProducts(appId, domain, queryParams);
    //   console.log("data graph", responseGraph);
    //   if (responseGraph.data) {
    //     for (let item of responseGraph.data) {
    //       products2.push({
    //         metadata: {
    //           name: item.title,
    //           url: item.handle,
    //           image: item.image,
    //           price: item.price,
    //           currency: item.currency
    //         }
    //       });
    //     }
    //   }
    // }

    console.log("products2", products2);

    // this.updateMessageProductsLoading(messageId, suggestedProduct.id, true);

    this.updateMessage(messageId, products2, suggestedProduct.id, true, false);
    setTimeout(() => {
      this.updateMessage(
        messageId,
        products2,
        suggestedProduct.id,
        false,
        true
      );

      console.log("is last item", this.state.messages, messageId);
      if (this.isLastItemById(this.state.messages, messageId)) {
        this.scrollToBottom();
      }
    }, 100);
    // console.log('updateMessage', messageId);
  };

  getProductsLocal = async (messageId, suggestedProduct) => {
    console.log("\n\n\nCALLLLLLLL   api2 products local");
    console.log("suggestedProduct local", messageId, suggestedProduct);

    // let domain = this.getCleanDomain();
    // console.log("domain", domain);

    // this.updateMessage(messageId, null, suggestedProduct.id, true, false);
    // this.updateMessage(messageId, [], suggestedProduct.id, true, false);
    this.updateMessage(messageId, null, suggestedProduct.id, true, false);
    setTimeout(() => {
      this.updateMessage(messageId, null, suggestedProduct.id, false, true);
      console.log("is last item", this.state.messages, messageId);
      // scroll to bottom for loading
      if (this.isLastItemById(this.state.messages, messageId)) {
        this.scrollToBottom();
      }
    }, 100);

    console.log("suggestedProduct params", suggestedProduct);
    // let queryParams = suggestedProduct.query;
    // let queryParams = suggestedProduct.queries[0];
    // console.log("queryParams", queryParams);
    //
    // let appId = config.appId;
    // let responseGraph = await this.queryProducts(appId, domain, queryParams);
    // console.log("data graph", responseGraph);

    // let products2 = [];
    // let queries = suggestedProduct.queries;
    //
    // const products2 = await this.fetchProducts(domain, queries);

    // for (let queryParams of queries) {
    //   // let queryParams = suggestedProduct.queries[0];
    //   console.log("queryParams", queryParams);
    //
    //   let appId = config.appId;
    //   let responseGraph = await this.queryProducts(appId, domain, queryParams);
    //   console.log("data graph", responseGraph);
    //   if (responseGraph.data) {
    //     for (let item of responseGraph.data) {
    //       products2.push({
    //         metadata: {
    //           name: item.title,
    //           url: item.handle,
    //           image: item.image,
    //           price: item.price,
    //           currency: item.currency
    //         }
    //       });
    //     }
    //   }
    // }

    function transformData(input) {
      return input.map(item => ({
        metadata: {
          name: item.title,
          url: item.handle,
          image: item.image,
          price: item.price,
          currency: item.currency
        }
      }));
    }

    let products2 = transformData(suggestedProduct.items);
    console.log("products2", products2);

    // this.updateMessageProductsLoading(messageId, suggestedProduct.id, true);

    this.updateMessage(messageId, products2, suggestedProduct.id, true, false);
    setTimeout(() => {
      this.updateMessage(
        messageId,
        products2,
        suggestedProduct.id,
        false,
        true
      );

      console.log("is last item", this.state.messages, messageId);
      if (this.isLastItemById(this.state.messages, messageId)) {
        this.scrollToBottom();
      }
    }, 100);
    // console.log('updateMessage', messageId);
  };

  getProducts = async (messageId, suggestedProduct) => {
    //console.log("getting products 1");

    this.getProductsLocal(messageId, suggestedProduct);

    // this.getProducts2(messageId, suggestedProduct);

    // let query = `"${suggestedProduct.model} ${suggestedProduct.type}" "${suggestedProduct.color}" "${suggestedProduct.material}"`;
    // this.updateMessage(messageId, null, suggestedProduct.id, true, false);
    // let products = await this.getGoogleProductsByQuery(suggestedProduct.gs);
    // console.log("products", products);
    //
    // // this.updateMessageProductsLoading(messageId, suggestedProduct.id, true);
    //
    // this.updateMessage(messageId, products, suggestedProduct.id, true, false);
    // setTimeout(() => {
    //   this.updateMessage(messageId, products, suggestedProduct.id, false, true);
    // }, 1500);
    // // console.log('updateMessage', messageId);
  };

  // getProducts = async (messageId, suggestedProduct) => {
  //   //console.log("getting products 1");
  //
  //   this.getProducts2(messageId, suggestedProduct);
  //
  //   let query = `"${suggestedProduct.model} ${suggestedProduct.type}" "${suggestedProduct.color}" "${suggestedProduct.material}"`;
  //   this.updateMessage(messageId, null, suggestedProduct.id, true, false);
  //   let products = await this.getGoogleProductsByQuery(suggestedProduct.gs);
  //   //console.log("products", products);
  //
  //   // this.updateMessageProductsLoading(messageId, suggestedProduct.id, true);
  //
  //   this.updateMessage(messageId, products, suggestedProduct.id, true, false);
  //   setTimeout(() => {
  //     this.updateMessage(messageId, products, suggestedProduct.id, false, true);
  //   }, 1500);
  //   // console.log('updateMessage', messageId);
  // };

  addAIMessage(text, messageId, suggestedProducts) {
    //console.log('\n\n\nadding loading message\n\n\n\n');
    let newMessageId = messageId ? messageId : uuidv4();
    let loadingMessageItem = {
      _id: newMessageId, // uuidv4(),
      text: text,
      response: text,
      createdAt: new Date(),
      user: this.state.aiUser
      // user: {
      //   _id: 'mod',
      //   name: 'MOD',
      //   avatar:
      //     'https://traveline-images.s3.amazonaws.com/fashionapp/general/logo_chat.png',
      // },
    };

    // this.setState({
    //   messages: GiftedChat.append(this.state.messages, [loadingMessageItem]),
    // });
    this.addMessages([loadingMessageItem]);

    let products = [];
    if (suggestedProducts && suggestedProducts.length > 0) {
      for (let item of suggestedProducts) {
        products.push(item.searchText);
      }
    }

    let productsText = "";
    if (products && products.length > 0) {
      productsText = products.join(", ");
    }

    config.analytics.trackProduction(`Chat Message Response`, {
      distinct_id: config.userId,
      chatPersonality: this.state.persona.name,
      chatMessage: text,
      chatProducts: productsText,
      chatChannelId: this.state.channelId
    });

    // setTimeout(
    //   () =>
    //     this.setState({
    //       messages: GiftedChat.append(this.state.messages, [
    //         loadingMessageItem,
    //       ]),
    //     }),
    //   // this.setState({
    //   //   messages: [loadingMessageItem, ...this.state.messages  ],
    //   // }),
    //   100
    // );
  }

  addLoadingMessage() {
    //console.log('\n\n\nadding loading message\n\n\n\n');
    let messageId = uuidv4();
    let loadingMessageItem = {
      _id: uuidv4(),
      text: "Looking for something",
      response: "Looking for something",
      createdAt: new Date(),
      user: this.state.aiUser
      // user: {
      //   _id: 'mod',
      //   name: 'MOD',
      //   avatar:
      //     'https://traveline-images.s3.amazonaws.com/fashionapp/general/logo_chat.png',
      // },
    };
    setTimeout(
      () => this.addMessages([loadingMessageItem]),
      // this.setState({
      //   messages: GiftedChat.append(this.state.messages, [
      //     loadingMessageItem,
      //   ]),
      // }),
      // this.setState({
      //   messages: [loadingMessageItem, ...this.state.messages  ],
      // }),
      400
    );
  }

  onSend = async (messages, isAutocomplete = false) => {
    // console.log(this.state.messages);
    // console.log(messages);
    let text = messages[0]["text"];

    if (text) {
      text = `${text}`; //  Describe the clothing items in details.

      // let response = await this.getOpenAIData(text);
      // this.pushAssistantResponse(response);

      // this.addLoadingMessage();

      // let response = this.getOpenAIData(text);

      this.getOpenAIData(text);
      // this.setState({
      //   messages: GiftedChat.append(this.state.messages, messages),
      // });
      this.addMessages(messages);

      config.analytics.trackProduction(`Chat Message`, {
        distinct_id: config.userId,
        chatPersonality: this.state.persona.name,
        chatMessage: text,
        chatSuggestion: isAutocomplete,
        chatChannelId: this.state.channelId
      });

      // setTimeout(() => {
      //   this.addLoadingMessage();
      // }, 1000);
    }

    // text = `${text}. I have a ${config.i18n.t(
    //   urlize(config.siluetteName)
    // )} body, and a ${config.i18n.t(
    //   urlize(config.personalityName1)
    // )} personality. Describe the fashion products in details.`;
    // // console.log('text', text);

    // text = `${text}`; //  Describe the clothing items in details.
    //
    // // let response = await this.getOpenAIData(text);
    // // this.pushAssistantResponse(response);
    //
    // let response = this.getOpenAIData(text);
    //
    // this.setState({
    //   messages: GiftedChat.append(this.state.messages, messages),
    // });

    // the response is handled by pubnub

    // if (response) {
    //   this.pushAssistantResponse(response);
    // } else {
    //   // there was an error, retry
    //   this.setState({ gpt4Error: true });
    // }

    // console.log('response', response);

    // console.log('response', response);

    // let response1 = `For a romantic party look with a rectangle body shape, I would suggest a classic A-line dress with a cinched waist and a flowy skirt. The dress should be in a light color such as a pale pink or a soft lavender, and should feature a delicate fabric such as chiffon or silky satin. I would also suggest adding some romantic details such as lace trim, beadwork, or embroidery. To complete the look, pair the dress with dainty, strappy heels and simple jewellery such as a pearl necklace or a crystal bracelet. For a finishing touch, choose a clutch bag in a matching color to the dress.`;

    // let productsText = `Extract clothing items from this text: ${response}. Separate results by comma.`;
    // let responseProducts = await this.getOpenAIDataForProducts(productsText);
    // // console.log('response items', responseProducts);
    // let items = responseProducts.split(',');
    // items = items.map(item => item.trim());
    // console.log(items);

    // call api
  };

  buildMessages = (text, context) => {
    // let defaultContext = `. The response should be according to my stylistic profile. My stylistic profile is as follows: I have a ${dataEn[
    //   urlize(config.siluetteName)
    // ]} body shape, a ${dataEn[
    //   urlize(config.personalityName1)
    // ]} style personality and a ${config.paletteName.toLowerCase()} color palette.`;
    //
    //

    let messages = [];
    let messageSystem = this.state.systemMessage;
    // if (context == "suggestedUserResponses") {
    //   messages.push(this.state.systemMessageSuggestedUserResponses);
    // } else {
    //   messages.push(this.state.systemMessage);
    // }
    messages.push(this.state.systemMessage);

    let allMessages = [...this.state.messages];
    allMessages = allMessages.reverse();
    // console.log('allMessages', allMessages);

    let maxMessages = 50;
    for (let item of allMessages.reverse().slice(-maxMessages)) {
      messages.push({
        role: item.user._id == this.state.aiUser._id ? "assistant" : "user",
        content:
          item.user._id == this.state.aiUser._id ? item.response : item.text // item.text,
      });
    }
    if (text) {
      messages.push({ role: "user", content: text });
    }

    return {
      messages: messages
    };
  };

  getOpenAIDataForSuggestedUserResponses = async (text, messageId) => {
    let response = null;
    let error = false;

    let actionId = uuidv4();
    let maxTokens = 150;
    let url = `https://xfkt65hb0j.execute-api.eu-west-2.amazonaws.com/dev/chat-gpt?userId=${config.userId}&channelId=${this
      .state
      .channelId}&messageId=${messageId}&isSuggestion=1&action=gpt-4&actionId=${actionId}&tokens=${maxTokens}`;

    // let messages = {
    //   messages: [
    //     // { role: 'system', content: 'You are a virtual style assistant.' },
    //     this.state.systemMessageSuggestedUserResponses,
    //     { role: 'user', content: text },
    //   ],
    // };

    let messages = this.buildMessages(null, "suggestedUserResponses");

    // messages.messages.push({
    //   role: 'user',
    //   content: `Give me a set of 3 predefined suggestions(the suggestions should have 3-10 words each) that the user may use to continue this conversation. Here's an example of your output format: {\"suggestedUserResponses\":[\"\", \"\", \"\"]}.The response must contain just the JSON object.`,
    // });

    let conversation = ``;
    // for (let item of messages.messages) {
    //   conversation = `${conversation} ${item.role}:${item.content}.`;
    // }
    // console.log(conversation);

    let lastMessage = messages.messages[messages.messages.length - 1];
    conversation = lastMessage.content;

    let newMessages = {
      messages: [
        // { role: 'system', content: 'You are a virtual style assistant.' },
        this.state.systemMessageSuggestedUserResponses,
        {
          role: "user",
          content: `Give me a set of 3 predefined suggestions(the suggestions should have maximum 7 words) that the user may use to continue this conversation. Here's an example of your output format: {\"suggestedUserResponses\":[\"\", \"\", \"\"]}.The response must contain just the JSON object.This is the conversation: ${conversation}`
        }
      ]
    };

    //console.log('GPT4 api call - suggested user responses', newMessages);
    this.setState({ isLoadingUserSuggestions: true });
    const rawResponse = fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(newMessages)
    });
    // const content = await rawResponse.json();

    // console.log('content', content);
    // if (content && content.data && content.data[0]) {
    //   // this.setState({ outfitText: content.data[0].text, text: text });
    //
    //   response = content.data[0].message.content;
    // } else {
    //   // there was an error, retry
    //   error = true;
    // }
    //
    // return error ? null : response;
  };

  getOpenAIDataForMessage = async (text, messageId) => {
    let response = null;
    let error = false;

    let actionId = uuidv4();
    let maxTokens = 256;
    let url = `https://xfkt65hb0j.execute-api.eu-west-2.amazonaws.com/dev/chat-gpt?userId=${config.userId}&channelId=${this
      .state
      .channelId}&messageId=${messageId}&action=gpt-4&actionId=${actionId}&tokens=${maxTokens}`;

    let productsText = `Find and extract all the clothing items, shoes, and accessories from the text and build a response with the product items that should have the following keys: ti (a text that will be displayed as a title, two-three words length text) and gs(a text that will be used to search on google in order to receive image results for that product). For \"ti\"(shortcut from title) and \"gs\"(shortcut from google search) use a combination of type, model, color, material, and title.
Here's an example of your output format:
{"sp":[{"ti": "","gs": ""}]}.
If you don't find any items return the empty JSON {"sp":[]}.
Instructions:
- The response should contain just the JSON with no additional text!
This is the text: ${text}`;
    let messages = {
      messages: [
        // { role: 'system', content: 'You are a virtual style assistant.' },
        this.state.systemMessageSuggestedProducts,
        { role: "user", content: productsText } // text
      ]
    };

    //console.log('GPT4 api call - products', messages);

    // display another component that show loading

    // setTimeout(() => {
    //   this.setState({ isLoadingSuggestedProductsLoading2: true });
    // }, 9500);

    const rawResponse = fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(messages)
    });
    // console.log(url);
    // this.setState({ isLoadingSuggestedProducts: true });
    setTimeout(() => {
      this.setState({ isLoadingSuggestedProducts: true });
    }, 1000);

    // const content = await rawResponse.json();

    // console.log('content', content);
    // if (content && content.data && content.data[0]) {
    //   // this.setState({ outfitText: content.data[0].text, text: text });
    //
    //   response = content.data[0].message.content;
    // } else {
    //   // there was an error, retry
    //   error = true;
    // }
    //
    // return error ? null : response;
  };

  // getOpenAIData = async text => {
  //   let response = null;
  //   let error = false;
  //
  //   let actionId = uuidv4();
  //   // let maxTokens = 150;
  //   let maxTokens = 1024;
  //   let temperature = 0.3;
  //   // let url = `https://enofvc3o7f.execute-api.us-east-1.amazonaws.com/production/chat-gpt-4-preview?userId=${config.userId}&channelId=${this
  //   // .state
  //   // .channelId}&action=gpt-4&actionId=${actionId}&tokens=${maxTokens}&temperature=${temperature}`;
  //
  //   let url = `https://enofvc3o7f.execute-api.us-east-1.amazonaws.com/production/chat-gpt-3-5?userId=${config.userId}&channelId=${this
  //     .state
  //     .channelId}&action=gpt-4&actionId=${actionId}&tokens=${maxTokens}&temperature=${temperature}`;
  //
  //   // let url = `https://xfkt65hb0j.execute-api.eu-west-2.amazonaws.com/dev/chat-gpt-4?userId=${config.userId}&channelId=${this
  //   //   .state.channelId}&action=gpt-4&actionId=${actionId}&tokens=${maxTokens}`;
  //
  //   // https://010k6ej9eg.execute-api.eu-west-2.amazonaws.com/production/chat-gpt-4-preview
  //
  //   // let url = `https://enofvc3o7f.execute-api.us-east-1.amazonaws.com/production/chat-gpt-4?userId=${config.userId}&channelId=${this
  //   //   .state
  //   //   .channelId}&action=gpt-4&actionId=${actionId}&tokens=${maxTokens}&temperature=${temperature}`;
  //
  //   // let url = `https://tlezfn5agpzapwpuqqe73ych5a0stnxv.lambda-url.us-east-1.on.aws?userId=${config.userId}&channelId=${this
  //   //   .state
  //   //   .channelId}&action=gpt-4&actionId=${actionId}&tokens=${maxTokens}&temperature=${temperature}`;
  //
  //   console.log("url openai", url);
  //   // let messages = {
  //   //   messages: [{ role: 'user', content: text }],
  //   // };
  //   let messages = this.buildMessages(text ? text : null);
  //
  //   // setTimeout(() => {
  //   //   this.setState({ isLoadingMessage2: true });
  //   // }, 5500);
  //
  //   try {
  //     const rawResponse = fetch(url, {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json"
  //       },
  //       body: JSON.stringify(messages)
  //     }).then(async response => {
  //       let r = await response.json();
  //       // console.log(r);
  //       // // Check if the request was successful
  //       // if (!response.ok) {
  //       //   throw new Error("Network response was not ok");
  //       // }
  //       // return response.json(); // parse the response body as JSON
  //     });
  //     // console.log('request to', url);
  //   } catch (e) {
  //     console.log(e);
  //   }
  //
  //   console.log("GPT4 api call - messages", messages);
  //   // display another component to show loading
  //   // this.setState({ isLoading: true });
  //   setTimeout(() => {
  //     this.setState({ isLoading: true, gpt4Error: false });
  //   }, 1000);
  //
  //   // const content = await rawResponse.json();
  //
  //   // console.log('content', content);
  //   // if (content && content.data && content.data[0]) {
  //   //   // this.setState({ outfitText: content.data[0].text, text: text });
  //   //
  //   //   response = content.data[0].message.content;
  //   // } else {
  //   //   // there was an error, retry
  //   //   error = true;
  //   // }
  //   //
  //   // return error ? null : response;
  // };

  getOpenAIData = async text => {
    let response = null;
    let error = false;
    let actionId = uuidv4();
    let maxTokens = 1024;
    let url = `https://enofvc3o7f.execute-api.us-east-1.amazonaws.com/production/chat-gpt-4-stream/?userId=${config.userId}&channelId=${this
      .state.channelId}&action=gpt-4&actionId=${actionId}&tokens=${maxTokens}`;
    console.log("systemMessage", this.state.systemMessage);
    console.log("url", url);
    // let messages = {
    //   messages: [{ role: 'user', content: text }],
    // };
    let messages = this.buildMessages(text ? text : null);
    console.log("messages for opena ai", messages);

    let attempts = 0;
    const maxAttempts = 2; // Max retry attempts

    // const fetchData = async () => {
    //   try {
    //     const rawResponse = fetch(url, {
    //       method: "POST",
    //       headers: {
    //         Accept: "application/json",
    //         "Content-Type": "application/json"
    //         // "Access-Control-Allow-Origin": "*"
    //       },
    //       body: JSON.stringify(messages)
    //     }).then(async response => {
    //       let r = await response.json();
    //       this.addMessageToUser();
    //
    //       // console.log(r);
    //       // // Check if the request was successful
    //       // if (!response.ok) {
    //       //   throw new Error("Network response was not ok");
    //       // }
    //       // return response.json(); // parse the response body as JSON
    //     });
    //     // console.log('request to', url);
    //   } catch (e) {
    //     console.log(e);
    //
    //     attempts += 1;
    //     if (attempts < maxAttempts) {
    //       console.log(`Retrying... Attempt ${attempts + 1}`);
    //       await fetchData(); // Retry fetching data
    //     }
    //   }
    // };

    const fetchData = async () => {
      let attempts = 0;
      const maxAttempts = 3; // Set the maximum number of retry attempts

      while (attempts < maxAttempts) {
        try {
          const response = await fetch(url, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
              // 'Access-Control-Allow-Origin': '*' // Uncomment if CORS policy permits
            },
            body: JSON.stringify(messages)
          });

          if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.status}`);
          }

          const data = await response.json();
          this.addMessageToUser();
          console.log("Request successful", data);
          return data; // Return the data on successful fetch
        } catch (error) {
          console.log(`Attempt ${attempts + 1} failed:`, error.message);
          attempts += 1;

          if (attempts >= maxAttempts) {
            console.log("Max attempts reached, stopping retries");
            throw error; // Rethrow the last error after max attempts
          }
        }
      }
    };

    async function performDataFetch() {
      try {
        const data = await fetchData(); // Wait for fetchData to complete
        console.log("Data fetched successfully:", data);
        // Continue with additional code if necessary
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors or retry mechanisms here
      }
    }

    console.log("set isLoading:true");
    this.setState({ isLoading: true, gpt4Error: false });

    // Call the performDataFetch function
    performDataFetch();

    console.log("GPT4 api call - messages", messages);
    // display another component to show loading
    // this.setState({ isLoading: true });
    // setTimeout(() => {
    //   this.setState({ isLoading: true, gpt4Error: false });
    // }, 500);
  };

  // getOpenAIDataForProducts = async text => {
  //   let response = null;
  //   // if (!color) {
  //   //   color = this.state.colorName;
  //   // }
  //   //
  //   // const { itemName } = this.props;
  //   // let text = `Describe in detail outfits for ${color.toLowerCase()} color ${itemName.toLowerCase()}`;
  //   // let text = `Describe an outfit for ${color.toLowerCase()} color ${itemName.toLowerCase()}`;
  //
  //   //console.log('request started', text);
  //   // var url = `https://xfkt65hb0j.execute-api.eu-west-2.amazonaws.com/dev/text-generation-tool?text=${text}`;
  //   //
  //   // const rawResponse = await fetch(url);
  //   // const content = await rawResponse.json();
  //   // console.log('content', content);
  //
  //   let url = `https://xfkt65hb0j.execute-api.eu-west-2.amazonaws.com/dev/chat-gpt`;
  //   let messages = {
  //     messages: [
  //       // { role: 'system', content: 'You are a virtual style assistant.' },
  //       this.state.systemMessage,
  //       { role: "user", content: text }
  //     ]
  //   };
  //
  //   // console.log('messages', messages);
  //   const rawResponse = await fetch(url, {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json"
  //     },
  //     body: JSON.stringify(messages)
  //   });
  //   const content = await rawResponse.json();
  //
  //   // console.log('content', content);
  //   if (content && content.data && content.data[0]) {
  //     // this.setState({ outfitText: content.data[0].text, text: text });
  //
  //     response = content.data[0].message.content;
  //   }
  //
  //   return response;
  // };

  onContentSizeChange = () => {
    if (this.flatListRef.current) {
      this.flatListRef.current.scrollToEnd({ animated: false });
      // setTimeout(() => {
      //   this.flatListRef.current.scrollToEnd({ animated: false });
      // }, 1);
    }
  };

  // scrollToLeft = () => {
  //   this.scrollViewRef.current.scrollTo({ x: 0, animated: true });
  // };
  //
  // scrollToRight = () => {
  //   this.scrollViewRef.current.scrollToEnd({ animated: true });
  // };

  scrollToLeft = () => {
    const newPosition = Math.max(this.state.scrollPosition - 200, 0); // 100 is the amount to scroll, adjust as needed
    this.scrollViewRef.current.scrollTo({ x: newPosition, animated: true });
    this.setState({ scrollPosition: newPosition });
  };

  scrollToRight = () => {
    const newPosition = this.state.scrollPosition + 200; // 100 is the amount to scroll, adjust as needed
    this.scrollViewRef.current.scrollTo({ x: newPosition, animated: true });
    this.setState({ scrollPosition: newPosition });
  };

  render() {
    console.log("user from state", this.state.userPersona);
    console.log("set isLoading - render", this.state.isLoading);

    //console.log('render app');
    // console.log(this.state.messages[0]);
    // let allMessages = [...this.state.messages].reverse();
    let allMessages = [...this.state.messages];
    console.log("allMessages", allMessages);

    let loadingMessageItem = {
      _id: uuidv4(),
      text: "Searching for products...",

      response: "How can I help you?",
      createdAt: new Date(),
      user: this.state.aiUser
      // user: {
      //   _id: 'mod',
      //   name: 'MOD',
      //   avatar:
      //     'https://traveline-images.s3.amazonaws.com/fashionapp/general/logo_chat.png',
      // },
    };

    let productListItem = {
      _id: uuidv4(),
      text: "Here is a list of products",

      response: "Here is a list of products",
      createdAt: new Date(),
      user: this.state.aiUser
      // user: {
      //   _id: 'mod',
      //   name: 'MOD',
      //   avatar:
      //     'https://traveline-images.s3.amazonaws.com/fashionapp/general/logo_chat.png',
      // },
    };

    console.log("allMessages", allMessages);

    // // join category
    // let newMessages = [];
    //
    // const bundleProductsByCategory = data => {
    //   // let newData = [];
    //
    //   // Collect all entries by category
    //   data.forEach((item, key) => {
    //     const categoriesMap = {};
    //     console.log(item.suggestedProducts);
    //     if (item.suggestedProducts && item.suggestedProducts.length > 0) {
    //       let productTmp = item.suggestedProducts[0];
    //       // if not from cache, not formatted
    //       if (productTmp.query) {
    //         item.suggestedProducts.forEach(product => {
    //           const id = product.id;
    //           const category = product.query.category;
    //           const categoryTranslated = product.query.categoryTranslated;
    //           if (!categoriesMap[category]) {
    //             categoriesMap[category] = {
    //               id: id,
    //               category: category,
    //               categoryTranslated: categoryTranslated,
    //               queries: []
    //             };
    //           }
    //           // Add queries
    //           categoriesMap[category].queries.push(product.query);
    //         });
    //
    //         const bundledData = Object.values(categoriesMap);
    //         console.log("bundledData", bundledData);
    //         data[key].suggestedProducts = bundledData;
    //       }
    //     }
    //   });
    //
    //   return data;
    // };
    // allMessages = bundleProductsByCategory(allMessages);
    //
    // console.log("allMessages", allMessages);

    //console.log('render chat', Math.random());
    return (
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%"
          // backgroundColor: "yellow",
          // paddingBottom:50,
        }}
      >
        {this.props.header}
        {!this.state.userPersona && (
          <View
            style={{
              // display: "flex",
              flex: 1,
              // overflowY: "auto",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <View
              mt={48}
              style={{
                display: "flex",
                // flex: 1,
                // overflowY: "auto",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Lottie
                animationData={loadingActivityIndicator}
                loop={true}
                style={{ width: 80, height: 80 }}
              />
              <Text> </Text>
            </View>
          </View>
        )}
        {this.state.userPersona && (
          <View
            ref={this.chatContainerRef}
            style={{ flex: 1, overflowY: "auto" }}
          >
            {this.headerComponent()}
            {allMessages.map((item, index) => (
              <View>
                {item.user._id != this.state.aiUser._id && (
                  <View mb={24}>
                    <View
                      mx={24}
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection:
                          item.user._id == this.state.aiUser._id
                            ? "row"
                            : "row-reverse",
                        alignItems: "flex-start"
                        // backgroundColor: 'yellow'
                      }}
                    >
                      <View
                        mr={0}
                        ml={0}
                        style={{
                          flex: 1,
                          // paddingVertical: 12,
                          paddingTop: 12,
                          paddingBottom: 12,
                          // paddingHorizontal: 16,
                          paddingLeft: 16,
                          paddingRight: 16,
                          backgroundColor:
                            item.user._id == this.state.aiUser._id
                              ? colors.bgVioletLighter
                              : colors.inkLightest,
                          borderRadius: 8
                          // flexGrow: 1,
                        }}
                      >
                        <Text
                          small_message
                          style={{
                            color: colors.inkDarkest
                            // flexGrow: 1,
                          }}
                        >
                          {item.text}
                        </Text>
                      </View>
                    </View>
                  </View>
                )}
                {item.user._id == this.state.aiUser._id && (
                  <View mb={24}>
                    <View
                      mx={24}
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection:
                          item.user._id == this.state.aiUser._id
                            ? "row"
                            : "row-reverse",
                        alignItems: "flex-start"
                        // backgroundColor: 'yellow'
                      }}
                    >
                      <Image
                        source={{
                          uri:
                            "https://traveline-images.s3.amazonaws.com/shopify/mod/logo_ai_1024x1024.png" // this.state.userPersona
                          //  ? this.state.userPersona.chat.imagePreviewUrl
                          //  : ""
                        }}
                        style={{
                          width: 24,
                          height: 24,
                          borderRadius: 50,
                          // borderColor: colors.bdLine,
                          // borderWidth: 1,
                          backgroundColor: this.props.userPersona.chat
                            .primaryColor // colors.bgVioletBase
                        }}
                        resizeMode="contain"
                      />

                      <View
                        mr={0}
                        ml={8}
                        style={{
                          flex: 1,
                          // paddingVertical: 12,
                          paddingTop: 12,
                          paddingBottom: 12,
                          // paddingHorizontal: 16,
                          paddingLeft: 16,
                          paddingRight: 16,
                          backgroundColor:
                            item.user._id == this.state.aiUser._id
                              ? this.props.userPersona.chat.primaryColor // colors.bgVioletBase
                              : colors.inkLightest,
                          borderRadius: 8
                          // flexGrow: 1,
                        }}
                      >
                        <Text
                          small_message
                          style={{
                            color: "#fff" // colors.inkDarkest
                            // flexGrow: 1,
                          }}
                        >
                          {item.text}
                        </Text>
                      </View>
                    </View>
                    <View>
                      <View>
                        {item.suggestedProducts &&
                          item.suggestedProducts.length > 0 && (
                            <ProductsBar
                              items={item.suggestedProducts}
                              getProducts={this.getProducts}
                              item={item}
                            />
                          )}

                        {false &&
                          item.suggestedProducts &&
                          item.suggestedProducts.length == 1 && (
                            <View mt={16}> </View>
                          )}

                        {false &&
                          item.suggestedProducts && (
                            <View>
                              <ScrollView
                              // ref={this.scrollViewRef}
                              // onScroll={event => {
                              //   this.setState({
                              //     scrollPosition:
                              //       event.nativeEvent.contentOffset.x
                              //   });
                              // }}
                              // scrollEventThrottle={16} // Adjust based on your needs
                              // style={{ padding: 0 }}
                              // horizontal
                              // showsHorizontalScrollIndicator={false}
                              // contentContainerStyle={{
                              //   paddingTop: 16,
                              //   paddingLeft: 56,
                              //   paddingRight: 20
                              //   // width: 300
                              //   // paddingBottom: 12,
                              // }}
                              >
                                {item.suggestedProducts.map(
                                  suggestedProduct => (
                                    <TouchableOpacity
                                      onClick={() => {
                                        this.getProducts(
                                          item._id,
                                          suggestedProduct
                                        );
                                      }}
                                    >
                                      <View
                                        mr={10}
                                        // my={10}
                                        // mx={10}
                                        // px={8}
                                        // py={16}
                                        style={{
                                          // height: 40,
                                          borderWidth: 1,
                                          borderRadius: 4,
                                          borderColor:
                                            suggestedProduct.id ==
                                            item.activeSuggestedProduct
                                              ? "#000"
                                              : colors.inkLight
                                          // backgroundColor:
                                          //   suggestedProduct.id ==
                                          //   item.activeSuggestedProduct
                                          //     ? colors.bdLine
                                          //     : '#fff',
                                        }}
                                      >
                                        <Text
                                          tiny_none_regular
                                          px={12}
                                          py={8}
                                          style={{
                                            color:
                                              suggestedProduct.id ==
                                              item.activeSuggestedProduct
                                                ? colors.inkBase
                                                : colors.inkLight
                                          }}
                                        >
                                          {suggestedProduct.title}
                                        </Text>
                                      </View>
                                    </TouchableOpacity>
                                  )
                                )}
                              </ScrollView>
                              {/*
                   <View
                   // style={{position: "absolute"}}
                   >
                   <TouchableOpacity
                     style={{ position: "absolute", right: 20 }}
                     onPress={this.scrollToRight}
                     style={styles.arrowButton}
                   >
                     <Text style={styles.arrowText}>{">"}</Text>
                   </TouchableOpacity>
                   </View>
                   */}
                            </View>
                          )}

                        {item.products && (
                          <View
                            mt={
                              item.suggestedProducts &&
                              item.suggestedProducts.length == 0
                                ? 16
                                : 0
                            }
                          >
                            <Products
                              items={item.products}
                              shop={this.state.userPersona.shop}
                            />
                          </View>
                        )}

                        {item.products === null && (
                          <View
                            style={{
                              width: "100%",
                              height: 144 * 1.1 + 6,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center"
                            }}
                          >
                            <Lottie
                              animationData={loadingActivityIndicator}
                              loop={true}
                              style={{ width: 80, height: 80 }}
                            />
                          </View>
                        )}

                        {!!this.props.isLocal && (
                          <ScrollView
                            // ref={this.scrollViewRef}
                            // onScroll={event => {
                            //   this.setState({
                            //     scrollPosition:
                            //       event.nativeEvent.contentOffset.x
                            //   });
                            // }}
                            // scrollEventThrottle={16} // Adjust based on your needs
                            // style={{ padding: 0 }}
                            // horizontal
                            // showsHorizontalScrollIndicator={false}
                            // contentContainerStyle={{
                            //   paddingTop: 16,
                            //   paddingLeft: 56,
                            //   paddingRight: 20
                            //   // width: 300
                            //   // paddingBottom: 12,
                            // }}

                            style={{ paddingTop: 10, paddingLeft: "55px" }}
                          >
                            {item.suggestedProducts &&
                              item.suggestedProducts.length > 0 &&
                              item.suggestedProducts.map(suggestedProduct => (
                                <View
                                  mr={10}
                                  // my={10}
                                  // mx={10}
                                  // px={8}
                                  // py={16}
                                  style={{
                                    flex: 1,
                                    alignItems: "left",
                                    justifyContent: "left"
                                    // height: 40,
                                    // borderWidth: 1,
                                    // borderRadius: 4,
                                    // borderColor:
                                    //   suggestedProduct.id ==
                                    //   item.activeSuggestedProduct
                                    //     ? "#000"
                                    //     : colors.inkLight
                                    // backgroundColor:
                                    //   suggestedProduct.id ==
                                    //   item.activeSuggestedProduct
                                    //     ? colors.bdLine
                                    //     : '#fff',
                                  }}
                                >
                                  <pre>
                                    {JSON.stringify(suggestedProduct, null, 2)}
                                  </pre>
                                </View>
                              ))}
                          </ScrollView>
                        )}
                        {false &&
                          item.suggestedProducts &&
                          item.suggestedProducts.length > 0 &&
                          item.products &&
                          !item.products.length && (
                            <View
                              style={{
                                width: "100%",
                                height: 144 * 1.1 + 6,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                              }}
                            >
                              <Lottie
                                animationData={loadingActivityIndicator}
                                loop={true}
                                style={{ width: 80, height: 80 }}
                              />
                            </View>
                          )}

                        {false &&
                          item.products &&
                          item.products.length > 0 && (
                            <ScrollView
                            // style={{ padding: 0 }}
                            // horizontal
                            // showsHorizontalScrollIndicator={false}
                            // contentContainerStyle={{
                            //   paddingTop: 8,
                            //   paddingLeft: 56,
                            //   paddingRight: 20
                            //   // width: 300
                            //   // paddingBottom: 12,
                            // }}
                            >
                              <View
                                style={{
                                  display: "flex",
                                  flex: 1,
                                  flexDirection: "row"
                                }}
                                key={"skeleton-2"}
                              >
                                {item.products.map((product, index) => (
                                  <View mr={8} style={{}} key={index}>
                                    <TouchableOpacity
                                      onClick={() => {
                                        window.open(
                                          `https://quickstart-1689939c.myshopify.com/products/${product.metadata.name
                                            .toLowerCase()
                                            .replace(/[\s\W-]+/g, "-")
                                            .replace(/^-+|-+$/g, "")}`,
                                          // product.image.contextLink,
                                          "_blank"
                                        );
                                        // window.location.href =
                                        //   product.image.contextLink;

                                        config.analytics.trackProduction(
                                          `Product Link`,
                                          {
                                            distinct_id: config.userId,
                                            product_title: product.title,
                                            product_link:
                                              product.image.contextLink
                                          }
                                        );
                                        this.props.navigation.push(
                                          "ProductWebView",
                                          {
                                            uri: product.image.contextLink,
                                            title: product.displayLink
                                          }
                                        );
                                        // WebBrowser.openBrowserAsync(
                                        //   product.image.contextLink
                                        // );
                                      }}
                                    >
                                      <View
                                        // mx={10}
                                        style={{
                                          alignItems: "center"
                                        }}
                                      >
                                        <Image
                                          // transition={500}
                                          // width={96 * 1.7}
                                          // height={144 * 1.7}
                                          // placeholder={
                                          //   config.blurhash_2x3
                                          // }
                                          // source={{ uri: product.image.thumbnailLink }}
                                          source={{
                                            uri: `${product.metadata.image}`
                                          }}
                                          // source={{
                                          //   uri: `https://traveline-images.s3.amazonaws.com/fashionapp/general/${this
                                          //     .state.persona.image}`
                                          // }}
                                          // uri={imgUrl}
                                          // preview={preview}
                                          //indicator={ProgressBar}
                                          style={{
                                            width: 96 * 1.1,
                                            height: 144 * 1.1,
                                            // width: 96, // (width - 60) / 2, //width * 0.35,
                                            // height: 144, // (width - 60) / 2 * 1.5,
                                            // marginTop: 20,
                                            // textAlign: 'center',
                                            // backgroundColor: 'blue',
                                            //marginLeft: parseInt((width - imgWidth) / 2),
                                            // marginTop: getStatusBarHeight(true) + 30,
                                            //marginTop: isNativeApp ? getStatusBarHeight(true) + 30 : 80,
                                            //marginBottom: 40,
                                            borderWidth: 1,
                                            borderRadius: 2,
                                            borderColor: colors.inkLighter
                                          }}
                                          // resizeMode="contain"
                                          // maxBytes="100000"
                                          // contentFit="contain"
                                        />
                                      </View>
                                    </TouchableOpacity>
                                  </View>
                                ))}
                                {/*
                         <View mr={8} style={{}} key={index}>
                           <TouchableOpacity
                             onPress={() => {
                               //console.log('go to products all');
                               this.props.navigation.push(
                                 "ProductsAll",
                                 { item: item }
                               );
                             }}
                           >
                             <View
                               // mx={10}
                               style={{
                                 flex: 1,
                                 alignItems: "center",
                                 justifyContent: "center",

                                 width: 96,
                                 height: 144,
                                 borderWidth: 1,
                                 borderRadius: 2,
                                 borderColor: colors.inkLighter
                               }}
                             >
                               <Text tiny_none_regular>
                                 View all
                               </Text>
                             </View>
                           </TouchableOpacity>
                         </View>
                         */}
                              </View>
                            </ScrollView>
                          )}
                        {(true || config.localUsers.includes(config.userId)) &&
                          item.suggestedProducts &&
                          item.suggestedProducts.length > 0 && (
                            <ScrollView
                              horizontal
                              showsHorizontalScrollIndicator={false}
                              contentContainerStyle={{
                                paddingTop: 16,
                                paddingLeft: 56 + 40,
                                paddingRight: 20
                                // paddingBottom: 12,
                              }}
                            >
                              {/*
                     <TouchableOpacity>
                       <View
                         mr={8}
                         // my={10}
                         // mx={10}
                         // px={8}
                         // py={16}
                         style={{
                           // height: 30,
                           // borderWidth: 1,
                           // borderRadius: 4,
                           // borderColor:
                           //   suggestedProduct.id ==
                           //   item.activeSuggestedProduct
                           //     ? '#000'
                           //     : colors.inkLight,
                           // backgroundColor:
                           //   suggestedProduct.id ==
                           //   item.activeSuggestedProduct
                           //     ? colors.bdLine
                           //     : '#fff',
                         }}
                       >
                         <Text small_none_regular px={16} py={8}>
                           Google image search:
                         </Text>
                       </View>
                     </TouchableOpacity>
                     */}
                              {item.suggestedProducts.map(suggestedProduct => (
                                <TouchableOpacity
                                  onPress={() => {
                                    this.getProducts(
                                      item._id,
                                      suggestedProduct
                                    );
                                  }}
                                >
                                  <View
                                    mr={8}
                                    // my={10}
                                    // mx={10}
                                    // px={8}
                                    // py={16}
                                    style={{
                                      // height: 30,
                                      borderWidth: 1,
                                      borderRadius: 4,
                                      borderColor:
                                        suggestedProduct.id ==
                                        item.activeSuggestedProduct
                                          ? "#000"
                                          : colors.inkLight
                                      // backgroundColor:
                                      //   suggestedProduct.id ==
                                      //   item.activeSuggestedProduct
                                      //     ? colors.bdLine
                                      //     : '#fff',
                                    }}
                                  >
                                    <Text
                                      tiny_none_regular
                                      px={16}
                                      py={8}
                                      style={{
                                        color:
                                          suggestedProduct.id ==
                                          item.activeSuggestedProduct
                                            ? colors.inkBase
                                            : colors.inkLight
                                      }}
                                    >
                                      {suggestedProduct.searchText}
                                    </Text>
                                  </View>
                                </TouchableOpacity>
                              ))}
                            </ScrollView>
                          )}
                      </View>
                    </View>
                  </View>
                )}
              </View>
            ))}
            {this.footerComponent()}
          </View>
        )}

        <View
          mt={10}
          style={{
            // backgroundColor: "blue",
            // position: "relative",
            // bottom: 24,
            // width: this.props.chatWidth
            // border: "solid 3px #f2f2f2"
          }}
        />
        {/*
        <View
          style={{
            // backgroundColor: "blue",
            // position: "relative",
            // bottom: 24,
            // width: this.props.chatWidth
            // border: "solid 3px #f2f2f2"
          }}
        >
          <View
            // px={24}
            py={16}
            style={{
              display: "flex",
              // flex: 1,
              // flexDirection: "row",
              // backgroundColor: 'blue',
              // width: '100%',
              zIndex: 2,
              paddingTop: 16,
              borderTop: "solid 1px #f2f2f2",
              // borderColor: "#f2f2f2",
              alignItems: "center"
              // width: "100%"
            }}
          >

            <form
              onSubmit={this.onSubmit}
              style={{
                display: "flex",
                flexGrow: 1
                // paddingLeft: 24,
                // paddingRight:24,
              }}
            >
              <input
                type="text"
                value={this.state.currentText}
                onChange={this.onChangeText}
                className="chatInput"
                style={{
                  flexGrow: 1,
                  borderColor: "#E3E5E5",
                  fontFamily: "CircularStd-Book",
                  fontSize: 16,
                  lineHeight: "20px",
                  letterSpacing: "-0.01em",
                  color: "#000000",
                  // borderColor: this.state.isActive ? "#000" : "#E3E5E5", // Adjust based on isActive state
                  borderRadius: 4,
                  borderWidth: 1,
                  borderStyle: "solid",
                  padding: "10px 16px",
                  margin: "0px 0px 0px 24px"
                  // width: "100%"
                }}
                placeholder="Type here..."
              />
            </form>

            <View ml={4} mr={24} style={{ justifyContent: "center" }}>
              <TouchableOpacity onClick={this.onSubmit}>
                <View>
                  <img
                    src={this.state.currentText ? imgSendActive : imgSend}
                    alt="Description"
                    style={{
                      width: 20,
                      height: 20
                    }}
                  />
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </View>
        */}
      </View>
    );
  }
}

export default Chat;
