import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import {
  space,
  color,
  typography,
  layout,
  flexbox,
  grid,
  background,
  borders,
  position,
  shadow
} from "styled-system";

const mainMargin = 12;

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'CircularStd-Black';
    src: url('https://s3.amazonaws.com/demo.runachat.ai/fonts/CircularStd-Black.otf') format('opentype');
  }

  @font-face {
    font-family: 'CircularStd-Book';
    src: url('https://s3.amazonaws.com/demo.runachat.ai/fonts/CircularStd-Book.otf') format('opentype');
  }

  @font-face {
    font-family: 'CircularStd-Bold';
    src: url('https://s3.amazonaws.com/demo.runachat.ai/fonts/CircularStd-Bold.otf') format('opentype');
  }

  @font-face {
    font-family: 'CircularStd-Light';
    src: url('https://s3.amazonaws.com/demo.runachat.ai/fonts/CircularStd-Light.otf') format('opentype');
  }

  @font-face {
    font-family: 'CircularStd-Medium';
    src: url('https://s3.amazonaws.com/demo.runachat.ai/fonts/CircularStd-Medium.otf') format('opentype');
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'CircularStd-Book', sans-serif;
  }
`;

export const View = styled.div`
  ${props =>
    props.showMargins &&
    `padding: 0 ${mainMargin}px;`} ${space} ${color} ${typography} ${layout} ${flexbox} ${grid} ${background} ${borders} ${position} ${shadow};
`;

export const Text = styled.div`
  ${space} ${color} ${typography} ${layout} ${flexbox} ${grid} ${background} ${borders} ${position} ${shadow};
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  font-family: CircularStd-Book;

  ${props => props.bold && `font-weight: 600;`};
  ${props =>
    props.title3 &&
    `
      font-family: CircularStd-Bold;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.02em;
      color: #090A0A;
  `};
  ${props =>
    props.regular_tight_regular &&
    `
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #000000;
  `};
  ${props =>
    props.regular_none_regular &&
    `
              font-size: 16px;
              line-height: 16px;
              letter-spacing: -0.01em;
              color: #000000;
  `};
  ${props =>
    props.regular_none_medium &&
    `
                          font-family: CircularStd-Medium;
                          font-size: 16px;
                          line-height: 16px;
                          letter-spacing: -0.01em;
                          color: #000000;
  `};
  ${props =>
    props.regular_none_bold &&
    `
                                                  font-family: CircularStd-Bold;
                                                  font-size: 16px;
                                                  line-height: 16px;
                                                  letter-spacing: -0.02em;
                                                  color: #000000;
  `};
  ${props =>
    props.small_message &&
    `
                                                        font-size: 14px;
                                                        line-height: 20px;
                                                        letter-spacing: -0.01em;
                                                        color: #000000;
  `};
  ${props =>
    props.small_tight_regular &&
    `
                                font-size: 14px;
                                line-height: 16px;
                                letter-spacing: -0.01em;
                                color: #000000;
  `};
  ${props =>
    props.small_tight_medium &&
    `
                                      font-family: CircularStd-Medium;
                                      font-size: 14px;
                                      line-height: 16px;
                                      letter-spacing: -0.01em;
                                      color: #000000;
  `};
  ${props =>
    props.small_none_regular &&
    `
                                      font-size: 14px;
                                      line-height: 14px;
                                      letter-spacing: -0.01em;
                                      color: #000000;
  `};
  ${props =>
    props.small_none_bold &&
    `
                                            font-family: CircularStd-Bold;
                                            font-size: 14px;
                                            line-height: 14px;
                                            letter-spacing: -0.02em;
                                            color: #000000;
  `};
  ${props =>
    props.tiny_none_regular &&
    `
                                                  font-size: 12px;
                                                  line-height: 14px;
                                                  letter-spacing: -0.01em;
                                                  color: #000000;
  `};
  ${props =>
    props.h1 &&
    `
    font-size: 30px;
    line-height: 42px;
    font-weight: 300;
  `};
  ${props =>
    props.h2 &&
    `
    font-size: 22px;
    line-height: 30px;
    font-weight: 600;
  `};
  ${props =>
    props.h3 &&
    `
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
  `};
  ${props =>
    props.h4 &&
    `
     font-size: 18px;
     line-height: 26px;
     font-weight: 600;
  `};
  ${props =>
    props.h5 &&
    `
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
  `};
`;

export const TouchableOpacity = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0px;
`;

const StyledImage = styled.img`
  width: ${props => props.style.width}px;
  height: ${props => props.style.height}px;
  border-radius: ${props => props.style.borderRadius}%;
  border: ${props => props.style.borderWidth}px solid
    ${props => props.style.borderColor};
  object-fit: ${props => props.resizeMode};
`;

export const Image = ({ source, style, resizeMode }) => {
  return <StyledImage src={source.uri} style={style} resizeMode={resizeMode} />;
};

// const StyledScrollView = styled.div`
//   overflow-x: auto;
//   white-space: nowrap;
//   padding-top: 16px;
//   padding-left: 56px;
//   padding-right: 20px;
// `;
//
// export const ScrollView = React.forwardRef(({ children, onScroll }, ref) => (
//   <StyledScrollView onScroll={onScroll} ref={ref}>
//     {children}
//   </StyledScrollView>
// ));

const StyledTextInput = styled.input`
  padding: 10px;
  margin: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  line-height: 20px;
  color: #333;

  &:focus {
    border-color: #888;
    outline: none;
  }
`;

export const TextInput = ({ value, onChange, ...props }) => (
  <StyledTextInput type="text" value={value} onChange={onChange} {...props} />
);

const HorizontalScrollContainer = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: thin;
  scrollbar-color: #888 #f0f0f0;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f0f0f0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 3px solid #f0f0f0;
  }

  scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for Internet Explorer and Edge */

  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
  }
`;

// export const ScrollView = ({ children }) => {
//   return <HorizontalScrollContainer>{children}</HorizontalScrollContainer>;
// };

export const ScrollView = ({ children, style }) => {
  return (
    <HorizontalScrollContainer style={style}>
      {children}
    </HorizontalScrollContainer>
  );
};
