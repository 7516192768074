import React, { Component } from "react";
import { ThemeProvider } from "styled-components";
import theme from "./components/styled/theme";
import { GlobalStyle } from "./components/styled/styledComponents"; // Update the path as necessary

import Chat from "./screens/chat/Chat";
import { View, Text } from "./components/styled/styledComponents"; // Assuming View is defined here

class App extends Component {
  constructor(props) {
    super(props);
    // Initialize the state
    this.state = {
      counter: 0
    };
  }

  componentDidMount() {
    console.log("didmount 1");
    // Increment the counter
    // this.setState(prevState => ({
    //   counter: prevState.counter + 1
    // }));
    setTimeout(() => {
      this.setState({ counter: this.state.counter + 1 });
    }, 500);
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <View style={{ flex: 1, zIndex: 10000 }}>
          <Chat />
        </View>
      </ThemeProvider>
    );
  }
}

export default App;
